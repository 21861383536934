import React, { useEffect, useContext } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Notification, UserProfile } from '.';
import AppContext from '../contexts/AppContext';
import { ThemeSettings } from '../components';

import { earningData, medicalproBranding, recentTransactions, weeklyStats, dropdownData, SparklineAreaData, ecomPieChartData } from '../data/dummy';




const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
<DropDownListComponent id="time" fields={{ text: 'Time', value: 'Id' }} style={{ border: 'none', color: (currentMode === 'Dark') && 'white' }} value="1" dataSource={dropdownData} popupHeight="220px" popupWidth="120px" />
   </div>
 );

const Navbar = (props:Props) => {
  const { themeSettings, setThemeSettings,currentMode, currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setIsClicked, setScreenSize, screenSize, userData } = useContext(AppContext);
 

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  const UserProfileIcon = ({ name, profileImage }) => (
    <div className="flex items-center">
      <img src={profileImage} alt={name} className="w-8 h-8 rounded-full" />
      <span className="ml-2 text-xs">Hi,  {name}</span>
    </div>
  );

  const handleActiveMenu = () => setActiveMenu(!activeMenu);
  const toggleThemeSettings = () => setThemeSettings(prev => !prev);

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
       <NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />} /> 
      <div className="flex">
        <NavButton title="Notification" dotColor="rgb(254, 201, 15)" customFunc={() => handleClick('notification')}
          color={currentColor} icon={<RiNotification3Line />}
        />
         <NavButton
          title="Settings"
          customFunc={toggleThemeSettings}
          color={currentColor}
          icon={<FiSettings />}
        />
        {/* <TooltipComponent content="Settings" position="Top"> */}
          {/* <button
            type="button"
            onClick={() => setThemeSettings(true)}
            style={{ background: currentColor, borderRadius: '50%' }}
            className="text-3xl text-white  hover:drop-shadow-xl hover:bg-light-gray"
          >
            <FiSettings  />
          </button>
        </TooltipComponent> */}
        <NavButton title="User Profile" customFunc={() => handleClick('userProfile')} color={currentColor} icon={<UserProfileIcon 
          name={userData?.data?.employee?.first_name} profileImage={userData?.data?.employee?.photo_url} 
        />}
        />
        {isClicked.notification && (<Notification />)}
        {isClicked.userProfile && (<UserProfile />)}
        {themeSettings && (<ThemeSettings />)}

      </div>
      
    </div>
  );
};

export default Navbar;
