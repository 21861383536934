import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Audio } from 'react-loader-spinner';
import { MdOutlineCancel } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import { CiEdit } from 'react-icons/ci';
import { Layout, Header, Modal, NewQuotation, EditQuotation } from '../../../../components';
import { handleApi, formatFriendlyDate } from '../../../../utils/utils';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Quotations = () => {
  const [quotations, setQuotations] = useState([]);
  const [quotation, setQuotation] = useState({});
  const [quantityAdjustment, setQuantityAdjustment] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const { userData, currentColor, actionCount } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchQuotations();
  }, [actionCount]);

  useEffect(() => {
    setCurrentPage(1); // Reset to page 1 when searchTerm changes
  }, [searchTerm]);

  const fetchQuotations = async () => {
    const apiEndpoint = 'sales/orders';
    setIsLoading(true);
    try {
      const response = await handleApi(apiEndpoint, {}, userData, 'GET');
      if (response.error) {
        toast.error(`Error fetching report: ${response.errorMessage}`);
        return;
      }

      const { status, statusText, data } = response;
      if (status === 200) {
        setQuotations(data);
      } else {
        toast.error(`Error ${status}: ${statusText}`);
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredQuotations = quotations.filter(quote =>
    quote?.partners?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredQuotations.slice(indexOfFirstItem, indexOfLastItem);

  // Add debugging logs
  console.log('Filtered Quotations:', filteredQuotations);
  console.log('Current Page:', currentPage);
  console.log('Current Items:', currentItems);

  const totalPages = Math.ceil(filteredQuotations.length / itemsPerPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleAction = (action, quote) => {
   console.log('quotations in handleaction', quote)
   setActionType(action)
   setQuotation(quote)
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Layout>
      <div className="m-1 md:m-2 mt-1 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-1 justify-center text-center">
          <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-2 flex flex-row justify-end">
            <button
              className="bg-transparent hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose}
            >
              <MdOutlineCancel size={24} color={currentColor} />
            </button>
          </div>
          <Header category="" title="Quotations" />
        </div>

        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && (
            <NewQuotation data={filteredQuotations} onClose={handleCloseModal} showCloseButton={true} />
          )}
          {isModalOpen  && actionType === 'edit' && (
            <EditQuotation originalQuotation={quotation} onClose={handleCloseModal} />
          )}
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (
          <>
            <div className="bg-gray-50 rounded-lg max-w-4xl  mx-auto">
            <div className="bg-gray-50 rounded-lg max-w-4xl mx-auto">

   

   

<div className="max-w-lg flex justify-end items-center mb-2 mx-auto">

              {/* Pagination */}
              <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-2 py-1 rounded-full text-xs text-white focus:outline-none ${
                      currentPage === index + 1 ? 'bg-current' : ''
                    }`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
               {/* Search Input */}
    <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
      />
    </div>

     {/* New Quotation Button */}
     <button
      className="ml-4 px-3 py-2 text-xs font-medium rounded-lg bg-white focus:outline-none flex items-center space-x-1"
      style={{ border: `1px solid ${currentColor}`, color: currentColor }}
      onClick={() => handleAction('new', {})}
    >
      <IoMdAdd />
      <span className="ml-1">New Quotation</span>
    </button>
  </div>
   {/*end  New Quotation Button */}


              </div>

              {/* Quotations List */}
              <div className="w-full bg-white rounded-lg shadow-lg">
                <div className="grid grid-cols-6 gap-4 p-3 border-b">
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">#</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Date</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Customer</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Item Value</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Status</span>
                  </div>
                  <div className="text-right">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Actions</span>
                  </div>
                </div>

                {currentItems.length > 0 ? (
                  currentItems.map((quote, index) => (
                    <div key={quote.id} className="grid grid-cols-6 gap-4 p-3 border-t cursor-pointer hover:bg-gray-200">
                      <div className="text-center">
                        <span className="text-sm text-gray-900">{indexOfFirstItem + index + 1}</span>
                      </div>
                      <div className="text-center">
                        <span className="text-sm text-gray-900">{quote?.date ? formatFriendlyDate(quote?.date) : 'No Date'}</span>
                      </div>
                      <div className="text-center">
                        <span className="text-sm text-gray-900">{quote?.partners?.name || 'N/A'}</span>
                      </div>
                      <div className="text-center">
                        <span className="text-sm text-gray-900">MK 40,000.00</span>
                      </div>
                      <div className="text-center">
                        <div className={`text-sm text-white  py-1 rounded-full ${quote?.state === 'draft' ? 'bg-red-500' : 'bg-green-500'}`}>
                          {quote?.state }
                        </div>
                      </div>
                      <div className="text-right">
                        <button
                          className="h-6 px-2 text-sm rounded-full hover:bg-blue-100 whitespace-nowrap"
                          onClick={() => handleAction('edit',quote)}
                          title="Edit"
                        >
                          <CiEdit size={20} color={currentColor} />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="p-3 text-center">No quotations available.</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Quotations;
