import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { Audio } from 'react-loader-spinner';
import { CiEdit } from "react-icons/ci";
import { IoMdTrash } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { Layout, Header, Button, Modal, NewEmployee, EditEmployee, DeleteEmployee } from '../../../../components';
import fetchData from '../../../../contexts/Api'; 
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer } from 'react-toastify';

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const { userData, currentColor,actionCount,setActionCount  } = useContext(AppContext);
  

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate(); // Get navigate function from React Router


    // Filter employees based on search term
    const filteredEmployees = employees && employees.filter(employee => {
        // Check if employee object and employee name are both defined
        if (employee && employee.first_name) {
        // Convert employee name and search term to lowercase for case-insensitive comparison
        return employee.first_name.toLowerCase().includes(searchTerm.toLowerCase());
        }
        // If employee object or employee name is undefined, return false to filter it out
        return false;
    });
  
  
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredEmployees.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    setIsLoading(true);
    fetchEmployees();
  }, [actionCount]);

  const fetchEmployees = async () => {
    await fetchData('employees', userData, setEmployees);
    setIsLoading(false);
  };

  const handleAction = (action,employee) => {
    setActionType(action);
    setEmployee(employee);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
};

console.log('current item at this point',currentItems.photo_url)
  return (
    <Layout>
      <div className="m-2 md:m-10 mt-4 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-8  justify-center text-center ">
          <div className="w-full sm:w-auto sm:flex-1  sm: px-4 py-2 flex flex-row justify-end">
            <button
                className="bg-transparent hover: hover:py-1 px-1 rounded mr-2 transition-all duration-300"
                title="Close"
                onClick={handleClose} // Call handleClose on click
            >
              <IoClose size={24}/>
            </button>
          </div>
          <Header category="" title="Employees"  />
        </div>
        
        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && <NewEmployee  onClose={handleCloseModal} />}
          {isModalOpen && actionType === 'edit' && <EditEmployee rowData={employee}  onClose={handleCloseModal} />}
          {isModalOpen && actionType === 'delete' && <DeleteEmployee rowData={employee}  onClose={handleCloseModal} />}
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col ">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (

          <>
            <div className='bg-gray-50 rounded-lg  mx-auto'>
              <div className="max-w-lg mx-auto">
                <button
                  className="rounded-lg my-3 p-1 transition-colors duration-300 ease-in-out hover:shadow-xl text-white"
                  style={{ backgroundColor: currentColor }}
                  onClick={() => handleAction('new', {})}
                  type="button"
                >
                  Add New
                </button>
              </div>
              {/* Search Input */}
              <div className="max-w-lg mx-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>
        
              {/* Pagination */}
              <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                {Array.from({ length: Math.ceil(filteredEmployees.length / itemsPerPage) }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
        
              <div className="max-w-lg mx-auto bg-white rounded-lg shadow-lg">
  {/* Column Header */}
  <div className="flex justify-between p-3 border-b">
    <div className="flex items-center">
      <span className="text-sm text-slate-400">Employee</span>
    </div>
    <div className="flex items-center">
      <span className="text-sm text-slate-400">Department</span>
    </div>
    <div className="flex items-center">
      <span className="text-sm text-slate-400">Actions</span>
    </div>
  </div>

  {/* List of Items */}
  {currentItems.map(employee => (
    <div key={employee.id} className="p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
      <div className="flex items-center">
        <img className="rounded-full h-10 w-10" src={employee?.photo_url} alt="" />
        <div className="ml-2 flex flex-col">
          <div className="leading-snug text-sm text-gray-900">{`${employee?.first_name} ${employee?.last_name}`}</div>
        </div>
      </div>
      <div className="flex ">
        <span className="text-sm text-gray-900 text-left">{employee?.departments?.name}</span>
      </div>
      <div className="flex items-center">
        <button 
          className="h-6 px-2 text-sm text-blue-400 rounded-full hover:bg-blue-100"
          onClick={() => handleAction('edit', employee)}
          title='Edit'
        >
          <CiEdit size={20}/>
        </button>
        <button 
          className="h-6 px-2 ml-2 text-sm text-blue-400 rounded-full hover:bg-blue-100"
          onClick={() => handleAction('delete', employee)}
          title='Delete'
        >
          <IoMdTrash size={20}/>
        </button>
      </div>
    </div>
  ))}
</div>

          {/* Pagination */}
          <div className="mt-3 flex mb-4 max-w-lg mx-auto">
            {Array.from({ length: Math.ceil(filteredEmployees.length / itemsPerPage) }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
              >s
                {index + 1}
              </button>
            ))}
          </div>
          </div>
          </>
          
        )}

        
      </div>
    </Layout>
  );
  };

export default Employees;
