//Login.jsx


import React, { useState, useEffect, useRef, useContext } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Audio } from 'react-loader-spinner';
import AppContext from '../../../../contexts/AppContext';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from "react-select/creatable";
import { FaUser, FaUnlock  } from "react-icons/fa";
import loginImg from '../../../../images/login/login.jpg'
// import { SearchableSelect} from '../components';
import { Position } from '@syncfusion/ej2/base';

import { authenticateUser} from '../utils/api';


const options = [
  { value: 0, label: "Select Terminal..." },
  { value: "Terminal 1", label: "Terminal 1" },
  { value: "Terminal 2", label: "Terminal 2" },
  { value: "Terminal 3", label: "Terminal 3" },
];
const Login = () => {
 
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState([]);
   // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    //const [pos, setPos] = useState(0);
    // const [pos, setPos] = useState(options[0].value); // Set default value to "Terminal 1"
    const [pos, setPos] = useState(options[0]); // Set default value to the first option object
    const [isLoading, setIsLoading] = useState(false);

    const usernameRef = useRef();
    const { setIsLoggedIn, isLoggedIn,isClicked, userData, currentColor, setUserData,companyProfile } = useContext(AppContext);

    
    
    useEffect(() => {
  // localStorage.removeItem('userInfo');
   // localStorage.removeItem('isLoggedIn');
  }, []);

  useEffect(() => {
  }, [pos]);
    
  // Update user data in storage whenever it changes
  useEffect(() => {
    if (userData) {
     // localStorage.setItem('userData', JSON.stringify(userData));
     // localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
    }
  }, [userData]); // Triggered whenever userData changes

  const handleUsernameChange = (e) => {
    const newValue = e.target.value;
    setUsername(newValue);
  };

  const handlePasswordChange = (e) => {
    const newValue = e.target.value;
    setPassword(newValue);
  };

  const handlePosChange = (selectedOption) => {
    setPos(selectedOption);
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    authenticateUser('users/login', { username, password,pos })
    .then(async (data) => {
      console.log('data from authentication',data.data)
      if (data.data.message && data.data.message.toLowerCase().includes('failed')) {
        toast.error(data.data.message); // Display the error message to the user
        setIsLoggedIn(false);
        return
      } else {   
        const userInfo = data;
        setUserData(userInfo);
        setIsLoggedIn(true);
        navigate('/dashboard');
      }
    })
    .catch(error => {
      toast.error(error.message); // Display the error message to the user
      // navigate('/dashboard');
    })
    .finally(() => { setIsLoading(false); });
  }

  


    useEffect(() => {
      localStorage.clear();
        if (isLoggedIn) {
          navigate('/dashboard');
        }
      }, [isLoggedIn, navigate]);
    


    return (
  
          
            <>
        <ToastContainer position="top-center" autoClose={2000} />
        <div className='relative w-full h-screen bg-zinc-900/90'>
            <img 
                className='absolute w-full h-full object-cover mix-blend-overlay' 
                src={loginImg} alt="/" 
            />
            <div className='flex justify-center items-center h-full'>
            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                    <Audio type="Bars"  color={currentColor} height={50} width={50} />
                </div>
            )}
                <form onSubmit={handleSubmit} className='max-w-[400px] w-full mx-auto bg-white p-8 rounded-2xl'>
                    <h2 className='text-4xl font-bold text-center py-4'> {companyProfile?.name?.trim().toUpperCase()}.</h2>
                    {/* <div className='flex flex-col mb-4'> */}
                    {/* <div className='grid grid-cols-auto mb-4'> */}
                 
                    <div className="flex items-center relative mb-4">
                      <span className="absolute left-3 shadow-lg"><FaUser color={currentColor}/></span>
                      <input
                        className="border bg-gray-100 p-2 pl-10 w-full" // Adjust padding
                        type="text"
                        onChange={handleUsernameChange}
                        id="username"
                        ref={usernameRef}
                        autoComplete="off"
                        required
                        value={username}
                        placeholder="Enter your username"
                      />
                    </div>

                    <div className="flex items-center relative mb-4">
                      <span className="absolute left-3 shadow-lg"><FaUnlock color={currentColor}/></span>
                      <input
                        className="border bg-gray-100 p-2 pl-10 w-full" // Adjust padding
                        type="password"
                        onChange={handlePasswordChange}
                        id="password"
                        value={password}
                        required 
                        placeholder="Enter your password"    
                      />
                    </div>
                    
                    <div className='flex flex-col p-2 rounded-lg'>
                    <CreatableSelect
                      className='border relative bg-gray-100 p-2 w-full'
                      options={options}
                      placeholder="Select Terminal..."
                      onChange={handlePosChange}
                      defaultValue={null}
                      value={pos}
                      styles={{
                        placeholder: (provided) => ({
                          ...provided,
                          color: 'rgba(0, 0, 0, 0.5)', // Adjust the color to your preference
                        }),
                      }}
                    />

                    </div>
                    <div className="flex justify-center mx-auto">
                      <button 
                        className=' py-2 px-12 pt-2  hover:bg-indigo-500 relative text-white rounded-lg'
                        style={{ backgroundColor:currentColor, transition: 'background-color 0.3s ease-in-out' 
                        }}
                        >
                        Sign In
                      </button>
                    </div>
                    <h4 className='text-center text-sm mt-4'>Forgot password ? Reset</h4>
                    <p className='text-center text-sm  mt-4'>Change password</p>     
                </form>
            </div>
        </div>
      </>
    );
}; export default Login ;
