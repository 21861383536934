import React from 'react'
import { useState, useEffect, useRef, useContext } from "react";
import { format } from 'date-fns';
import { Audio } from 'react-loader-spinner';
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { TbNewSection } from "react-icons/tb";
import AppContext from '../../../../contexts/AppContext';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit,
    Toolbar, Sort, Filter, Search } from '@syncfusion/ej2-react-grids';
import { ToastContainer, collapseToast, toast } from 'react-toastify';
import { Layout, Header, Modal, NewPayment,EditPayment,TabBar,InvoiceUtils} from '../../../../components';
import { getPurchases} from '../../../../data/PurchaseService';
// import { InvoiceUtils } from '../../invoices/utils/InvoiceUtils'; 
//import { getInvoices} from '../../invoices/utils/Api';
import fetchData from '../../../../contexts/Api'; 

const Payments = () => {
  // const [activeTab, setActiveTab] = useState('create');
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState({}); // selected  purchase order
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [purchaseId, setPurchaseId] = useState('');
    const [invoices, setInvoices] = useState([]);
    const [invoice, setInvoice] = useState('');
    const [data, setData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const [error, setError] = useState(null);
 
    const { userData, currentColor}= useContext(AppContext);
    const dynamicTabNames = [
      { name: 'New Payment ', key: 'create' },
      { name: 'List Payments', key: 'created' }
    ];
    // State to manage active tab
    const [activeTab, setActiveTab] = useState(dynamicTabNames[0].key);

    // Filter invoices  based on search term
    const filteredOrders = invoices && invoices.filter(po =>
      po.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
// console.log('filteredPurchaseOrders',filteredPurchaseOrders)
    // Filter purchase orders  based on search term
    const filteredInvoices = invoices && invoices.filter(invoice =>
      invoice.reference.toLowerCase().includes(searchTerm.toLowerCase())
    );
    


  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = filteredPurchaseOrders.slice(indexOfFirstItem, indexOfLastItem);
  // const currentReceivedItems = filteredReceivedOrders.slice(indexOfFirstItem, indexOfLastItem);

  // Ensure filteredPurchaseOrders and filteredReceivedOrders are arrays
  const currentItems = (filteredOrders || []).slice(indexOfFirstItem, indexOfLastItem);
  const currentInvoices = (filteredInvoices || []).slice(indexOfFirstItem, indexOfLastItem);


  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

 


  

  useEffect(() => {
    setIsLoading(true);
    fetchOrders();
    fetchInvoices();

   // fetchPayments();
    // setState('confirmed');
   // setActionCount(actionCount+1);
   // InvoiceUtils();
    console.log('approved invoices',invoices)
 }, []); // Trigger fetchPurchases when on load

 const fetchInvoices = async () => {
   const filter = { state: 'confirmed' };
  try {
    const invoices = await fetchData('purchase/invoices', userData, setInvoices,filter);
    setIsLoading(false);
  } catch (error) {
    console.error('Error fetching invoices:', error);
    setIsLoading(false); // Make sure to set isLoading to false on error as well
  }
};


  const fetchOrders = () => { // Fetch orders that are approved and not delivered
  
   //const filter = { state: 'approved', delivered: 0 };
    const filter = { state: 'approved' };
    setIsLoading(true);
   // const state =['approved'];
    getPurchases('purchase/orders', userData,filter)
    .then(data => { 
      console.log('approved orders from api',data.result)
      setOrders(data.result); 
    
    })
    .catch(error => { })// setError(error);
    .finally(() => { setIsLoading(false); });
  }

//   const fetchInvoices = () => { // Fetch unit of measure  from api
//     setIsLoading(true);
//     getInvoices('purchase/invoices', userData)
//     .then(data => { 
//       // console.log('from api invoices ',data)
//       setInvoices(data.result);})
//     .catch(error => {})
//     .finally(() => { setIsLoading(false); });
//   }

  
  const handleAction = (action,po) => {

    console.log('po in handleaction',po,action)
    setActionType(action);
    setInvoice(po);
    setIsModalOpen(true);
  };

    

  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  
  const formatDate = (dateString) => {
    const parsedDate = new Date(dateString);
    // Customize the format string as needed
    //const formattedDate = format(parsedDate, 'MMMM/do/yyyy'); // Example format: DD/MM/YYYY
    const formattedDate = format(parsedDate, 'MMMM do yyyy'); // Example format: May 20 2020 (without delimiters)
    //const formattedDate = format(parsedDate, 'dd/MM/yyyy'); // Example format: DD/MM/YYYY
    return formattedDate;
  };

  const getDayWithoutSuffix = (date) => {
    const day = date.getDate();
    return day > 3 && day < 21 ? `${day}th` : day % 10 === 1 ? `${day}st` : day % 10 === 2 ? `${day}nd` : `${day}rd`;
  };

  // Function to format the currency to Malawian Kwacha
const formatToMWK = (value) => {
  // Convert value to string
  const stringValue = value.toString();
  // Split the string into groups of three from the right
  const parts = stringValue.match(/\d{1,3}(?=(\d{3})+(?!\d))/g);
  // Join the parts with comma separators
  let formattedValue = parts ? parts.join(",") : stringValue;
  // Append 'MWK' as the currency symbol
  formattedValue = `MWK ${formattedValue}`;
  // Return the formatted value
  return formattedValue;
};

// console.log('recived orders at  this point',filteredReceivedOrders)
 console.log('approved invoices  at  this point',invoices)
  return (
    <>
      <Layout>
        <div className="m-2 md:m-10 mt-24 p-2 md:p-2 bg-white rounded-3xl">
          <ToastContainer position="top-center" autoClose={2000} />       
          <div className="pt-4">
            <Header  category="" title="Purchase Payments" color={currentColor} />
          </div>
          <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="700px">
            {actionType === 'new' && <NewPayment data={invoice} onClose={handleCloseModal} />}
             {actionType === 'edit' && <EditPayment data={invoice} onClose={handleCloseModal} />} 
          </Modal>

          <div className="flex w-full">
            <main className="main-container">
              <div className="">
                {/* <TabBar activeTab={activeTab} setActiveTab={setActiveTab} /> */}
                <TabBar tabNames={dynamicTabNames} activeTab={activeTab} setActiveTab={setActiveTab} />
     
              </div>
            </main>
          </div>

          {activeTab === 'create' && (
           <div>
            {isLoading ? (
              <div className="flex items-center flex-col ">
                <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
                <p className="mt-2">Loading data. Please wait...</p>
              </div>
            ) : (

              <>
                {/* start  Search Input */}
                <div className="max-w-lg">
                  <input
                    type="text"
                    placeholder="Search Purchase Order to receive items..."
                    // value={searchTerm}
                    // onChange={e => setSearchTerm(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
                </div>
                {/* end Search Input */}
                {/* Pagination */}
                <div className="mt-3 flex mb-4 max-w-lg ">
                {Array.from({ length: Math.ceil((filteredOrders || []).length / itemsPerPage) }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                    >
                    {index + 1}
                    </button>
                  ))}
                </div>
              {/* End Pagination */}
              {/* Product list */}
              <div className="mx-auto bg-white rounded-lg shadow-lg">
                {/* Column Header */}
                <div className="flex justify-between p-3 border-b">
                    <div style={{ width: '160px', textAlign: 'center' }}>
                        <span className="text-sm text-blue-400">PO # </span>
                    </div>
                
                    <div style={{ width: '80px', textAlign: 'center' }}>
                        <span className="text-sm text-blue-400">Amount</span>
                    </div>
                    <div style={{ width: '80px', textAlign: 'center' }}>
                        <span className="text-sm text-blue-400">Supplier</span>
                    </div>
                    
                    
                    <span className="text-sm text-blue-400">Actions</span>
                </div>
                {/* End Column Header */}
                {currentItems.map(po => (
                    <div key={po.id} className="p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
                        <div className="flex " style={{ width: '180px' }}>
                            <div className="ml-4 flex flex-col" style={{ width: '180px' }}>
                            <div className="leading-snug text-sm text-gray-900 font-bold">{po?.reference}</div>
                            <div className="text-xs" style={{ width: '200px', textAlign: 'left' }}>
                            Due: {po?.due_date ? new Date(po.due_date).toLocaleDateString('en-US', 
                                { month: 'short', day: 'numeric', year: 'numeric' }) : ""}
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '80px', textAlign: 'center' }}>
                        {po?.totalTaxedPrice ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(po.totalTaxedPrice) : ""}
                    </div>
                
                    <div className="flex items-center" style={{ width: '160px' }}>
                      <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                        <div className="leading-snug text-sm text-gray-900 font-bold">{po?.partners?.name}</div>  
                      </div>
                    </div>
                
                    
                    <button 
                        className="h-6 px-2 text-sm font-bold text-blue-400 rounded-full hover:bg-blue-100"
                        onClick={() => handleAction('new', po)}
                    >
                        <TbNewSection size={20}/>
                    </button>
                </div>
                ))}
            </div>
            {/* end Product list */}
         
              
              </>
            )}
           </div>
          )}

{activeTab === 'created' && (
           <div>
            {isLoading ? (
              <div className="flex items-center flex-col ">
                <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
                <p className="mt-2">Loading data. Please wait...</p>
              </div>
            ) : (

              <>
                {/* start  Search Input */}
                <div className="max-w-lg">
                  <input
                    type="text"
                    placeholder="Search Purchase Order to receive items..."
                    // value={searchTerm}
                    // onChange={e => setSearchTerm(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
                </div>
                {/* end Search Input */}
                {/* Pagination */}
                <div className="mt-3 flex mb-4 max-w-lg ">
                {Array.from({ length: Math.ceil((filteredOrders || []).length / itemsPerPage) }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                    >
                    {index + 1}
                    </button>
                  ))}
                </div>
              {/* End Pagination */}
              {/* Product list */}
              <div className="mx-auto bg-white rounded-lg shadow-lg">
                {/* Column Header */}
                <div className="flex justify-between p-3 border-b">
                    <div style={{ width: '160px', textAlign: 'center' }}>
                        <span className="text-sm text-blue-400">PO # </span>
                    </div>
                
                    <div style={{ width: '80px', textAlign: 'center' }}>
                        <span className="text-sm text-blue-400">Amount</span>
                    </div>
                    <div style={{ width: '80px', textAlign: 'center' }}>
                        <span className="text-sm text-blue-400">Supplier</span>
                    </div>
                    
                    
                    <span className="text-sm text-blue-400">Actions</span>
                </div>
                {/* End Column Header */}
                {currentItems.map(po => (
                    <div key={po.id} className="p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
                        <div className="flex " style={{ width: '180px' }}>
                            <div className="ml-4 flex flex-col" style={{ width: '180px' }}>
                            <div className="leading-snug text-sm text-gray-900 font-bold">{po?.reference}</div>
                            <div className="text-xs" style={{ width: '200px', textAlign: 'left' }}>
                            Due: {po?.due_date ? new Date(po.due_date).toLocaleDateString('en-US', 
                                { month: 'short', day: 'numeric', year: 'numeric' }) : ""}
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '80px', textAlign: 'center' }}>
                        {po?.totalTaxedPrice ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(po.totalTaxedPrice) : ""}
                    </div>
                
                    <div className="flex items-center" style={{ width: '160px' }}>
                      <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                        <div className="leading-snug text-sm text-gray-900 font-bold">{po?.partners?.name}</div>  
                      </div>
                    </div>
                
                    
                    <button 
                        className="h-6 px-2 text-sm font-bold text-blue-400 rounded-full hover:bg-blue-100"
                        onClick={() => handleAction('edit', po)}
                    >
                        <TbNewSection size={20}/>
                    </button>
                </div>
                ))}
            </div>
            {/* end Product list */}
         
              
              </>
            )}
           </div>
          )}


        
          
        </div>
      </Layout>
    </>
  )
}

export default Payments
