import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import {  updateProduct, handleProductApi } from '../utils/api';
import fetchData from '../../../../contexts/Api'; 
const EditProduct = ({ data, onClose }) => {
    const { userData, setActionCount,actionCount } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [originalProduct, setOriginalProduct] = useState({});
    const [originalPrices, setOriginalPrices] = useState({});
    const [categories, setCategories] = useState([]);
    const [category_name, setCategory_name] = useState('');
    const [uoms, setUoms] = useState([]);
    const [buy_uom_name, setBuy_uom_name] = useState('');
    const [sell_uom_name, setSell_uom_name] = useState('');
    const [sell_price_new, setSell_price_new] = useState('');
    const [buy_price_new, setBuy_price_new] = useState('');
    const [imgType, setImgType] = useState('');
    const [displayImg, setDisplayImg] = useState('');
   

    // console.log('data',data)

    useEffect(() => {
        setProduct(data);
        setOriginalProduct(data); // Store the initial profile
        setCategory_name(data?.product_categories?.name);
        setBuy_uom_name(data?.prices?.buy_uom_name);
        setSell_uom_name(data?.prices?.sell_uom_name);
        setBuy_price_new(data?.prices?.buy_price_new);
        setSell_price_new(data?.prices?.sell_price_new);
      
        setIsLoading(false);
      }, [data]);

      useEffect(() => {
        setIsLoading(true);
        fetchProductCategories();
        fetchUoms();
        const updatedProduct = { 
            ...data, 
            // category_name: data?.product_categories?.name || '',
            // buy_uom_name: data?.prices?.buy_uom_name || '',
            // sell_uom_name: data?.prices?.sell_uom_name || '',
            // buy_price_new: data?.prices?.buy_price_new || '',
            // sell_price_new: data?.prices?.sell_price_new || '',
            prices: {
                buy_uom_id: data?.prices?.buy_uom_id || '',
                sell_uom_id: data?.prices?.sell_uom_id || '',
                buy_price_new: data?.prices?.buy_price_new || '',
                sell_price_new: data?.prices?.sell_price_new || '',
            }
        };
        
        setProduct(updatedProduct);
        setCategory_name(data?.product_categories?.name || '');
        setBuy_uom_name(data?.prices?.buy_uom_name || '');
        setSell_uom_name(data?.prices?.sell_uom_name || '');
        setBuy_price_new(data?.prices?.buy_price_new || '');
        setSell_price_new(data?.prices?.sell_price_new || '');
        setOriginalProduct(data);
        setIsLoading(false);
        
    }, [data]);
    
    const fetchProductCategories = async () => {
        await fetchData('product_categories', userData, setCategories);
    };

    const fetchUoms = async () => {await fetchData('uoms', userData, setUoms); };
    const getChangedFields = (original, updated) => {
    const changedFields = {};
        for (const key in updated) {
          if (updated.hasOwnProperty(key) && original[key] !== updated[key]) {changedFields[key] = updated[key];}
        }
        if (original.id) {changedFields.id = original.id; }   // Include id if it exists in original
        return changedFields;
      };

    const convertFile = (files) => {
        console.log('conviert images')
        if (files && files.length > 0 && files[0] instanceof File) {
            const fileRef = files[0];
            const fileType = fileRef.type;
            const reader = new FileReader();
            reader.readAsBinaryString(fileRef);
            reader.onload = (ev) => {
            const  photo= {data:btoa(ev.target.result),contentType:fileType};
            setDisplayImg(btoa(ev.target.result))
            setImgType(fileType)
            setProduct(prevProduct => ({...prevProduct,photo:photo}));
        };
      }
    };

    const handleImgChange = (event) => {
        const { name, files } = event.target;if (name === "img") {convertFile(files);}
    };


    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setProduct(prevProduct => {const updatedProduct = { ...prevProduct };
        if (name === "category_name") {
            //updatedProduct.category_name = value; 
            setCategory_name(value)
            const selectedCategory = categories.find((category) => category.name === value);
           console.log('selectedCategory',selectedCategory)
            if (selectedCategory) {updatedProduct.category_id= selectedCategory.id;} 
        }else if(name === "buy_uom_name") {
            //updatedProduct.buy_uom_name = value; 
            setBuy_uom_name(value)
            const selectedUom = uoms.find((uom) => uom.name === value);
            console.log('selected Uom',selectedUom)
            if (selectedUom) {updatedProduct.prices.buy_uom_id= selectedUom.id;} 
        }else if(name === "sell_uom_name") {
            //updatedProduct.sell_uom_name = value; 
            setSell_uom_name(value)
            const selectedUom = uoms.find((uom) => uom.name === value);
            if (selectedUom) {updatedProduct.prices.sell_uom_id= selectedUom.id;} 
        }else if(name === "buy_price_new") {
            setBuy_price_new(value)
            //updatedProduct.buy_price_new = value; 
            updatedProduct.prices.buy_price_new = value; 
        }else if(name === "sell_price_new") {
            setSell_price_new(value)
            console.log('here isr ');
            //updatedProduct.sell_price_new = value; 
            updatedProduct.prices.sell_price_new= value; 
            
        }else{updatedProduct[name] = value } // Handle other fields
        return updatedProduct;
        });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('products at this point',product)
        if (!product.name) {toast.error('Please enter name ');return;  }
        if (!product.prices.sell_uom_id) {toast.error('Please enter Sell Unit of measure ');return;  }
        if (!product.category_id) {toast.error('Please enter product category ');return;  }
        //if (!category.photo_url) {toast.error('Please enter photo');return;  }
        
       const changedFields = getChangedFields(originalProduct, product); 
        //const changedPrices = getChangedFields(originalPrices, product.prices);
        //changedFields.Prices = changedPrices;
        // Conditional API call based on presence of profile.id

       // console.log(changedPrices.)
        const apiMethod = product.id ? 'PUT' :'POST'
        //const apiCall =  updateCompanyProfile('company_profile', profile, userData);
        //const apiCall =  updateCompanyProfile('company_profile', profile, userData);
        //const apiCall =  handleCompanyProfileApi('company_profile', profile, userData, apiMethod);
        setIsLoading(true);
        //apiCall
        handleProductApi('products', changedFields, userData, apiMethod)
        //updateProduct('products', userData,product)
        .then(async (data) => {

            if (data.data.status === 200) {//console.log('data from product api',data.data.statusText)
              toast.success('Product updated Successfully.',data.data.statusText );  
              setActionCount(actionCount+1); // to trigger reload of products list
            }else if (data.data.status === 409) {
              toast.error('Product already exist',data.data.statusText); // Display the error message to the use
              return
            } else { 
              toast.error('Error saving Product.',data.data.statusText,);  
              return
            }
        })
        .catch(error => {
          toast.error(error.message); // Display the error message to the user
          // navigate('/dashboard');
        })
        .finally(() => { 
            setActionCount(actionCount+1); // to trigger reload of products list
            setIsLoading(false); 
        });
      }
    

    // const photoUrl = (product && product.displayImg)
    // ? `data:${product.imgType};base64,${product.displayImg}`
    // : product.photoUrl;


    const photo= (displayImg)
     ? `data:${imgType};base64,${displayImg}`
    : product.photoUrl;


    const photo_url = (photo)? photo: product.photoUrl;
  
     console.log('product at this point',product)
    return (
        <div className="">
          <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Edit Product</h2>
            </div> 
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <div className="w-full">
                    <form className="shadow-md rounded px-8 pt-4 mb-2">
                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                                name="name"
                                onChange={(event) => handleTextChange(event)}
                                value={product?.name}
                            />
                            <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Product Name
                            </label>
                        </div>

                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                                name="barcode"
                                onChange={(event) => handleTextChange(event)}
                                value={product?.barcode}
                            />
                            <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Barcode
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="category_name"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="categoriesList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                key={product?.category_id} // Assuming 'id' is a unique identifier
                                value={category_name}
                            />
                                 {/* Use the datalist element for autocomplete */}
                                <datalist id="categoriesList">{categories.map((cat) => (<option value={cat.name} />))}
                                </datalist> 
                                <label
                                htmlFor="category_name"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                Product Category
                                </label>
                            </div>

                            <div className="relative z-0 mb-8 group">
                                <input
                                type="text"
                                name="buy_uom_name"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="uomsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                // key={product?.buy_uom_id} // Assuming 'id' is a unique identifier
                                value={buy_uom_name}
                                />
                            {/* Use the datalist element for autocomplete */}
                                <datalist id="uomsList">{uoms.map((uom) => (<option value={uom.name} />))}
                                </datalist> 
                                <label
                                htmlFor="buy_uom_name"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                Buy Unit of Measure 
                                </label>
                            </div>

                            <div className="relative z-0 mb-8  group">
                                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                    focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" "  
                                    name="buy_price_new"
                                    onChange={(event) => handleTextChange(event)}
                                    value={buy_price_new}
                                        
                                />
                                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Cost Price
                                </label>
                            </div>

                            <div className="relative z-0 mb-8 group">
                                <input
                                type="text"
                                name="sell_uom_name"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="uomsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                // key={product?.sell_uom_name} // Assuming 'id' is a unique identifier
                                value={sell_uom_name}
                                />
                            {/* Use the datalist element for autocomplete */}
                                <datalist id="uomsList">{uoms.map((uom) => (<option value={uom.name} />))}
                                </datalist> 
                                <label
                                htmlFor="sell_uom_id"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                Sell unit of measure
                                </label>
                            </div>

              

            <div className="relative z-0 mb-8  group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="sell_price_new" 
                    onChange={(event) => handleTextChange(event)}
                    value={sell_price_new}
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Sell Price
                </label>
            </div>

            <div className="relative z-0 mb-8  group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="reorder_quantity"
                    onChange={(event) => handleTextChange(event)}
                    value={product?.reorder_quantity}
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Re-order Qty
                </label>
            </div>
              

              <div className="relative z-0 mb-3 w-full group flex items-center">
                  <input
                  className="block py-2.5 px-0 w-full text-gray-900 bg-transparent bgray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                  type="file"
                  accept="image/*"
                  name='img'
                  onChange={(event) => handleImgChange(event)}
                  />
  
                  <label htmlFor="img" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Upload product picture</label>
                 
                 
                  {photo_url && (
                    <img
                        className="rounded-full h-24 w-24"
                        src={photo_url}
                        alt="product"
                    />
                    )}
                  
                  
                 
                
              </div>

                        <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditProduct;
