import React, { useState, useContext,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FcCancel } from "react-icons/fc";
import { BsFillSendFill } from "react-icons/bs";
import { MdPayment } from 'react-icons/md';
import { Audio } from 'react-loader-spinner';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatCurrency, formatShortDateTime,cleanObject,createOrder,handlePrint, generateReceiptHTML, handleApi } from '../../../../utils/utils';
import { TbDeviceGamepad } from 'react-icons/tb';

const PosCheckOut = ({ amountDue, cart, employee, onClose}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { currentColor, userData,companyProfile } = useContext(AppContext);
    const navigate = useNavigate();
    const [expandedOrderId, setExpandedOrderId] = useState(null);
    const [pay, setPay] = useState(false);
    const [amountTendered, setAmountTendered] = useState('');
    const [payment, setPayment] = useState({});
    const [reference, setReference] = useState('');
    const [change, setChange] = useState(-1);
    const handleClose = () => { if (onClose) onClose(); };
    const handleCancel = () => { setPay(false); };
    const handlePayBtnClick = () => { setPay(true); };
    useEffect(() => {
       console.log('original order sir  and amount ',cart)
    }, []);
    const handleAmountTenderedChange = (e) => {
        
        setAmountTendered(e.target.value);
        const tendered = parseFloat(e.target.value) || 0;
        const due = parseFloat(amountDue) || 0;
        setChange(tendered - due);
    };

    const handleSubmit = async () => {
        if (reference) {console.error('Payment already submitted.'); return; }
        const immediatePayment = {amount:amountDue,tendered: amountTendered};// payment object
        const billId=""; //this is a new bill does not has id 
        const cleanedOrder= createOrder(employee, cart,immediatePayment,billId);
        console.log('cleanered date sir ',cleanedOrder)
        const apiMethod = 'POST';
        setIsLoading(true);
        handleApi('pos/orders', cleanedOrder, userData, apiMethod)
        .then(async (data) => {
            const response =data.data;
            console.log('from api payment sir sir',data.data)
       
            // Check if the payment object has properties
            if (response && Object.keys(response).length > 0) {
                // Clone the response to avoid mutating the original object
                //const updatedResponse = { ...response };
            
                // Check if billOrders array exists and has at least one element
                // if (updatedResponse.billOrders && updatedResponse.billOrders.length > 0) {
                //     // Attach the cart as transactionLines to the first billOrder
                //     updatedResponse.billOrders[0].transactionLines = cart;
                // }
            
                setReference(response.reference);
                console.log('Payment from API received with updated billOrders:', response);
                toast.success('Payment successful.');
                handlePrint(response,companyProfile,1); // 1 for paid 0 for un paid
            } else {
                toast.info('Payment was not successful. Please contact systems administrator.');
            }
            
        })
        .catch(error => {toast.error(error.message); // Display the error message to the user
        })
        .finally(() => { 
            //resetCart(); // Reset cart
            // console.log('selected employee',selectedEmployee)
            //handleEmployeeSelect(selectedEmployee);
            //setActionCount(actionCount+1); // to trigger reload of products list
            setIsLoading(false); 
        });
    };
    
    

    console.log('employee ', employee);

    return (
        <>
            <div className="p-4 bg-gray-50 ">
                <div className="flex justify-between items-center border-b-2 border-blue-300 pb-4 mb-4">
                    <h2 className="text-blue-700 text-2xl font-bold">Customer Bill</h2>
                    {/* menu icons */}
                    <div className="flex items-center space-x-2">
                       
                    </div>
                        {/*  end menu icons */}
                </div>
                <p className="text-xs text-blue-500 block mx-2">Sales Person: {employee?.first_name} {employee?.last_name}</p>
               
                {isLoading ? (
                    <div className="flex flex-col items-center justify-center py-16">
                        <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="loading" />
                        <p className="mt-4 text-gray-600">Processing. Please wait...</p>
                    </div>
                ) : (
                    <div className="space-y-4">
                        {/* Bill information */}
                        <div className='bg-white rounded-lg shadow-lg p-2'>
                            <h3 className="text-lg font-medium text-gray-800 mb-2 flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <div className="font-bold text-gray-700 ml-2">
                                        <span>Amount Due:</span>
                                    </div>
                                    <div className="bg-red-500 text-white font-bold py-1 px-4 rounded-lg">
                                        {formatCurrency(amountDue)}
                                    </div>
                                </div>
                            </h3>
                        </div>
                         {/*End  Bill information */}
                         {/* payment form */}
                         <div className="bg-white rounded-lg shadow-lg mb-4 px-4 py-2">
                                <form >
                                    <div className="mb-4">
                                        <label htmlFor="amountTendered" className="block text-gray-700 text-3xl font-bold">Amount Tendered:</label>
                                        <input
                                            name="amountTendered"
                                            type="text"
                                            value={amountTendered}
                                            className="mt-1 block w-full text-3xl font-bold px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                            onChange={handleAmountTenderedChange}
                                        />
                                    </div>
                                    {change >= 0 && (
                                        <div className="my-4 text-green-600 font-bold text-4xl">
                                            <p>Change: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MKW' }).format(change)}</p>
                                        </div>
                                    )}

                                    <div className="flex justify-between items-center mb-4">
                                        <button
                                            type="button"
                                            className="bg-red-200 hover:bg-red-600 text-white py-2 px-4 rounded transition-all duration-300 flex items-center"
                                            title="Cancel transaction"
                                            onClick={handleCancel}
                                        >
                                            <FcCancel size={24} />
                                        </button>
                                        
                                        {change >= 0 && (
                                            <button
                                                type="submit"
                                                className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded transition-all duration-300 flex items-center"
                                                title="Pay"
                                                onClick={handleSubmit}
                                                >
                                                <BsFillSendFill size={24} />
                                                <span className="ml-2 uppercase">Pay</span>
                                            </button>
                                        )}
                                    </div>
                                   
                                </form>
                            </div>
                         {/* end payment form  */}

                        

                        
                    </div>
                )}
            </div>
            <ToastContainer />
        </>
    );
};

export default PosCheckOut;
