import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import fetchData from '../../../../contexts/Api'; 
import { employeeTypeProperty } from "@syncfusion/ej2/documenteditor";
import { addEmployee} from '../utils/api';

const NewEmployee = ({onClose }) => {
    const { userData ,actionCount, setActionCount} = useContext(AppContext);
    const [employee, setEmployee] = useState({});
    const [departments, setDepartments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {fetchDepartments();}, []);

    const fetchDepartments = async () => {
        await fetchData('departments', userData, setDepartments);
        setIsLoading(false);
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setEmployee(prevEmployee => {const updatedEmployee = { ...prevEmployee };
        if (name === "department") {
            updatedEmployee.department = value; 
            const selectedDepartment = departments.find((department) => department.name === value);
            if (selectedDepartment) {updatedEmployee.department_id= selectedDepartment.id;} 
        }else{updatedEmployee[name] = value } // Handle other fields
        return updatedEmployee;
        });
    };
    

    const handleImgChange = (event) => {
        const { name, files } = event.target;
        if (name === "img") {convertFile(files);}
    };

    const convertFile = (files) => {
        if (files && files.length > 0 && files[0] instanceof File) {
            const fileRef = files[0];
            const fileName = fileRef.name; // Get the file name
            const fileType = fileRef.type;
            const reader = new FileReader();
            reader.readAsBinaryString(fileRef);
            reader.onload = (ev) => {
                const base64Data = `data:${fileType};bLOase64,${btoa(ev.target.result)}`;
                setEmployee(prevEmployee => ({
                    ...prevEmployee,
                    img: base64Data,
                    displayImg: btoa(ev.target.result),
                    imgType: fileType,
                    imgUrl: fileName, // Add the fileName to the Page state
                }));
            };
        }
    };

    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log('in submit sir ',employee)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (!employee.first_name) {toast.error('Please enter first name ');return;  }
        if (!employee.last_name) {toast.error('Please enter last name ');return;  }
        if (!employee.phone_number) {toast.error('Please enter telephone number ');return;  }
        if (!employee.department) {toast.error('Please enter telephone number ');return;  }
        if (!employee.hire_date) {toast.error('Please enter date hired');return;  }
        if (!employee.imgUrl) {toast.error('Please enter photo');return;  }
    // if (!employee.photo_url) {toast.error('Please enter photo');return;  }
    

   
        addEmployee('employees',employee, userData)
        .then(async (data) => {
          console.log('data from department api',data.data.statusText)
          if (data.data.status === 200) {
            toast.success('Department created  Successfully.',data.data.statusText );  
            setActionCount(actionCount+1); // to trigger reload of products list
            //setUserData(userInfo);
          }else if (data.data.status === 409) {
            toast.error('Employee already exist',data.data.statusText); // Display the error message to the use
            return
          } else { 
            toast.error('Error saving employee.',data.data.statusText,);  
            return
          }
        })
        .catch(error => {
          toast.error(error.message); // Display the error message to the user
          // navigate('/dashboard');
        })
        .finally(() => {
          setActionCount(actionCount+1); // to trigger reload of products list
          setIsLoading(false); });
    }

    console.log(' employee at this point',employee)
    return (
        <div className="">
            <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New Employee</h2>
            </div> 
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <div className="w-full">
                    <div className="shadow-md rounded px-8 pt-4 mb-2 bg-blue-25 p-4">
                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                                name="first_name"
                                onChange={(event) => handleTextChange(event)}
                                value={employee?.first_name}
                            />
                            <label htmlFor="first_name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First Name
                            </label>
                        </div>
              
                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="last_name"
                                className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={employee?.last_name}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="last_name"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Last Name
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="phone_number"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="parentsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                key={employee?.id} // Assuming 'id' is a unique identifier
                                value={employee?.phone_number}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="phone_number"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Telephone
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="email"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={employee?.email}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="email"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Email
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="department"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="departmentList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                key={employee?.id} // Assuming 'id' is a unique identifier
                                value={employee?.department}
                            />
                            <datalist id="departmentList">{departments.map((department) => (<option value={department.name} />))}
                                </datalist> 
                            {/* Use the datalist element for autocomplete */}
                            <label htmlFor="department" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Department
                            </label>
                        </div>

                        
                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="job_title"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={employee?.job_title}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label htmlFor="job_title" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Job Title
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="date"
                                name="hire_date"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                key={employee?.id} // Assuming 'id' is a unique identifier
                                value={employee?.hire_date}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="hire_date"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Hired Date
                            </label>
                        </div>

                        <div className="relative z-0 mb-3 w-full group flex items-center">
                            <input
                                className="block py-2.5 px-0 w-full text-gray-900 bg-transparent dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                                type="file"
                                accept="image/*"
                                name='img'
                                onChange={(event) => handleImgChange(event)}
                            />
            
                            <label htmlFor="img" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Profile picture</label>
                            {employee && employee.img && (
                            <img
                                className="rounded-full h-24 w-24"
                                src={`data:${employee.imgType};base64,${employee?.displayImg}`}
                                alt="Page image"
                            />
                            )}
                        </div>

                        <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewEmployee;
