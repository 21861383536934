import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FcCancel } from "react-icons/fc";
import { BsFillSendFill } from "react-icons/bs";
import { MdPayment } from 'react-icons/md';
import { Audio } from 'react-loader-spinner';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatLongDateTime, handlePrint,generateReceiptHTML,handleApi } from '../../../../utils/utils';

const PosBillView = ({ bill, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { currentColor, userData,companyProfile } = useContext(AppContext);
    const navigate = useNavigate();
    const [expandedOrderId, setExpandedOrderId] = useState(null);
    const [pay, setPay] = useState(false);
    const [amountTendered, setAmountTendered] = useState('');
    const [reference, setReference] = useState('');
    const [change, setChange] = useState(null);

    const handleAmountTenderedChange = (e) => {
        const amountDue = bill.totalBillTaxedPrice;
        setAmountTendered(e.target.value);
        const tendered = parseFloat(e.target.value) || 0;
        const due = parseFloat(amountDue) || 0;
        setChange(tendered - due);
    };

    const handleSubmit = async () => {
        if (reference) {
            toast.error('Payment already submitted.');
            return;
        }
        const posPayment = { bill_id: bill.id, tendered: amountTendered, amount: bill.totalBillTaxedPrice };
        console.log('pos payment ', posPayment);
        //debugger
        const apiMethod = 'POST';
        setIsLoading(true);
        handleApi('pos/payments', posPayment, userData, apiMethod)
        .then(async (data) => {
            const response =data.data;
            if (response && Object.keys(response).length > 0) {
               setReference(response.reference)
                console.log('Payment from API received with updated billOrders:', response);
                toast.success('Payment successful.');
                handlePrint(response,companyProfile,1) // 1 for staus paid 0 for un paid
            }
        })
        .catch(error => {toast.error(error.message);})
        .finally(() => {setIsLoading(false);});
    };

    const toggleOrder = (id) => {
        setExpandedOrderId(expandedOrderId === id ? null : id);
    };

    const handleClose = () => { if (onClose) onClose(); };
    const handleCancel = () => {
         setPay(false); 
         setAmountTendered('');
         setChange(-1);
    
    };
    const handlePayBtnClick = () => { setPay(true); };
    const handlePrintBtnClick = () => { 
        handlePrint(bill,companyProfile,0) // for status paid . 0 for status unpaid
    };
    
    
    
    
    

    
    console.log('bills sir ', bill);

    return (
        <>
            <div className="p-4 bg-gray-50 ">
                <div className="flex justify-between items-center border-b-2 border-blue-300 pb-4 mb-4">
                    <h2 className="text-blue-700 text-2xl font-bold">Customer Bill</h2>
                    
                    <div className="flex items-center space-x-2">
                    {!pay && (
                        <button
                            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded transition-all duration-300 flex items-center"
                            title="Print"
                            onClick={handlePrintBtnClick}
                        >
                            <MdPayment size={24} />
                            <span className="ml-2 uppercase">Print</span>
                        </button>
                    )}
                        {!pay && (
                            <button
                                className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded transition-all duration-300 flex items-center"
                                title="Pay"
                                onClick={handlePayBtnClick}
                            >
                                <BsFillSendFill size={24} />
                                <span className="ml-2 uppercase">Pay</span>
                            </button>
                        )}
                    </div>
                </div>
                <p className="text-xs text-blue-500 block mx-2">Sales Person: {bill?.employees?.first_name} {bill?.employees?.last_name}</p>
                {isLoading ? (
                    <div className="flex flex-col items-center justify-center py-16">
                        <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="loading" />
                        <p className="mt-4 text-gray-600">Processing. Please wait...</p>
                    </div>
                ) : (
                    <div className="space-y-4" id="billContent">
                        <div className='bg-white rounded-lg shadow-lg p-2'>
                            <h3 className="text-lg font-medium text-gray-800 mb-2 flex justify-between items-center">
                                <span className="text-gray-600 font-bold">Bill #: {bill.id}</span>
                                <div className="flex items-center space-x-2">
                                    <div className="font-bold text-gray-700 ml-2">
                                        <span>Amount Due:</span>
                                    </div>
                                    <div className="bg-red-500 text-white font-bold py-1 px-4 rounded-lg">
                                        {new Intl.NumberFormat('en-US', { 
                                            style: 'currency', 
                                            currency: 'MWK', // Change this to your currency code if different
                                            minimumFractionDigits: 2, // Adjust the number of decimal places
                                            maximumFractionDigits: 2 
                                        }).format(bill.totalBillTaxedPrice)}
                                    </div>
                                </div>
                            </h3>
                        </div>

                        {pay ? (
                            <div className="bg-white rounded-lg shadow-lg mb-4 px-4 py-2">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label htmlFor="amountTendered" className="block text-gray-700 text-3xl font-bold">Amount Tendered:</label>
                                        <input
                                            name="amountTendered"
                                            type="text"
                                            value={amountTendered}
                                            className="mt-1 block w-full text-3xl font-bold px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                            onChange={handleAmountTenderedChange}
                                        />
                                    </div>
                                    {change >= 0 && (
                                        <div className="my-4 text-green-600 font-bold text-4xl">
                                            <p>Change: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MKW' }).format(change)}</p>
                                        </div>
                                    )}

                                    <div className="flex justify-between items-center mb-4">
                                        <button
                                            type="button"
                                            className="bg-red-200 hover:bg-red-600 text-white py-2 px-4 rounded transition-all duration-300 flex items-center"
                                            title="Cancel transaction"
                                            onClick={handleCancel}
                                        >
                                            <FcCancel size={24} />
                                        </button>
                                        
                                        {change >= 0 && (
                                            <button
                                                type="submit"
                                                className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded transition-all duration-300 flex items-center"
                                                title="Pay"
                                                >
                                                <BsFillSendFill size={24} />
                                                <span className="ml-2 uppercase">Pay</span>
                                            </button>
                                        )}
                                    </div>
                                   
                                </form>
                            </div>
                        ) : (
                            <div className="bg-white shadow-lg rounded-lg p-4">
                                {bill && bill.billOrders && bill.billOrders.map((order) => (
                                    <div key={order.id} className="border border-gray-300 rounded-lg mb-2 shadow-md overflow-hidden">
                                        <button
                                            className="flex justify-between items-center w-full p-2 text-left bg-gray-100 rounded-t-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            onClick={() => toggleOrder(order.id)}
                                        >
                                            <div>
                                                <span className="text-lg font-semibold text-gray-800">Order #{order.id}</span>
                                                <span className="text-lg text-red-500 font-semibold text-gray-800 ml-4"> {new Intl.NumberFormat('en-US', { 
                                                        style: 'currency', 
                                                        currency: 'MWK', // Change this to your currency code if different
                                                        minimumFractionDigits: 2, // Adjust the number of decimal places
                                                        maximumFractionDigits: 2 
                                                    }).format(order.totalTaxedPrice)}
                                                </span>
                                                <span className=" font-semibold text-gray-600 ml-4">{formatLongDateTime(order.date)}</span>
                                            </div>

                                            <span className={`text-xl font-bold rounded-full flex items-center justify-center w-8 h-8 ${
                                                expandedOrderId === order.id ? 'text-blue-500' : 'text-gray-700'
                                            }`}>
                                                {expandedOrderId === order.id ? '-' : '+'}
                                            </span>
                                        </button>
                                        <div
                                            className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                                                expandedOrderId === order.id ? 'max-h-screen p-4' : 'max-h-0'
                                            }`}
                                        >
                                            <div className="grid grid-cols-3 min-w-full divide-y divide-gray-200">
                                                <div className="bg-gray-50 px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase">Product</div>
                                                <div className="bg-gray-50 px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase">Qty</div>
                                                <div className="bg-gray-50 px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase text-right">Total</div>
                                                {order.transactionLines && order.transactionLines.map((line) => (
                                                    <>
                                                        <div className="ml-4 flex flex-col">
                                                            <div className="px-4 pt-3 text-sm font-medium font-semibold text-gray-900">{line.products.name}</div>
                                                            <div className="px-4 pb-3 text-xs font-medium text-gray-500">@ {line.unit_price}</div>
                                                        </div>
                                                        <div className="px-4 py-3 text-sm font-medium text-gray-900">{line.quantity}</div>
                                                        <div className="px-4 py-3 text-sm font-medium text-gray-900 text-right">
                                                            {new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }).format(line.line_total_taxed)}
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <ToastContainer />
        </>
    );
};

export default PosBillView;
