import React, { useState, useEffect, useRef, useContext } from "react";
import { FiPlusCircle , FiTrash2 } from 'react-icons/fi';
import { FaTrashAlt  } from 'react-icons/fa';
import { FcApproval } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { IoClose } from "react-icons/io5";
import { AiFillPrinter } from "react-icons/ai";
import { RiMailSendLine } from "react-icons/ri";

import { ToastContainer, collapseToast, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import { createInvoice} from '../utils/Api';
////import { editInvoice } from '../utils/invoiceUtils'; 
import { InvoiceUtils} from '../../../../components';

 
const NewInvoice = ({po, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { currentColor,userData} = useContext(AppContext);
    const [invoice, setInvoice] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log('in new Invoice sir', po);
        const updatedInvoice = {...po,invoice_state: 'new',invoice_id:0};
        setInvoice(updatedInvoice);
    }, [po]);


    const handleChange = (event, productIndex) => {
        const { name, value } = event.target;
        setInvoice((prevInvoice) => ({...prevInvoice,[name]: value}));
    };

    const handleChangeStatus = async (status) => {
      // Check if invoice_id is greater than 0
      if (invoice.invoice_id > 0) {
        const updatedInvoice = { ...invoice, state: status };         // Update local state first
        setInvoice(updatedInvoice);
        await new Promise(resolve => setTimeout(resolve, 0)); // Wait for the state update to be processed by React
        InvoiceUtils(); // edit in nvoice in Invoice Utils
      } else {
        console.error('invoice not saved:', invoice.invoice_id);
        toast.error('Invoice in not saved. Please click update button');
      }
    };
    
    
    
    
    
  
    
    const handleSubmit = async () => {
        setIsLoading(true); // Set loading indicator
        try {
          const updatedInvoice = { ...invoice };      // Clone the invoice object
          updatedInvoice.invoice_state = 'draft';  // Set invoice State to 'draft'
          
          // Check if invoice already exists (assuming you have a way to check)
          if (updatedInvoice.invoice_id && updatedInvoice.invoice_id > 0) {
            toast.info('Invoice already created. Invoice ID: ' + updatedInvoice.invoice_id);
            return; // Exit the function if invoice exists
          }

          // Perform validation checks
          if (!updatedInvoice.invoice_no) {toast.error('Invoice number is required.');return;}
          if (!updatedInvoice.due_date) {toast.error('Due date is required.');return;}
          const selectedDate = new Date(updatedInvoice.due_date);
          const today = new Date();
          if (selectedDate < today) {toast.error('Due date cannot be earlier than today.');return;}

          const result = await createInvoice('purchase/invoices', updatedInvoice, userData);//API call with updatedInvoice
          console.log('Updated Invoice response:', result);

          const invoiceData = result.data; // Assuming 'result' holds the API response

          if (invoiceData) {
            const invoiceId = invoiceData.id;
            console.log("Extracted Invoice ID:", invoiceId);
            updatedInvoice.invoice_id = invoiceId; // Update the invoice object with the returned invoice_id
            setInvoice(updatedInvoice); 
          } else {
            console.error("Error: Invoice data not found in the response");
          }
          //const { id } = result[result.length - 1]; // Extract invoice_id from API response
        
          //console.log('Updated Invoice response:',id);
          

          toast.success('Invoice updated successfully.');
        } catch (error) {setError('Failed to update invoice. Please try again later.');
        } finally {setIsLoading(false); // Clear loading indicator
        }
      };
      
    
    function formatDate(isoDate) {
        if (!isoDate) return ''; // Handle empty or undefined date
        const date = new Date(isoDate);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();
        // Pad single digit month/day with a leading zero
        if (month.length === 1) { month = '0' + month; }
        if (day.length === 1) {day = '0' + day;}
        return `${year}-${month}-${day}`;
      }
    
    //console.log('value at this point',order[0].state)
    
  
    const shouldRenderCancelButton = invoice?.state !== 'cancelled';

   {console.log('invoice value at this point :', invoice)}
    return (
        <div className="">
          <ToastContainer position="top-left" autoClose={2000} />       
          <div className="flex">
          <h1 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New Invoice</h1>
       
        </div>
            {/* // order data */}
            {invoice && (
                //order div
                <div className="rounded-md p-4">
                    {/* purchase order preamble info */}
                    <div className="flex flex-wrap items-center mt-3 border-1 gray-200 rounded-lg">
                        
                        <div className="w-full sm:w-auto sm:flex-1  sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
                            <p className="whitespace-nowrap text-slate-400 text-xs">Order #</p>
                            <p className="whitespace-nowrap uppercase font-bold text-xs" >
                            {invoice?.reference }
                            </p>
                        </div>
                        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
                            <p className="whitespace-nowrap text-slate-400 text-xs">Purchase Amount</p>
                            <p className="whitespace-nowrap uppercase font-bold text-main text-xs" style={{ color: currentColor }}>
                            {invoice?.totalTaxedPrice ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(invoice?.totalTaxedPrice) : ""}
                           
                            </p>
                        </div>
                        
                        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
                            <p className="whitespace-nowrap text-slate-400 text-xs">Supplier</p>
                            <p className="whitespace-nowrap uppercase font-bold text-main text-xs" style={{ color: currentColor }}>
                            {invoice?.partners?.name}
                            </p>
                        </div>
                        
                        {/* Conditionally render Approve and Cancel buttons */}
                        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row">
                           
                            {shouldRenderCancelButton && (
                                <button 
                                    className="bg-transparent ${currentColor} mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Cancel Invoice" // Tooltip content
                                    onClick={() => handleChangeStatus('cancelled')} // Pass "cancel" value to handleCancelOrder
                                >
                                    <FcCancel size={24} /> {/* This will render a larger check icon */}
                                </button>
                            )}

                                <button 
                                    className="bg-transparent border ${currentColor} mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Email Invoice" // Tooltip content
                                    // onClick={() => handleOrderStatus('cancelled')} // Pass "cancel" value to handleCancelOrder
                                >
                                    <RiMailSendLine  size={24} /> {/* This will render a larger check icon */}
                                </button>

                                <button 
                                    className="bg-transparent border ${currentColor} mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Print Invoice" // Tooltip content
                                    // onClick={() => handleOrderStatus('cancelled')} // Pass "cancel" value to handleCancelOrder
                                >
                                    <AiFillPrinter  size={24} /> {/* This will render a larger check icon */}
                                </button>



                        </div>
                        {/* End of Approve and Cancel buttons */}   
                    </div>
                    {/* end purchase order preamble info */}
                  
                  
                    {/* // supplier info */}
                    <div className="flex p-4 mt-3 border-1 gray-200 rounded-lg">
                    <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0  px-4 py-2">
                        <p className="whitespace-nowrap text-slate-400 text-xs ">Invoice #</p>
                        <input type="text"  id="invoiceId" className="py-2.5 px-0 w-full text-sm
                            text-gray-900 border-0 border-b-2 border-gray-300 
                            dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                            focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" "  
                            name="invoice_no"
                            value={invoice.invoice_no ? invoice.invoice_no : ''}
                            onChange={handleChange}
                        />
                    </div>
        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0  px-4 py-2">
            <p className="whitespace-nowrap text-slate-400 text-xs">Due date</p>
            <input 
                type="date"  
                className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" "  
                name="due_date"
                value={invoice ? formatDate(invoice.due_date) : ''}
                onChange={handleChange}
            />
        </div>

        

                        
                        
                        
                    </div>
                    
                    {/* //end supplier info */}
                    <div className="p-1 mt-3 border-1 gray-200 rounded-lg">
  <div className="card rounded-lg justify-center bg-white p-2 border-gray-200">
    {/* Product list */}
    <div className="mx-auto bg-white rounded-lg shadow-lg">
      {/* Column Header */}
      <div className="flex justify-between p-3 border-b">
        <div style={{ width: '10px', textAlign: 'left' }}>
          <span className="text-xs text-blue-400">#</span>
        </div>
        <div style={{ width: '160px', textAlign: 'left' }}>
          <span className="text-xs text-blue-400">Item Name</span>
        </div>
        <div style={{ width: '80px', textAlign: 'right' }}>
          <span className="text-xs text-blue-400">Price</span>
        </div>
        <div style={{ width: '50px', textAlign: 'left' }}>
          <span className="text-xs text-blue-400">Qty</span>
        </div>
        <div style={{ width: '80px', textAlign: 'center' }}>
          <span className="text-xs text-blue-400">Total</span>
        </div>
        <span className="text-xs text-blue-400"></span>
      </div>
      {/* End Column Header */}
      {invoice && invoice.transactionLines && invoice.transactionLines.map((line, index) => (
        <div key={line.id} className="p-2 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
          <div className="flex items-center" style={{ width: '10px' }}>
            <div className="ml-4 flex flex-col" style={{ width: '10px' }}>
              <div className="leading-snug text-sm text-gray-900 ">{index + 1}</div>
            </div>
          </div>
          <div className="flex items-center" style={{ width: '160px' }}>
            <div className="leading-snug text-sm text-gray-900 ">{line?.name}</div>
          </div>
          <div className="flex items-right" style={{ width: '80px' }}>
            <div className="leading-snug text-sm text-gray-900 ">{line?.unit_price}</div>
          </div>
          <div className="flex items-center" style={{ width: '50px' }}>
            <div className="leading-snug text-sm text-gray-900">{parseInt(line.quantity).toFixed(0)}</div>
          </div>
          <div className="text-xs" style={{ width: '80px', textAlign: 'right' }}>
            <div className="leading-snug text-sm text-gray-900 ">{line?.line_total_untaxed ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(line?.line_total_untaxed) : ""}</div>
          </div>
        </div>
      ))}
    </div>
    {/* end Product list */}

     {/* Summary section */}
     <div className="mt-3 px-2">
      <div className="flex justify-between mb-1">
        <span className="text-sm text-gray-900 font-bold">Sub total:</span>
        <span className="text-sm text-gray-900 font-bold">{invoice?.totalUntaxedPrice ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(invoice.totalUntaxedPrice) : ""}</span>
      </div>

      <div className="flex justify-between mb-1">
        <span className="text-sm text-gray-900 font-bold">Discount:</span>
        <span className="text-sm text-gray-900 font-bold">{invoice?.totalDiscount ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(invoice.totalDiscount) : ""}</span>
      </div>

      <div className="flex justify-between mb-1">
        <span className="text-sm text-gray-900 font-bold">Tax:</span>
        <span className="text-sm text-gray-900 font-bold">{invoice?.totalTax ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(invoice.totalTax) : ""}</span>
      </div>

      <div className="flex justify-between mb-1">
        <span className="text-sm text-gray-900 font-bold">Grand Total:</span>
        <span className="text-sm text-gray-900 font-bold">{invoice?.totalTaxedPrice ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(invoice.totalTaxedPrice) : ""}</span>
      </div>

      {/* Submit button */}
      <div className="flex justify-end">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-lg"
                style={{ backgroundColor: currentColor }}
                onClick={handleSubmit}
              >
                Update
              </button>
      </div>
    </div>
  </div>
</div>

                    {/* stop Render product form here */}
                </div>
                //end order div
              )}  
              {/* // order data */}
            </div>
    );
};
export default NewInvoice;
