import React, { useState, useEffect, useRef, useContext } from "react";
import { ToastContainer, collapseToast, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillPrinter } from "react-icons/ai";
import { RiMailSendLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import AppContext from '../contexts/AppContext';
import { Header, Button} from '../components';
import { getPurchase, updatePurchaseOrderState, recieveProducts} from '../data/PurchaseService';
import { StateContext } from "../contexts/ContextProvider";
const RecieveProducts = ({ po, onClose })=> {
  const [isLoading, setIsLoading] = useState(true);
  const { currentColor,userData} = useContext(AppContext);
  const [order, setOrder] = useState({});
  const [recieved, setReceived] = useState(0);

  const [error, setError] = useState(null);

  // useEffect(() => {
  //   setOrder(po)
  // }, []);

  useEffect(() => {
    if (po && Object.keys(po).length > 0 && po.transactionLines && po.transactionLines.length > 0) {
        // Make a shallow copy of the order object and set delivered_date to null
        const updatedPo = { ...po, delivered_date: null };

        // Map through transactionLines to set the received quantity to 0
        const updatedTransactionLines = po.transactionLines.map(line => ({
            ...line,
            received_qty: 0
        }));

        // Update the po object with the modified transactionLines
        const updatedPO = { ...updatedPo, transactionLines: updatedTransactionLines };
        
        // Set the updated order
        setOrder(updatedPO);
    }
}, [po]); // Add 'po' as a dependency



  const handleChange = (event, index) => {
    const { name, value } = event.target;
    setOrder(prevOrder => {
        const updatedOrder = { ...prevOrder }; // Make a shallow copy of the order object
        if (name === "received_qty") {
            // Update the received field of the product at the specified index
            updatedOrder.transactionLines[index].received_qty= value;
        } else if (name === "delivered_date") {
          const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
          if (value > today) {
              toast.error("Delivered date cannot be in the future");
          } else if (value < prevOrder.date) {
              toast.error("Delivered date cannot be earlier than order date");
          } else {
              
              updatedOrder.delivered_date = value;
          }
        }
        return updatedOrder; // Return the updatedOrder object
    });
};


  // const handleOrderStatus=() => {
  //   const stateObj = { state: 'delivered' , purchaseId:purchaseId};
  //   console.log('state received ',stateObj)
  //   updatePurchaseOrderState('purchase/orders', stateObj ,userData) // Fetch PO from api
  //   .then(data => {if (data.status===200) {toast.success('Products recieved successfully.' );}})
  //   .catch(error => {setError('Failed to fetch data. Please try again later.');})
  //   .finally(() => {setIsLoading(false);});
  // };

  const handleSubmit = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

    // Check if delivered_date is null
    if (order.delivered_date === null) {
        toast.error('Please enter date delivered');
        return;
    }

    // Check if delivered_date is in the future
    if (order.delivered_date > today) {
        toast.error("Delivered date cannot be in the future");
        return;
    }

    // Check if delivered_date is earlier than the order date
    if (order.delivered_date < order.date) {
        toast.error("Delivered date cannot be earlier than order date");
        return;
    }

    // Check if at least one product has delivered_qty > 0
    const hasDeliveredQty = order.transactionLines.some(line => line.received_qty > 0);
    if (!hasDeliveredQty) {
        toast.error("No Product delivered. ");
        return;
    }

    // If all checks pass, proceed with the API call
    recieveProducts('purchase/deliveries', order, userData) // Fetch PO from API
        .then(result => {
            // handleOrderStatus();
            toast.success('Goods received successfully.');
        })
        .catch(error => {
            setError('Failed to fetch data. Please try again later.');
        })
        .finally(() => {
            setIsLoading(false);
        });
};

    console.log('order at this point',order)  
    
  return (
    <>
    <ToastContainer position="top-left" autoClose={4000} />       
          <div className="flex justify-between ">
            <Header
              category=""
              title="Receive Products "
              // color={currentColor}
              className="mb-8 border border-2 " // Add a bottom margin of 4 (adjust as needed)
            />
            
            
          
          </div>
      {/* purchase order preamble info */}
      <div className="flex flex-wrap border border-1 items-center mb-8 mt-3 rounded-lg">
        <div className="w-full sm:w-auto sm:flex-1  sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
          <p className="whitespace-nowrap text-slate-400 text-xs text-right">Order Date</p>
          <p className="whitespace-nowrap uppercase font-bold text-xs text-main text-right" style={{ color: currentColor }}>
          {order?.date ? new Date(order?.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : ''}
          </p>
        </div>
        <div className="w-full sm:w-auto sm:flex-1  sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
          <p className="whitespace-nowrap text-slate-400 text-xs ">Delivered Date</p>
          <input
            type="date"
            name="delivered_date"
            className="block  px-0 w-full text-sm font-bold text-gray-900 bg-transparent 
              appearance-none dark:text-white dark:border-gray-600 
              dark:focus:border-blue-500 focus:outline-none focus:ring-0 
              focus:border-blue-600 peer"
            placeholder=" "
            value={order?.delivered_date}
            onChange={(event) => handleChange(event)}
            style={{ color: currentColor }}
          />
        </div>
        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
            <p className="whitespace-nowrap text-slate-400 text-xs text-right">Reference #</p>
            <p className="whitespace-nowrap uppercase font-bold text-main text-xs text-right" style={{ color: currentColor }}>
            {order?.reference ? order?.reference : ''}
            </p>
        </div>
        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
            <p className="whitespace-nowrap text-slate-400 text-xs text-right">Supplier</p>
            <p className="whitespace-nowrap uppercase font-bold text-main text-xs text-right" style={{ color: currentColor }}>
            {order?.partners?.name}
            </p>
        </div>           
        {/* Conditionally render Approve and Cancel buttons */}
        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row">
          <button 
              className="bg-transparent border ${currentColor} mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Cancel Order" // Tooltip content
              // onClick={() => handleOrderStatus('cancelled')} // Pass "cancel" value to handleCancelOrder
          >
              <RiMailSendLine  size={24} /> {/* This will render a larger check icon */}
          </button>

          <button 
              className="bg-transparent border ${currentColor} mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Cancel Order" // Tooltip content
              // onClick={() => handleOrderStatus('cancelled')} // Pass "cancel" value to handleCancelOrder
          >
              <AiFillPrinter  size={24} /> {/* This will render a larger check icon */}
          </button>
        </div>
        {/* End of Approve and Cancel buttons */}   
      </div>
      {/* end purchase order preamble info */}

      {/* Product list */}
      <div className="mx-auto bg-white rounded-lg shadow-lg border border-1"style={{ overflowX: 'hidden', overflowY: 'auto', maxWidth: '100%' }}>
        {/* Column Header */}
        <div className="flex justify-between p-3 border-b">
          <div style={{ width: '10px', textAlign: 'left' }}>
            <span className="text-sm text-blue-400">#</span>
          </div>
          <div style={{ width: '160px', textAlign: 'left' }}>
              <span className="text-sm text-blue-400">Item Name </span>
          </div>
      
          <div style={{ width: '80px', textAlign: 'left' }}>
              <span className="text-sm text-blue-400">Measure</span>
          </div>
            
          <div style={{ width: '80px', textAlign: 'left' }}>
              <span className="text-sm text-blue-400">Qty</span>
          </div>
          <div style={{ width: '80px', textAlign: 'center' }}>
              <span className="text-sm text-blue-400">Received</span>
          </div>
        </div>
        {/* End Column Header */}

        {/* Product list*/}
        {order && order.transactionLines && order.transactionLines.map((product, index) => (
          <div key={product.id} className="p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
            <div className="flex items-center" style={{ width: '10px' }}>
              <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                <div className="leading-snug text-sm text-gray-900 ">{index + 1}</div>
              </div>
            </div>
            
            <div className="flex items-center" style={{ width: '160px' }}>
              <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                <div className="leading-snug text-sm text-gray-900 font-bold">{product?.name}</div>
              </div>
            </div>

            <div className="flex items-center" style={{ width: '80px' }}>
              <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                <div className="leading-snug text-sm text-gray-900 ">{product?.uom_name}</div>
              </div>
            </div>

            <div className="flex items-center" style={{ width: '80px' }}>
              <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                <div className="leading-snug text-sm text-gray-900 ">{product?.quantity}</div>
              </div>
            </div>
            <div className="flex items-center" style={{ width: '80px' }}>
              <div className="ml-4 flex flex-col" style={{ width: '80px' }}>
                <div className="text-sm" style={{ width: '100px', textAlign: 'left' }}>
                  <input
                    type="text"
                    name="received_qty"
                    className="text-sm text-gray-900 font-bold bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={product?.received_qty}
                    onChange={(event) => handleChange(event, index)}
                  />
                
                </div>
              </div>
            </div>

          </div>
        ))}
      </div>
      {/* Submit Button */}
      <div className="flex justify-end mt-4 mr-4">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-lg"
          style={{ backgroundColor: currentColor }}
          onClick={handleSubmit}
        >
          Receive
        </button>
      </div>
    </>
  )
}

export default RecieveProducts
