// invoice api.js
import axios from 'axios';
import API_BASE_URL from '../../../../config';

// get all 
export const getInvoices= async (page,userData,filter) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { state:filter}, // Send purchaseId as a query parameter
  };


  try {
    const response = await axios.get(`${API_BASE_URL}/${page}`, config);
    const data = response.data;
   // console.table('Invoices API Response:', data); // Log the data
   
    return {
      count: data.length,
      result: data,
      error: null, // Include this line to ensure consistency
    };
  } catch (error) {
    console.error('Error fetching data:', error);
      // Ensure that the returned object has the expected structure
    return {
      count: 0,
      result: null,
      error: 'Failed to fetch data. Please try again later.',
    };
  }
};



  export const getInvoice = async (page, invoiceId, userData) => {
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { id:invoiceId}, // Send purchaseId as a query parameter
    };
    try {
      // console.log('in api sir ',page, purchaseId, userData);
      const response = await axios.get(`${API_BASE_URL}/${page}`, config);
      const data = response.data;
      console.table('purchase order sir  API Response:', data); // Log the data
  

      return {
        count: data ? 1 : 0, // Assuming data is the product details
        result: data ? [data] : null,
        error: null,
      };
    } catch (error) {
      console.error('Error fetching purchase order:', error);
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch purchase order details. Please try again later.',
      };
    }
  }; 
 
  



export const createInvoice = async (page, invoice, userData) => {
  console.log('invoice in api ',invoice)
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
  };

  try {
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json','Authorization': `Bearer ${userData.data.token}`},
      body: JSON.stringify({
        due_date:invoice.due_date,
        invoice_no:invoice.invoice_no,
        state:invoice.invoice_state,
        order_id:invoice.id,
        session_id: 1,
      })
    });
 
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    
    //const responseData = await response.json(); // Parse response body as JSON
   // const message = responseData.message; // Extract the message
   // console.log('message from api',message)
   
   const data = await response.json();
    console.log('Response from API:', data); // Log the response from the API
    
    return {
      data
      //message: 'Operation was completely successful.' data,
    };
  } catch (err) {
      console.error('Error adding order:', err);
      throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
  }
};

export const updateInvoice = async (page, invoice ,userData) => {
  console.log('update invoice api',invoice)
  try {
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.data.token}`
      },
      body: JSON.stringify({
        id:invoice.id,
        due_date:invoice.due_date,
        invoice_no:invoice.invoice_no,
        state:invoice.state,
        session_id: 1,
      })
    });
      if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
   

      const data = await response.json();
      console.log('Response invoiuce from API:', data); // Log the response from the API

   
      return {
        data
        // count: data.length,
        // result: data,
        // error: null, // Include this line to ensure consistency
      };

  } catch (err) {
      console.error('Error adding order:', err);
      throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
  }
};



export const deleteInvoice = async (page, invoiceId,  userData) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    // params: { id: productId }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:invoiceId,
      active: 0,
    }, config);
  
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
  } catch (err) {
    console.error('Error deleting  Invoice:', err);
    throw new Error('Failed to delete Invoice'); // Rethrow a custom error message to be handled by the calling code
  }
};




