import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { FcCancel } from "react-icons/fc";
import { format } from 'date-fns';
import { Audio } from 'react-loader-spinner';
import CreatableSelect from "react-select/creatable";
import { BsFillSendFill } from "react-icons/bs";
import { FaTrashAlt } from 'react-icons/fa';
import { IoSearchSharp } from "react-icons/io5";
import { FcApproval } from "react-icons/fc";
import { CiEdit } from "react-icons/ci";
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout, Header } from '../../../../components';
import fetchData from '../../../../contexts/Api';
import { handleCategoryApi } from '../utils/api';

const EditProductCategory = ({ singleData, allData, onClose }) => {
    const [category, setCategory] = useState({});
    const [originalCategory, setOriginalCategory] = useState({});
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const [imgType, setImgType] = useState('');
    const [displayImg, setDisplayImg] = useState('');
    const [isEditable, setIsEditable] = useState(false); // Add state to manage edit mode
    //const [apiMethod, setApiMethod] = useState('');
    const { isLoggedIn, userData, currentColor, actionCount, setActionCount } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        setCategory(singleData);
        setOriginalCategory(singleData);
        setCategories(allData);
        setIsLoading(false);
    }, [singleData]);

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setCategory(prevCategory => ({
            ...prevCategory,
            [name]: value,
        }));
    };

    const handleImgChange = (event) => {
        const { name, files } = event.target;
        if (name === "img") {
            convertFile(files);
        }
    };

    const convertFile = (files) => {
        console.log('convert images');
        if (files && files.length > 0 && files[0] instanceof File) {
            const fileRef = files[0];
            const fileType = fileRef.type;
            const reader = new FileReader();
            reader.readAsBinaryString(fileRef);
            reader.onload = (ev) => {
                const photo = { data: btoa(ev.target.result), contentType: fileType };
                setDisplayImg(btoa(ev.target.result));
                setImgType(fileType);
                setCategory(prevCategory => ({ ...prevCategory, photo: photo }));
            };
        }
    };

    const handleClose = () => {
        navigate(-1);
    };

    const getChangedFields = (original, updated) => {
        const changedFields = {};
        for (const key in updated) {
            if (updated.hasOwnProperty(key) && original[key] !== updated[key]) {
                changedFields[key] = updated[key];
            }
        }
        if (original.id) { changedFields.id = original.id; } // Include id if it exists in original
        return changedFields;
    };

    const handleDelete = () => {
        //setApiMethod('DELETE');
        handleSubmit(null,'DELETE');
    };

    const handleEdit = () => {handleSubmit(null,'PUT');};

    const handleSubmit = async (e, method) => {
        // e.preventDefault();
        console.log('products at this point', category);
        if (!category.name) {
          toast.error('Please enter name ');
          return;
        }
        
        const changedFields = getChangedFields(originalCategory, category);
        console.log('method', method);
        const apiMethod = method;
        
        setIsLoading(true);
      
        try {
          const data = await handleCategoryApi('product_categories', changedFields, userData, apiMethod);
      
          if (data.error) {
            // If there's an error, display it
            const errorMessage = data.error.message || 'Error handling request.';
            const statusCode = data.error.status;
      
            if (statusCode === 409) {
              toast.error('Product category already exists', errorMessage);
            } else if (statusCode === 406) {
              toast.error('Cannot delete category because there are products associated with it', errorMessage);
            } else {
              toast.error(`Error ${statusCode}: ${errorMessage}`);
            }
          } else {
            // Check response status
            if (data.data.status === 200) {
              toast.success('Product category updated successfully.', data.data.statusText);
              setActionCount(actionCount + 1); // Trigger reload of products list
            } else {
              toast.error(`Error ${data.data.status}: ${data.data.statusText}`);
            }
          }
        } catch (error) {
          toast.error(error.message || 'An unexpected error occurred.');
        } finally {
          setActionCount(actionCount + 1); // Trigger reload of products list
          setIsLoading(false);
        }
      };
        

    const photo = (displayImg)? `data:${imgType};base64,${displayImg}`: category.photoUrl;
    const photo_url = (photo) ? photo : category.photoUrl;
    console.log('usere',userData?.data)
    const isUserAuthorized = 
    (userData?.data.employee?.department?.name?.toLowerCase() === 'procurement' ||
    userData?.data.role?.role?.toLowerCase() === 'executive');


    return (
        <>
            <div className="">
                <div className="flex">
                    <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Product Category</h2>
                </div>
                <div>
                    {isLoading ? (
                        <div className="flex items-center flex-col">
                            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
                            <p className="mt-2">Processing data. Please wait...</p>
                        </div>
                    ) : (
                        <>
                            {/* menu icon */}
                            <div className="w-full sm:w-auto sm: px-4 py-2 flex flex-row justify-end">
                                {isUserAuthorized && (
                                    <>
                                        <button
                                            className={`bg-transparent mt-3 hover:bg-blue-400 hover:text-white text-blue-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? 'hidden' : ''}`}
                                            title="Edit"
                                            onClick={() => setIsEditable(true)}
                                        >
                                            <CiEdit size={20} color="blue" />
                                        </button>
                                        <button
                                            className={`bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? '' : 'hidden'}`}
                                            title="Delete"
                                            onClick={handleDelete}
                                        >
                                            <FcCancel size={20} color="blue" />
                                        </button>
                                    </>
                                )}
                                <button
                                    className={`bg-transparent mt-3 hover:bg-blue-400 hover:text-white text-blue-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? '' : 'hidden'}`}
                                    title="Save"
                                    onClick={handleEdit}
                                >
                                    <BsFillSendFill size={20} color="blue" />
                                </button>
                            </div>
                            {/* end menu icons */}

                            <div className="">
                                <ToastContainer position="top-center" autoClose={2000} />
                                <div className="max-w-xl mx-auto">
                                    {isLoading && (
                                        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                                            <Audio type="Bars" color="#FFF" height={50} width={50} />
                                        </div>
                                    )}
                                    <div className="w-full">
                                        <form className="shadow-md rounded px-8 pt-4 mb-2">
                                            <div className="relative z-0 mb-8 group">
                                                <input
                                                    type="text"
                                                    id="title"
                                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                    placeholder=" "
                                                    name="name"
                                                    onChange={(event) => handleTextChange(event)}
                                                    value={category?.name}
                                                    disabled={!isEditable} // Conditionally disable input
                                                />
                                                <label
                                                    htmlFor="productname"
                                                    className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Category Name
                                                </label>
                                            </div>
                                            <div className="relative z-0 mb-8 group">
                                                <input
                                                    type="text"
                                                    id="parent"
                                                    name="parent"
                                                    className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                    placeholder=" "
                                                    list="parentsList" // Link the input to the datalist
                                                    onChange={(event) => handleTextChange(event)}
                                                    value={category?.parentCategory?.name}
                                                    disabled={!isEditable} // Conditionally disable input
                                                />
                                                <datalist id="parentsList">
                                                    {categories && categories.map((parent) => (
                                                        <option key={parent.name} value={parent.name} />
                                                    ))}
                                                </datalist>
                                                <label
                                                    htmlFor="page"
                                                    className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Parent Category
                                                </label>
                                            </div>
                                            <div className="relative z-0 mb-3 w-full group flex items-center">
                                                <input
                                                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                                                    type="file"
                                                    accept="image/*"
                                                    name='img'
                                                    onChange={(event) => handleImgChange(event)}
                                                    disabled={!isEditable} // Conditionally disable input
                                                />
                                                <label
                                                    htmlFor="img"
                                                    className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Upload picture
                                                </label>
                                                {photo_url && (
                                                    <img
                                                        className="rounded-full h-24 w-24"
                                                        src={photo_url}
                                                        alt="product category"
                                                    />
                                                )}
                                            </div>
                                            {isEditable && (
                                                <div className="text-center mb-2">
                                                    <button
                                                        className="px-4 py-2 mb-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                                        onClick={handleEdit}
                                                        disabled={!isEditable} // Conditionally disable button
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default EditProductCategory;
