// api.js
import axios from 'axios';
import API_BASE_URL from '../config';

const fetchData = async (page , userData, setData, filter ) => {
 if(page!='pos/order'){
  const filter = { };
  
 }

  // //Set up the config object with headers
  const config = {
    headers: {  Authorization: userData && userData.data && userData.data.token 
      ? `Bearer ${userData.data.token}` : '' },
    params: filter, // Send  query parameter
  }
  try {
    const response = await axios.get(`${API_BASE_URL}/${page}`, config);
    if(page='products'){
     
     }

    // Check if response.data has data
    if (response.data && (Array.isArray(response.data) ? response.data.length > 0 : Object.keys(response.data).length > 0)) {
        // If data is an array and has items, or if data is an object with keys
        const fetchedData = response.data;
        setData(fetchedData);
    } else {
        // Handle the case when response.data is empty or undefined
        console.log('No data found');
    }
} catch (error) {
    // Handle any errors that occurred during the request
    console.error('Error fetching data:', error);
}

};
export default fetchData;
