import React, { useState, useEffect, useContext } from 'react';
import { Audio } from 'react-loader-spinner';
import { CiEdit } from "react-icons/ci";
import { Layout, Header, Button, Modal, NewPurchaseOrder, EditPurchaseOrder } from '../../../../components';
import fetchData from '../../../../contexts/Api'; 
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer } from 'react-toastify';

const Orders = () => {
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [purchaseOrder, setPurchaseOrder] = useState({}); // selected  purchase order
 
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const { userData, currentColor } = useContext(AppContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Filter categories based on search term
    const filteredPurchaseOrders = purchaseOrders.filter(po =>
      po.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredPurchaseOrders.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
      setIsLoading(true);
      fetchPurchaseOrders();
    }, []);

    const fetchPurchaseOrders = async () => {
      await fetchData('purchase/orders', userData, setPurchaseOrders);
      setIsLoading(false);
    };

    const handleAction = (action,po) => {
      setActionType(action);
      setPurchaseOrder(po);
      setIsModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
    console.log('purchase orders',filteredPurchaseOrders)

  return (
    <Layout>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-2 bg-white rounded-3xl mx-auto">
  <ToastContainer position="top-center" autoClose={2000} />
  <div className="mt-8 max-w-lg ">
    <Header category="" title="Purchase Orders" color={currentColor} />
  </div>
  
  <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
    {isModalOpen && actionType === 'new' && <NewPurchaseOrder  onClose={handleCloseModal} />} 
    {isModalOpen && actionType === 'edit' && <EditPurchaseOrder po={purchaseOrder} onClose={handleCloseModal} />}
    
  </Modal>
  <div className="max-w-lg">
        <button
            className="rounded-lg mb-3 p-1 transition-colors duration-300 ease-in-out hover:shadow-xl text-white"
            style={{ backgroundColor: currentColor }}
            onClick={() => handleAction('new', {})}
            type="button"
        >
            Add Purchase Order
        </button>
    </div>


    {isLoading ? (
        <div className="flex items-center flex-col ">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
        </div>
    ) : (
        <>
            {filteredPurchaseOrders.length === 0 ? (
                // No purchase orders message
                <p></p>
            ) : (
                // Purchase orders list
                <>

                    {/* Your existing code for the purchase orders list */}
                    {/* Search Input */}
                    <div className="max-w-lg">
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            // onChange={e => setSearchTerm(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                        />
                    </div>
                    {/* End Search Input */}
                    {/* Pagination */}
                    <div className="mt-3 flex mb-4 max-w-lg ">
                        {Array.from({ length: Math.ceil(filteredPurchaseOrders.length / itemsPerPage) }).map((_, index) => (
                            <button
                            key={index}
                            onClick={() => paginate(index + 1)}
                            className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                            style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                            >
                            {index + 1}
                            </button>
                        ))}
                    </div>
                    {/* End Pagination */}
                    {/* Product list */}
                    <div className="mx-auto bg-white rounded-lg shadow-lg">
                        {/* Column Header */}
                        <div className="flex justify-between p-3 border-b">
                            <div style={{ width: '160px', textAlign: 'center' }}>
                                <span className="text-sm text-blue-400">PO # </span>
                            </div>
                        
                            <div style={{ width: '80px', textAlign: 'center' }}>
                                <span className="text-sm text-blue-400">Supplier</span>
                            </div>
                            <div style={{ width: '80px', textAlign: 'center' }}>
                                <span className="text-sm text-blue-400">Amount</span>
                            </div>
                            
                            <span className="text-sm text-blue-400">Actions</span>
                        </div>
                        {/* End Column Header */}
                        {currentItems.map(po => (
                            <div key={po.id} className="p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
                                <div className="flex items-center" style={{ width: '160px' }}>
                                    <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                                    <div className="leading-snug text-sm text-gray-900 font-bold">{po?.reference}</div>
                                    <div className="text-xs text-slate-400" style={{ width: '160px', textAlign: 'center' }}>
                                        Created: {po?.date ? new Date(po.date).toLocaleDateString('en-US', 
                                        { month: 'short', day: 'numeric', year: 'numeric' }) : ""}0
                                    </div>
                                    <div className="leading-snug text-xs">
                                        {po?.state && (
                                            <span 
                                                style={{ color: 'white', 
                                                    backgroundColor: 
                                                    po.state.toLowerCase() === 'cancelled' ? 'red' :
                                                    po.state.toLowerCase() === 'draft' ? 'blue' :
                                                    po.state.toLowerCase() === 'approved' ? 'green' : 'inherit',
                                                    borderRadius: '0.25rem', 
                                                    padding: '0.25rem 0.5rem', 
                                                    display: 'inline-block' 
                                                }}>
                                                {po.state.charAt(0).toUpperCase() + po.state.slice(1).toLowerCase()}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        
                            <div className="flex items-center" style={{ width: '160px' }}>
                                <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                                    <div className="leading-snug text-sm text-gray-900 font-bold">{po?.partners?.name}</div>
                                    
                                </div>
                            </div>
                        
                            <div className="" style={{ width: '80px', textAlign: 'center' }}>
                                {po?.totalTaxedPrice ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(po.totalTaxedPrice) : ""}
                            </div>
                            <button 
                                className="h-6 px-2 text-sm font-bold text-blue-400 rounded-full hover:bg-blue-100"
                                onClick={() => handleAction('edit', po)}
                            >
                                <CiEdit size={20}/>
                            </button>
                        </div>
                        ))}
                    </div>
                    {/* end Product list */}
                    {/* Pagination */}
                    <div className="mt-3 flex mb-4 max-w-lg ">
                        {Array.from({ length: Math.ceil(filteredPurchaseOrders.length / itemsPerPage) }).map((_, index) => (
                            <button
                                key={index}
                                onClick={() => paginate(index + 1)}
                                className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                                style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            )}
        </>
    )}






 
  
  
  
  



  
</div>
    </Layout>
  );
  };

export default Orders;
