import React, { useState, useEffect, useRef, useContext } from "react";
import { FiPlusCircle , FiTrash2 } from 'react-icons/fi';
import { FaTrashAlt  } from 'react-icons/fa';
import { FcApproval } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { IoClose } from "react-icons/io5";
import { AiFillPrinter } from "react-icons/ai";
import { RiMailSendLine } from "react-icons/ri";

import { ToastContainer, collapseToast, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import { Header, Button} from '../../../../components';
//import { getSuppliers} from '../modules/purchases/suppliers/utils/api';
import { getProducts} from '../../../../data/ProductService';

import fetchData from '../../../../contexts/Api'; 


import { getPurchase, updatePurchaseOrder, updatePurchaseOrderState} from '../utils/api';
import { convertStringToValue } from "@syncfusion/ej2/maps";
 
const EditPurchaseOrder = ({po, onClose }) => {
    const { currentColor,userData} = useContext(AppContext);
    const [suppliers, setSuppliers] = useState([]); // State for product data
    const [products, setProducts] = useState([]); // State for product data
    const [uoms, setUoms] = useState([]); // State for unit of 
    const [taxTypes, setTaxTypes] = useState([]); // State for tax types
    const [taxes, setTaxes] = useState([]); // State for tax types
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [order, setOrder] = useState({});
    const [showProductForm, setShowProductForm] = useState(false); // Initially hide form
    
  
    useEffect(() => {
      if (Object.keys(po).length > 0 && po.transactionLines && po.transactionLines.length > 0) {
        const updatedTransactionLines = po.transactionLines.map(line => ({ // add new field recoived in transactionLines
          ...line,discount_amount: 0 }));
        const updatedPO = {...po, transactionLines: updatedTransactionLines };
        setOrder(updatedPO);
      }
      //setOrder(po);
      fetchUoms();
      fetchTaxTypes();
    }, []);
    
    useEffect(() => {
      // Fetch suppliers order details using the purchaseId
      fetchSuppliers() // Fetch PO from api
      
    }, []);

    useEffect(()=>{
      getProducts('products', userData) // Fetch PO from api
      .then(data => {setProducts(data.result);})  // Assuming the API returns the purchase order details
      .catch(error => {setError('Failed to fetch productsorder details. Please try again later.');})
      .finally(() => {setIsLoading(false);});
    },[]);
    
    const fetchUoms = async () => {
      await fetchData('uoms', userData, setUoms);
      setIsLoading(false);
    };

    const fetchTaxTypes = async () => {
      await fetchData('tax_types', userData, setTaxTypes);
      setIsLoading(false);
    };
    const fetchSuppliers = async () => {
      await fetchData('suppliers', userData, setSuppliers);
      setIsLoading(false);
    };
    
    
  

    
    

  //   const handleAddRow = () => {
  //     const lastOrder = order;
  //     if (
  //       lastOrder.products.length === 0 || (
  //         lastOrder.products.length > 0 &&
  //         lastOrder.products[lastOrder.products.length - 1].lineTotal !==0
  //       )
  //     ) {
  //       const updatedOrder = { ...order }; // Spread the properties of the first order
  //       updatedOrder.products = updatedOrder.products || []; // Ensure products array is initialized
  //       updatedOrder.products.push({
  //           product: '', productId: 0, measure: '', uomId: '', price: 0, tax: 0, taxId: '', discount: 0, qty: 1, total: 0
  //       });
  //       setOrder([updatedOrder]); // Set the updated order back into the array
  //     } else {
  //         alert('Please complete entering  for previous row before adding a new row.');
  //     }
  // };

  const handleAddRow = () => {
    console.log('here sir in add row ')
    setOrder((prevOrder) => ({
      ...prevOrder,
      transactionLines: [
        ...prevOrder.transactionLines,
        {
          name: '',
          uom_name: '',
          unit_price: '',
          tax_type_name: '',
          discount_percentage: '',
          quantity: '',
          line_total_untaxed: '',
          // Add other necessary fields with default values
        },
      ],
    }));
  };
  
  

  const removeAllRows = () => {
    setOrder(prevOrder => {
      const updatedOrder = [...prevOrder];
      updatedOrder.products = [];   // Clear the products array of the first order

      // Recalculate the subtotal for the current order
      const orderSubTotal = updatedOrder.products.reduce((total, product) => total + parseFloat(product.lineTotal), 0);
      updatedOrder.subTotal = orderSubTotal;

      // Recalculate the total discount for the current order
      const orderTotalDiscount = updatedOrder.products.reduce((total, product) => total + (parseFloat(product.discount || 0) / 100) * parseFloat(product.price) * parseFloat(product.qty), 0);
      updatedOrder.totalDiscount = orderTotalDiscount.toFixed(2);

      // Recalculate the total tax for the current order
      const orderTotalTax = updatedOrder[0].products.reduce((total, product) => {
        const discountedPrice = parseFloat(product.price) * (1 - (parseFloat(product.discount || 0) / 100));
        return total + (parseFloat(product.taxPercent || 0) / 100) * discountedPrice * parseFloat(product.qty);
      }, 0);
      updatedOrder.totalTax = orderTotalTax.toFixed(2);

      // Recalculate the grand total for the current order
      const orderGrandTotal = parseFloat(updatedOrder.subTotal || 0) - parseFloat(updatedOrder.totalDiscount || 0) + parseFloat(updatedOrder.totalTax || 0);
      updatedOrder.grandTotal = orderGrandTotal.toFixed(2);

      return updatedOrder;
    });
};

      const removeRow = (productId) => {
        setOrder(prevOrder => {
            const updatedOrder = [...prevOrder];
            // Find the index of the product with the given productId
            const productIndex = updatedOrder.products.findIndex(product => product.productId === productId);
            // If productIndex is -1, product with productId was not found
            if (productIndex !== -1) {
                // Remove the product from the products array
                updatedOrder.products.splice(productIndex, 1);
    
                // Recalculate the subtotal for the current order
                const orderSubTotal = updatedOrder.products.reduce((total, product) => total + parseFloat(product.lineTotal), 0);
                updatedOrder.subTotal = orderSubTotal;
    
                // Recalculate the total discount for the current order
                const orderTotalDiscount = updatedOrder.products.reduce((total, product) => total + (parseFloat(product.discount || 0) / 100) * parseFloat(product.price) * parseFloat(product.qty), 0);
                updatedOrder.totalDiscount = orderTotalDiscount.toFixed(2);
    
                // Recalculate the total tax for the current order
                const orderTotalTax = updatedOrder.products.reduce((total, product) => {
                    const discountedPrice = parseFloat(product.price) * (1 - (parseFloat(product.discount || 0) / 100));
                    return total + (parseFloat(product.taxPercent || 0) / 100) * discountedPrice * parseFloat(product.qty);
                }, 0);
                updatedOrder.totalTax = orderTotalTax.toFixed(2);
    
                // Recalculate the grand total for the current order
                const orderGrandTotal = parseFloat(updatedOrder.subTotal || 0) - parseFloat(updatedOrder.totalDiscount || 0) + parseFloat(updatedOrder.totalTax || 0);
                updatedOrder.grandTotal = orderGrandTotal.toFixed(2);
            }
            return updatedOrder;
        });
    };
    
      

    const CalculateTotals= (updatedOrder,productIndex)=>{
      // Ensure updatedOrder and products are defined
      if (!updatedOrder || !updatedOrder.transactionLines) {
        console.error("Invalid or missing order or products array");
        return updatedOrder; // Return the original order object
      }
  
      // Ensure productIndex is within bounds
      if (productIndex < 0 || productIndex >= updatedOrder.transactionLines.length) {
        console.error("Product index is out of bounds");
        return updatedOrder; // Return the original order object
      }
      const price = parseFloat(updatedOrder.transactionLines[productIndex]?.unit_price) || 0;
      const qty = parseFloat(updatedOrder.transactionLines[productIndex].quantity) || 0;
      const discountRate = parseFloat(updatedOrder.transactionLines[productIndex].discount_percentage) || 0;
      const discountRatio = parseFloat(discountRate) / 100; // Convert discountRate percentage to decimal
      const taxRate = parseFloat(updatedOrder.transactionLines[productIndex].tax_percentage) || 0;
      const taxRatio = parseFloat(taxRate) / 100; // Convert discountRate percentage to decimal
     
     //calculate linetotals
      const lineTotal = parseFloat(price * qty); //Apply discountRate to price before tax calculation
      updatedOrder.transactionLines[productIndex].line_total_untaxed = lineTotal;
      
      //calculate discount
      const discountAmount = parseFloat(price) * qty * discountRatio;
      const discountedPrice = parseFloat(price) * (1- discountRatio);
      updatedOrder.transactionLines[productIndex].discount_amount = discountAmount;
      
      const totalExclTax = discountedPrice * parseFloat(qty); //subTotal
      
      //calculate tax 
      const tax = parseFloat(taxRate);
      const taxAmt = parseFloat((totalExclTax * (tax / 100)));
      updatedOrder.transactionLines[productIndex].taxAmt = taxAmt;
      const totalInclTax = totalExclTax + taxAmt;
      updatedOrder.transactionLines[productIndex].totalInclTax = totalInclTax;
  
      // Calculate product totals
      const subTotal = updatedOrder.transactionLines.reduce((line_total_untaxed, product) => line_total_untaxed + parseFloat(product.line_total_untaxed), 0);
      updatedOrder.totalUntaxedPrice = subTotal;
      const discountTotal = updatedOrder.transactionLines.reduce((discount_amount, product) => discount_amount + parseFloat(product.discount_amount), 0);
      updatedOrder.totalDiscount = discountTotal;
      const taxTotal = updatedOrder.transactionLines.reduce((line_total_tax, product) => line_total_tax + parseFloat(product.line_total_tax), 0);
      updatedOrder.totalTax = taxTotal;
      const grandTotal = updatedOrder.transactionLines.reduce((line_total_taxed, product) => line_total_taxed + parseFloat(product.line_total_taxed), 0);
      updatedOrder.totalTaxedPrice = grandTotal;
  
    }
  

    const handleChange = (event, productIndex) => {
      const { name, value } = event.target;
      setOrder(prevOrder => {const updatedOrder = { ...prevOrder };
      // Check if updatedOrder is defined and not null
      if (updatedOrder && updatedOrder.productLines) {
          // Check if productIndex is within bounds
          if (productIndex >= 0 && productIndex < updatedOrder.productLines.length) {
            const product = updatedOrder.productLines[productIndex];
            // Now you can safely use the product object
          } else {
            return
            // Handle case where productIndex is out of bounds
            console.error("Product index is out of bounds");
          }
        } else {
          console.error("Invalid or missing order or products array");
          // Handle case where updatedOrder or updatedOrder.products is missing or not an array
        }
    
        // Update order fields based on the changed input
        if (name === "supplier") {
          updatedOrder.supplier = value;
          const selectedSupplier = suppliers.find((supplier) => supplier.name === value);
          if (selectedSupplier) {
            updatedOrder.contactPerson = selectedSupplier.contact_person;
            updatedOrder.phone = selectedSupplier.phone_number;
            updatedOrder.supplierId = selectedSupplier.id;
          } 
        } else if (name === "orderDate") {updatedOrder.orderDate = value;
        } else if (name === "name") {
          console.log('here sie ',name,value)
          const selectedProduct = products.find(product => product.name === value);
          updatedOrder.transactionLines[productIndex].quantity = 1;
          updatedOrder.transactionLines[productIndex].discount_percentage= ''
          if (selectedProduct) {
            updatedOrder.transactionLines[productIndex] = {
              ...updatedOrder.transactionLines[productIndex],
              name: value,
              id: selectedProduct.id,
              uom_name: selectedProduct.prices.buy_uom_name,
              uom_id: selectedProduct.prices.buy_uom_id,
              tax_type_name: selectedProduct.buy_tax_info.name,
              tax_type_id: selectedProduct.buy_tax_type_id,
              tax_percentage: selectedProduct.buy_tax_info.percentage,
              unit_price: selectedProduct.prices.buy_price_new,
              quantity: 1,
              discount_percentage: '',
            };
          } else {
            updatedOrder.transactionLines[productIndex].name = value;
          }
        } else if (name === "uom_name") {
          updatedOrder.transactionLines[productIndex].uom_name= value;
          const selectedUom = uoms.find(item=> item.name === value);
          if (selectedUom) { updatedOrder.transactionLines[productIndex].uom_id= selectedUom.id;}
        } else if (name === "tax_type_name") {
            updatedOrder.transactionLines[productIndex].tax_type_name= value;
            const selectedTax = taxTypes.find(item=> item.name === value);
            if (selectedTax) { 
              updatedOrder.transactionLines[productIndex].tax_type_id= selectedTax.id;
              updatedOrder.transactionLines[productIndex].tax_percentage= selectedTax.percentage;
            }
        } else {updatedOrder.transactionLines[productIndex][name] = value;}
        CalculateTotals(updatedOrder, productIndex);
        
        return updatedOrder;
      });
    };
    
  
    // Function to calculate line total for a product
    const calculateLineTotal = (product) => {
      //const discountAmt = parseFloat(product.price) * parseFloat(product.qty) * (parseFloat(product.discount) / 100);
      const totalExclTax = parseFloat(product.price) * parseFloat(product.qty) 
      //- discountAmt;
      const totalInclTax = totalExclTax 
      //+ (totalExclTax * (parseFloat(product.taxPercent) / 100));
      return totalInclTax.toFixed(2);
    };

    const handleOrderStatus = (value) => {
      //const stateObj = { state: value, purchaseId: purchaseId };
    
      updatePurchaseOrderState('purchase/orders', order, userData)
        .then(response => {
          // Parse response as JSON
          console.log('response from api',response)
          return response.json();

        })
        .then(data => {
          console.log('message from api', data);
          if (data.status === 200) {
            toast.success('Purchase Order ' + value + ' successfully.');
          }
        })
        .catch(error => {
          setError('Failed to fetch data. Please try again later.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    
    const handleSubmitOrder = async () => {
    // event.preventDefault(); // Prevent default form submission behavior
    window.scrollTo({ top: 0, behavior: 'smooth' });
    try {
        const response = await ( updatePurchaseOrder('purchase/orders',  order, userData))
        toast.success('Purchase Order Updated  Successfully.');      
      } catch (err) {
        toast.error('Error submitting form');
      }
    };
       
    
    //console.log('value at this point',order[0].state)
    
    const shouldRenderApproveButton = order[0]?.state !== 'approved';
    const shouldRenderCancelButton = order[0]?.state !== 'cancelled';

    {console.log('Order value at this point :', order)}
    return (
        <div className="">
          <ToastContainer position="top-left" autoClose={2000} />       
          <div className="flex justify-between ml-8 mt-8">
            <Header
              category=""
              title="Edit Purchase Order"
              color={currentColor}
              className="mb-8 border border-2 pb-8 mt-8" // Add a bottom margin of 4 (adjust as needed)
            />
            
            
          
          </div>
            {/* // order data */}
            {order && (
                //order div
                
                <div className="rounded-md p-4">
                    {/* purchase order preamble info */}
                    <div className="flex flex-wrap items-center mt-3 border-1 gray-200 rounded-lg">
                        <div className="w-full sm:w-auto sm:flex-1  sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
                            <p className="whitespace-nowrap text-slate-400 text-xs text-right">Date</p>
                            <p className="whitespace-nowrap uppercase font-bold text-xs text-main text-right" style={{ color: currentColor }}>
                            {order?.date ? new Date(order?.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : ''}
                            </p>
                        </div>
                        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
                            <p className="whitespace-nowrap text-slate-400 text-xs text-right">Reference #</p>
                            <p className="whitespace-nowrap uppercase font-bold text-main text-xs text-right" style={{ color: currentColor }}>
                            {order?.reference}
                            </p>
                        </div>
                        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
                            <p className="whitespace-nowrap text-slate-400 text-xs text-right">Purchase Value #</p>
                            <p className="whitespace-nowrap uppercase font-bold text-main text-xs text-right" style={{ color: currentColor }}>
                            {order?.totalTaxedPrice ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(order.totalTaxedPrice) : ""}
                           
                            </p>
                        </div>
                        <div className="w-full sm:w-auto sm:flex-1  sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
                            <p className="whitespace-nowrap text-slate-400 text-xs text-right">Status</p>
                            <p className="whitespace-nowrap uppercase font-bold text-xs text-right" style={{ color: order.state === 'draft' ? 'blue' : order.state === 'cancelled' ? 'red' : order.state === 'approved' ? 'green' : 'inherit' }}>
                            {order?.state }
                            </p>
                        </div>
                        
                           
                    </div>
                    {/* end purchase order preamble info */}
                  
                  
                    {/* // supplier info */}
                    <div className="flex p-4 mt-3 border-1 gray-200 rounded-lg">
                        <div className="relative z-0 mb-1 w-full group">
                            <input
                                type="text"
                                id="supplier"
                                name="supplier"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="suppliersList" // Link the input to the datalist
                                //onChange={(event) => handleChange(event)}
                                value={order?.partners?.name }
                            
                            />
                            {/* Use the datalist element for autocomplete */}
                            {/* <datalist id="suppliersList">
                                {suppliers.map((supplier) => (
                                <option value={supplier.partners.name} />
                                ))}
                            </datalist> */}
                            <label
                                htmlFor="supplier"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Supplier
                            </label>
                        </div>
                        
                        <div className="relative z-0 mx-8 mb-1 w-full group">
                            <input
                                type="text"
                                id="contactPerson"
                                name="contactPerson"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                               // onChange={(event) => handleChange(event)}
                                value={order?.partners?.contact_person}
                            />
                            
                            <label
                                htmlFor="contactPerson"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Contact Person
                            </label>
                        </div>
                        <div className="relative z-0 mb-1 w-full group">
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                               // onChange={(event) => handleChange(event)}
                                value={order?.partners?.phone_number}
                            />
                            <label
                                htmlFor="phone"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Telephone.
                            </label>
                        </div>
                    </div>
                    
                    {/* //end supplier info */}
                    {/* start Render product form here */}
                   

                    <div className="p-1 mt-3 border-1 gray-200 rounded-lg">







                      {/* <form onSubmit={handleSubmitOrder}> */}
                        <div className="card rounded-lg  justify-center bg-white p-2 border-gray-200">  
                         {/* Product list */}
                    <div className="mx-auto bg-white rounded-lg shadow-lg">
                        {/* Column Header */}
                        <div className="flex justify-between p-3 border-b">
                            <div style={{ width: '160px', textAlign: 'left' }}>
                                <span className="text-xs text-blue-400">Item Name </span>
                            </div>
                        
                            <div style={{ width: '80px', textAlign: 'left' }}>
                                <span className="text-xs text-blue-400">Measure</span>
                            </div>
                            <div style={{ width: '80px', textAlign: 'right' }}>
                                <span className="text-xs text-blue-400">Price</span>
                            </div>
                            <div style={{ width: '100px', textAlign: 'left' }}>
                                <span className="text-xs text-blue-400">Tax</span>
                            </div>
                            <div style={{ width: '80px', textAlign: 'left' }}>
                                <span className="text-xs text-blue-400">Discount</span>
                            </div>
                            <div style={{ width: '50px', textAlign: 'left' }}>
                                <span className="text-xs text-blue-400">Qty</span>
                            </div>
                            <div style={{ width: '80px', textAlign: 'center' }}>
                                <span className="text-xs text-blue-400">Total</span>
                            </div>
                            
                            <span className="text-xs text-blue-400"></span>
                        </div>
                        {/* End Column Header */}
                        {order && order.transactionLines && order.transactionLines.map((product, index) => (
                          <div key={po.id} className="p-2 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
                            <div className="flex items-center" style={{ width: '160px' }}>
                              <input
                                type="text"
                                name="name"
                                className="  text-xs text-gray-900 bg-transparent  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="productsList" // Link the input to the datalist
                                value={product.name}
                                onChange={(event) => handleChange(event, index)} // Pass the index here
                              />
                              {/* Use the datalist element for autocomplete */}
                              <datalist id="productsList">
                                {products ? (
                                  products.map((product, index) => (
                                    <option key={index} value={product.name} />
                                  ))
                                ) : (
                                  <option value="Loading..." />
                                )}
                              </datalist>
                            </div>
                        
                            <div className="flex items-center" style={{ width: '80px' }}>
                              <input
                                type="text"
                                name="uom_name"
                                className="text-xs text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="uomList" // Link the input to the datalist
                                value={product.uom_name}
                                onChange={(event) => handleChange(event, index)}
                              />
                              {/* Use the datalist element for autocomplete */}
                              <datalist id="uomList">
                                {uoms && uoms.map((item, i) => (
                                  <option key={i} value={item.name} />
                                ))}
                              </datalist>
                            </div>
                     
                            <div className="flex items-right" style={{ width: '80px' }}>
                              <input
                                type="text"
                                name="unit_price"
                                className="text-xs text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={product?.unit_price}
                                onChange={(event) => handleChange(event, index)}
                              />
                            </div>
                      
                            <div className="flex items-center" style={{ width: '100px' }}>
                              <input
                                type="text"
                                name="tax_type_name"
                                className="text-xs text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="taxTypeList" // Link the input to the datalist
                                value={product.tax_type_name}
                                onChange={(event) => handleChange(event, index)}
                              />
                              {/* Use the datalist element for autocomplete */}
                              <datalist id="taxTypeList">
                                {taxTypes && taxTypes.map((item, i) => (
                                  <option key={i} value={item.name} />
                                ))}
                              </datalist>

                            </div>
                               
                            <div className="flex items-center" style={{ width: '80px' }}>
                              <input
                                type="text"
                                name="discount_percentage"
                                className="text-xs text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={product.discount_percentage}
                                onChange={(event) => handleChange(event, index)}
                              />
                            </div>

                            <div className="flex items-center" style={{ width: '50px' }}>
                              <input
                                type="text"
                                name="quantity"
                                className="text-xs text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={product.quantity}
                                onChange={(event) => handleChange(event, index)}
                              />
                            </div>
                               
                            <div className="text-xs"style={{ width: '80px', textAlign: 'right' }}>
                                {product?.line_total_untaxed ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(product.line_total_untaxed) : ""}
                            </div>

                            <button
                              type="button"
                              onClick={() => removeRow(product.productId)}
                              //onClick={() => removeRow(index)}
                              className="text-red-500 hover:text-red-700 transition duration-300 inline-flex items-center space-x-1 pl-4"
                            >
                              <FiTrash2 size={10}className="" />
                            </button>
                        </div>
                        ))}
                    </div>
                    {/* end Product list */}

                    <button
                      type="button"
                      onClick={handleAddRow}
                      className="text-blue-500 hover:text-blue-700 transition duration-300 inline-flex items-center space-x-1 text-xs"
                      style={{ marginBottom: "10px" }} // Add margin to the button
                    >
                      <FiPlusCircle className="h-3 w-3 animate-bounce" />
                      <span className="text-xs">Add Product</span>
                    </button>
                    

                          
                          <table className=" w-full text-left text-gray-300">  
                            <tbody>
                              <tr>
                                <td colSpan="5" className="py-2">
                                  
                                </td>
                              </tr>
                              
                              {/* Sub total */}
                              {/* {order[0].subTotal && ( */}
                                <tr>
                                   <td colSpan="6" className="px-4 text-right text-xs text-gray-900">Sub total:</td>
                                  <td colSpan="" className="px-4 border-t text-xs text-right text-gray-900">{order?.totalTaxedPrice ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(order.totalUntaxedPrice) : ""}</td>
                              
                                </tr>
                              {/* )} */}

                              {/* Total discount */}
                              <tr>
                                <td colSpan="6" className="px-4 text-right text-xs text-gray-900">Discount: </td>
                                <td className="px-4 text-dark text-right text-xs">{order?.totalDiscount ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(order.totalDiscount) : ""}</td>

                              </tr>

                              {/* Total tax */}
                              <tr>
                              <td colSpan="6" className="px-4 text-right text-xs text-gray-900">Tax: </td>
                                <td className="px-4 text-dark text-xs text-right text-gray-900">{order?.totalTax ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(order.totalTax) : ""}</td>
                              </tr>

                              {/* Grand total */}
                              <tr>
                                <td colSpan="6" className="px-4 text-right text-xs text-gray-900">Grand Total: </td>
                                <td className="px-4 border-t text-xs text-right text-gray-900">{order?.totalTaxedPrice ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(order.totalTaxedPrice) : ""}
                          </td>
                                
                              </tr>
                              <tr>
                                <td colSpan="7" className="px-4 pt-2 text-right">
                                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-lg"
                                    style={{ backgroundColor: currentColor }}
                                    onClick={handleSubmitOrder}
                                  >
                                    Update Order
                                
                                  </button>
                          </td>
                           
                              </tr>
                            </tbody>
                          </table>
                          {/* Submit button */}
                          <div className="flex justify-end">
                                                      </div>
                        </div>
                        
                      {/* </form> */}
                    </div>
                    {/* stop Render product form here */}
                </div>
                //end order div
              )}  
              {/* // order data */}
            </div>
    );
};
export default EditPurchaseOrder;
