import React, { useState, useEffect, useRef, useContext } from "react";
import { ToastContainer, collapseToast, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillPrinter } from "react-icons/ai";
import { BsFillSendFill } from "react-icons/bs";
import CreatableSelect from "react-select/creatable";

import { Audio } from 'react-loader-spinner';
import { RiMailSendLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import AppContext from '../../../../contexts/AppContext';
import { Header, Button} from '../../../../components';
import { createPayment} from '../utils/api';
// import { StateContext } from "../contexts/ContextProvider";
import '../styles/payment.css'
const NewPayment = ({ data, onClose })=> {
  const [isLoading, setIsLoading] = useState(false);
  const { currentColor,userData} = useContext(AppContext);
 
  const [payment, setPayment] = useState({});
  const [error, setError] = useState(null);

  const options = [
    { value: 0, label: "..." },
    { value: "check", label: "Cheque" },
    { value: "bank_transfer", label: "Bank Transfer" },
    { value: "airtel_money", label: "Airtel Money" },
    { value: "tnm_mpamba", label: "TNM Mpamba" },
  ];

  
  console.log('data at this ytime',data)

  useEffect(() => {
    const today = new Date().toISOString().substr(0, 10);
    const updatedPayment = {...data,date_paid: today};
    setPayment(updatedPayment);
}, [data]);
  
  
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayment(prevPayment => ({...prevPayment,[name]: value}));
  };

  const handlePayMethodChange = (selectedOption) => {
    setPayment(prevPayment => ({
        ...prevPayment,
        pay_method: selectedOption.value // Adjust the property name as needed
    }));
  };



  const handleSubmit = async () => {
    // setIsLoading(true)
    //event.preventDefault(); // Prevent default form submission behavior
    //window.scrollTo({ top: 0, behavior: 'smooth' });
    console.log('payment payment just befire submit sir',payment)
    // Validation checks
    if (!payment.paid) {toast.error('Enter amount paid.');return;}
    if (!payment.pay_method) {toast.error('Enter pay method.');return;}
    if (!payment.account_no) {toast.error('Enter account number.');return;}
    if (!payment.account_name) {toast.error('Enter account name.');return;}
    const selectedDte = new Date(payment.due_date);
    const tdy = new Date();
    if (selectedDte > tdy) {toast.error('Date paid  cannot bein future.');return;}
 
    
 
    createPayment('purchase/payments', payment ,userData) // Fetch PO from api
    .then(result => {
        console.log(' from payments creatpayment  response:', result); // Log the API response here
      toast.success('payment  created  successfully.');
    })
    .catch(error => {setError('Failed to fetch data. Please try again later.');})
    .finally(() => {setIsLoading(false);});
  };


  function formatDate(isoDate) {
    if (!isoDate) return ''; // Handle empty or undefined date
    const date = new Date(isoDate);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    // Pad single digit month/day with a leading zero
    if (month.length === 1) { month = '0' + month; }
    if (day.length === 1) {day = '0' + day;}
    return `${year}-${month}-${day}`;
  }

   console.log('payment at this point sir ',payment
   )  
    
  return (
    <>
      <div className="flex">
          <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New Payment</h2>
        </div>
      
   
      {/* Conditionally render Approve and Cancel buttons */}
      <div className="w-full sm:w-auto sm:flex-1 sm:bpayment-b-0 sm: px-4 py-2 flex flex-row justify-end ">
          <button 
            className="bg-transparent bpayment ${currentColor} mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
            title="Create Invoice" // Tooltip content
            onClick={handleSubmit}
          >
            <BsFillSendFill  size={24} /> {/* This will render a larger check icon */}
          </button>
          <button 
            className="bg-transparent bpayment ${currentColor} mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
            title="Print payment " // Tooltip content
          >
            <AiFillPrinter  size={24} /> {/* This will render a larger check icon */}
          </button>
        </div>
        {/* End of Approve and Cancel buttons */} 
      {/* purchase payment preamble info */}
      <div className="m-2 md:m-1  p-2 md:p-2 bg-gray-50 rounded-3xl">
        <div className="flex flex-wrap "> 
          <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
            <p className="whitespace-nowrap text-slate-400 text-xs text-right">Reference #</p>
            <p className="whitespace-nowrap uppercase font-bold  text-xs text-right" >
            {payment ? payment.reference : ''}
            </p>
          </div>

          <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
            <p className="whitespace-nowrap text-slate-400 text-xs text-right">Due date #</p>
            <p className="whitespace-nowrap uppercase font-bold  text-xs text-right" >
            {payment ? formatDate(payment.due_date) : ''}
          </p>
        </div>
        
        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
          <p className="whitespace-nowrap text-slate-400 text-xs text-right">Amount Due</p>
          <p className="whitespace-nowrap uppercase font-bold text-xs text-right"> {payment.totalTaxedPrice ? payment.totalTaxedPrice.toLocaleString(undefined, { style: 'currency', currency: 'MKW' }) : ''}
          </p>
        </div>

      <div className="w-full sm:w-auto sm:flex-1 sm:bpayment-b-0 sm:border-r border-gray-200 px-4 py-2">
        <p className="whitespace-nowrap text-slate-400 text-xs text-right">Supplier</p>
        <p className="whitespace-nowrap uppercase font-bold  text-xs text-right" >
            {/* {payment.partners.name ? payment.partners.name: ''} */}
            {payment.partners?.name || ''}
        </p>
      </div> 
  </div>
</div>
      
      {/* end purchase payment preamble info */}
        
      
      {/* form */}
     
      <div className="flex justify-center  max-w-full " style={{ width: "500px" }}>
      <ToastContainer position="top-center" autoClose={2000} />
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <Audio type="Bars" color="#FFF" height={50} width={50} />
          </div>
        )}    
    </div>
    {/* start payment form  */}
    <section className="bg-white py-4 antialiased dark:bg-gray-900 md:py-">
      <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
        <div className=" mx-auto max-w-5xl">
          <div className="mt-1 sm:mt-1 lg:flex lg:items-start lg:gap-12">
            
            <div className="mt-6 grow sm:mt-8 lg:mt-0 w-1/2">
            Invoice Summary
              <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50 p-6 dark:border-gray-700 dark:bg-gray-800">
                <div className="space-y-1 text-sm">
                  <dl className="flex items-center justify-between border-t  gap-2">
                    <dt className=" font-normal text-slate-400 text-xs dark:text-gray-400">Price</dt>
                    <dd className="text-sm font-normal text-gray-900 dark:text-white">
                      {payment.totalUntaxedPrice ? payment.totalUntaxedPrice.toLocaleString(undefined, { style: 'currency', currency: 'MKW' }) : ''}
                    </dd>
                  </dl>
                  <dl className="flex items-center justify-between border-t gap-2">
                    <dt className=" font-normal text-slate-400 text-xs dark:text-gray-400">Discount</dt>
                    <dd className="text-sm font-medium text-green-500"> {payment.totalDiscount?payment.totalDiscount:0}</dd>
                  </dl>

                  <dl className="flex items-center justify-between border-t gap-2">
                    <dt className="font-normal text-slate-400 text-xs dark:text-gray-400">Tax</dt>
                    <dd className="text-sm font-normal text-gray-900 dark:text-white">
                      {payment.totalTax ? payment.totalTax.toLocaleString(undefined, { style: 'currency', currency: 'MKW' }) : ''}
                    </dd>
                  </dl>
                  <dl className="flex items-center justify-between gap-2 border-t border-gray-200  dark:border-gray-700">
                  <dt className="font-normal text-slate-400 text-xs dark:text-white">Total</dt>
                  <dd className="text-sm font-bold text-gray-900 dark:text-white">
                    {payment.totalTaxedPrice ? payment.totalTaxedPrice.toLocaleString(undefined, { style: 'currency', currency: 'MKW' }) : ''}
                  </dd>
                </dl>
                <dl className="flex items-center justify-between gap-2 border-t border-gray-200 dark:border-gray-700">
                  <dt className="font-normal text-slate-400 text-xs dark:text-white"> Paid</dt>
                  <dd className="text-sm  text-gray-900 dark:text-white">0
                    {/* {payment.totalTaxedPrice ? payment.totalTaxedPrice.toLocaleString(undefined, { style: 'currency', currency: 'MKW' }) : ''} */}
                  </dd>
                </dl>
                <dl className="flex items-center justify-between gap-2 border-t border-gray-200 pt-2 dark:bpayment-gray-700">
                  <dt className="font-normal text-slate-400 text-xs dark:text-white">Balance</dt>
                  <dd className="text-sm font-bold text-gray-900 dark:text-white">
                    {payment.totalTaxedPrice ? payment.totalTaxedPrice.toLocaleString(undefined, { style: 'currency', currency: 'MKW' }) : ''}
                  </dd>
                </dl>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-center gap-3">
              <img className="h-6 w-auto dark:hidden" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal.svg" alt="" /> 
              <img className="hidden h-6 w-auto dark:flex" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal-dark.svg" alt="" /> 
              <img className="h-6 w-auto dark:hidden" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa.svg" alt="" /> 
              <img className="hidden h-6 w-auto dark:flex" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa-dark.svg" alt="" /> 
              <img className="h-6 w-auto dark:hidden" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard.svg" alt="" /> 
              <img className="hidden h-6 w-auto dark:flex" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard-dark.svg" alt="" /> 
            </div> 
            <p className="text-sm text-bold text-green-700 text-center">Coming soon!</p>
          </div>
          <div action="#" className="">
          Payment
      <div className="mb-1 grid grid-cols-2 gap-4 bg-gray-50 p-4 rounded-xl">
        
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="full_name" className="whitespace-nowrap text-slate-400 text-xs text-right mb-2"> Payment Method* </label>
          <CreatableSelect
            options={options}
            name="pay_method"
            onChange={handlePayMethodChange}
            styles={{
              placeholder: (provided) => ({
                ...provided,
                color: 'rgba(0, 0, 0, 0.5)',
              }),
            }}
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="" className="whitespace-nowrap text-slate-400 text-xs text-right mb-2"> Account Number* </label>
          <input 
            className="block w-full rounded border border-gray-500 bg-gray-50 p-1.5 text-sm text-gray-900 
            focus:border-primary-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-blue-500 
            dark:text-white dark:placeholder:text-gray-400 dark:focus:bpayment-primary-500 
            dark:focus:ring-primary-500" 
            name="account_no"
            type="text"
            onChange={handleChange}
            required 
          />
        </div>
        <div>
          <label htmlFor="" className="whitespace-nowrap text-slate-400 text-xs text-right mb-2"> Account Name* </label>
          <input 
            className="block w-full rounded border border-gray-500 bg-gray-50 p-1.5 text-sm text-gray-900 
            focus:border-primary-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-blue-500 
            dark:text-white dark:placeholder:text-gray-400 dark:focus:bpayment-primary-500 
            dark:focus:ring-primary-500" 
            name="account_name"
            type="text"
            onChange={handleChange}
            required 
          /> 
        </div>
        <div>
          <label htmlFor="" className="whitespace-nowrap text-slate-400 text-xs text-right mb-2">
            Reference #*
          </label>
          <input 
            className="block w-full rounded border border-gray-500 bg-gray-50 p-1.5 text-sm text-gray-900 
            focus:border-primary-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-blue-500 
            dark:text-white dark:placeholder:text-gray-400 dark:focus:bpayment-primary-500 
            dark:focus:ring-primary-500" 
            name="reference"
            type="text"
            onChange={handleChange}
          />  
        </div>
        <div>
        <div>
          <label htmlFor="" className="whitespace-nowrap text-slate-400 text-xs text-right mb-2">
            Date Paid
          </label>
          <input 
            className="block w-full rounded border border-gray-500 bg-gray-50 p-1.5 text-sm text-gray-900 
            focus:border-primary-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-blue-500 
            dark:text-white dark:placeholder:text-gray-400 dark:focus:bpayment-primary-500 
            dark:focus:ring-primary-500" 
            name="paid_paid"
            type="date"
            value={payment?.date_paid}  
            onChange={handleChange}
          />
        </div>
        </div>
        <div>
          <label htmlFor="" className="whitespace-nowrap text-slate-400 text-xs text-right mb-2">
            Amount to pay
          </label>
          <input 
            className="block w-full rounded border border-gray-500 bg-gray-50 p-1.5 text-sm text-gray-900 
            focus:border-primary-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-blue-500 
            dark:text-white dark:placeholder:text-gray-400 dark:focus:bpayment-primary-500 
            dark:focus:ring-primary-500" 
            name="paid"
            type="text"
            onChange={handleChange}
            required 
          />
        </div>
      </div>
      <div className="flex justify-end mt-1"> {/* Aligns items to the end of the container */}
        <button className="bg-blue-500 text-white py-2 px-4 rounded-xl flex items-center" data-tooltip={payment.paid ? payment.paid.toLocaleString(undefined, { style: 'currency', currency: 'MKW' }) : ''} onClick={handleSubmit}
       
        >
          <span className="mr-2">Pay Now</span>
          <svg viewBox="0 0 16 16" className="bi bi-cart2" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
          </svg>
        
        </button>
      </div>
    </div>

        </div>
      </div>
    </div>
  </section>
  {/* end payment form */}
</>
)
}
export default NewPayment
