import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { IoSearchSharp } from "react-icons/io5";
import { Audio } from 'react-loader-spinner';
import { CiEdit } from "react-icons/ci";
import { IoMdTrash } from "react-icons/io";
import { Layout, Header, Modal, NewProduct, EditProduct, DeleteProduct } from '../../../../components';
import fetchData from '../../../../contexts/Api';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer } from 'react-toastify';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const { userData, currentColor, setActionCount, actionCount } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setIsLoading(true);
    fetchProducts();
  }, [actionCount]);

  const fetchProducts = async () => {
    await fetchData('products', userData, setProducts);
    setIsLoading(false);
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber <= totalPages && pageNumber > 0) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset page number on search
  };

  const handleAction = (action, product) => {
    setActionType(action);
    setProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    navigate(-1);
  };

  // console.log('searchTerm:', searchTerm);
  // console.log('filteredProducts:', filteredProducts);
  // console.log('totalPages:', totalPages);
  // console.log('currentPage:', currentPage);
  // console.log('indexOfFirstItem:', indexOfFirstItem);
  // console.log('indexOfLastItem:', indexOfLastItem);
  // console.log('currentItems:', currentItems);

  return (
    <Layout>
      <div className="m-2 md:m-10 mt-12 p-2 md:p-2 bg-white rounded-3xl mx-auto">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-1 justify-center text-center">
          <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-1 flex flex-row justify-end">
            <button
              className="bg-transparent hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose}
            >
              <MdOutlineCancel size={24} color={currentColor} />
            </button>
          </div>
          <Header category="" title="Products" />
        </div>

        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && <NewProduct onClose={handleCloseModal} />}
          {isModalOpen && actionType === 'edit' && <EditProduct singleData={product} data={product} onClose={handleCloseModal} />}
          {isModalOpen && actionType === 'delete' && <DeleteProduct rowData={product} onClose={handleCloseModal} />}
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (
          <>
            <div className='bg-gray-50 rounded-lg p-8 mx-auto'>
              <div className="max-w-lg">
                <button
                  className="rounded-lg mb-3 p-1 transition-colors duration-300 ease-in-out hover:shadow-xl text-white"
                  style={{ backgroundColor: currentColor }}
                  onClick={() => handleAction('new', {})}
                  type="button"
                >
                  Add Product
                </button>
              </div>
              {/* Search Input */}
              

              <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search product..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                        list="allProductsList"
                    />
                    
                    <button
                        className="absolute right-3 top-3 text-blue-500 hover:text-blue-700"
                        onClick={handleSearchChange}
                    >
                        <IoSearchSharp size={20} color={currentColor} />
                    </button>
                </div>
            </div>
              {/* Pagination */}
              <div className="mt-3 flex mb-4 max-w-lg">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-1 py-0.5 rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
              {/* Product list */}
              <div className="mx-auto bg-white rounded-lg shadow-lg">
                {/* Column Header */}
                <div className="flex justify-between p-3 border-b">
                  <div style={{ width: '160px', textAlign: 'center' }}>
                    <span className="text-sm text-blue-400">Product Name</span>
                  </div>
                  <div style={{ width: '100px', textAlign: 'center' }}>
                    <span className="text-sm text-blue-400">Measure</span>
                  </div>
                  <div style={{ width: '80px', textAlign: 'center' }}>
                    <span className="text-sm text-blue-400">Sell Price</span>
                  </div>
                  <div style={{ width: '100px', textAlign: 'center' }}>
                    <span className="text-sm text-blue-400">Qty in stock</span>
                  </div>
                  <div style={{ width: '100px', textAlign: 'center' }}>
                    <span className="text-sm text-blue-400">Re order Qty</span>
                  </div>
                  <span className="text-sm text-blue-400">Actions</span>
                </div>
                {/* End Column Header */}
                {currentItems.map((product, index) => (
                  <div key={product.id} className="p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
                    <div className="flex items-center" style={{ width: '160px' }}>
                      <span className="text-sm text-gray-600 mr-2">{indexOfFirstItem + index + 1}.</span>
                      <img className="rounded-full h-10 w-10" src={product?.photoUrl} alt="" />
                      <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                        <div className="leading-snug text-sm text-gray-900 font-bold">{product?.name}</div>
                        <div className="leading-snug text-xs text-gray-600">{product?.product_categories?.name}</div>
                      </div>
                    </div>
                    <div style={{ width: '100px', textAlign: 'center' }}>{product?.prices?.buy_uom_name}</div>
                    <div style={{ width: '80px', textAlign: 'center' }}>{product?.prices?.sell_price_new}</div>
                    <div style={{ width: '100px', textAlign: 'center' }}>{product?.quantity_instock}</div>
                    <div style={{ width: '100px', textAlign: 'center' }}>{product?.reorder_quantity}</div>
                    <div className="flex items-center gap-2">
                      <button
                        className="h-6 text-sm rounded-full hover:bg-blue-100"
                        onClick={() => handleAction('edit', product)}
                        title='Edit'
                      >
                        <CiEdit size={20} color={currentColor} />
                      </button>
                      <button
                        className="h-6 text-sm rounded-full hover:bg-blue-100"
                        onClick={() => handleAction('delete', product)}
                        title='Delete'
                      >
                        <IoMdTrash size={20} color={currentColor} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {/* end Product list */}
              {/* Pagination */}
              <div className="mt-3 flex mb-4 max-w-lg">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-1 py-0.5 rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
              {/* end Pagination */}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Products;
