import React, { useContext } from 'react';
import AppContext from '../contexts/AppContext';

const Footer = () => {
  const { companyProfile } = useContext(AppContext); // Hook usage should be inside the function body

  return (
    <div className="mt-8">
      <p className="dark:text-gray-200 font-semibold text-gray-700 text-center align-center mx-20">
        © 2023 All rights reserved by {companyProfile.email}
      </p>
    </div>
  );
};

export default Footer;
