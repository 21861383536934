// invoiceUtils.js
import React, { useState, useEffect, useRef, useContext } from "react";
import { toast } from 'react-toastify'; // Assuming you use react-toastify for notifications
import { getInvoices, createInvoice, updateInvoice } from './Api'; // Import API function to create/update invoices
import AppContext from '../../../../contexts/AppContext';



const InvoiceUtils = () => {
    const { isLoggedIn, userData, currentColor, invoices, setInvoices, actionCount,setActionCount,state,setState} = useContext(AppContext);
    useEffect(() => {
       // setIsLoading(true);
        fetchInvoices();
        console.log('approved invoices',invoices)
     }, [actionCount]); // Trigger fetchPurchases when on load
    
    const editInvoice = async (invoice, setInvoice, setIsLoading, setError, userData) => {
  
        console.log('in invoice util', invoice);
        setIsLoading(true);
      
        try {
          const updatedInvoice = { ...invoice };
      
          if (!updatedInvoice.invoice_no) {
            toast.error('Invoice number is required.');
            return;
          }
      
          if (!updatedInvoice.due_date) {
            toast.error('Due date is required.');
            return;
          }
      
          const selectedDate = new Date(updatedInvoice.due_date);
          const today = new Date();
      
          if (selectedDate < today) {
            toast.error('Due date cannot be earlier than today.');
            return;
          }
      
          const result = await updateInvoice('purchase/invoices', updatedInvoice, userData);
          console.log(' from updateInvoice response:', result);
          toast.success('Invoice updated successfully.');
        } catch (error) {
          setError('Failed to update invoice. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      };

      const fetchInvoices = (invoices, setInvoices, setIsLoading, setError, userData, state) => {
        setIsLoading(true);
      
        getInvoices('purchase/invoices', userData, state)
          .then(data => {
            console.log('in utils invoices', data);
            setInvoices(data.result);
          })
          .catch(error => {
            // Handle error if needed
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
      
  return (
    <div>
      
    </div>
  )
}

export default InvoiceUtils;
