// PurchaseOrderService.js
import axios from 'axios';
import API_BASE_URL from '../../../../config';

// get all 
export const getPurchases= async (page,userData,filter,action) => {
  console.log('filterss',filter)
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
   //params: { state:filter,delivered:0}, // Send purchaseId as a query parameter
    params: filter, // Send purchaseId as a query parameter
  };


  try {
    const response = await axios.get(`${API_BASE_URL}/${page}`, config);
    const data = response.data;
   // console.table('purchase order API Response:', data); // Log the data
   
    return {
      count: data.length,
      result: data,
      error: null, // Include this line to ensure consistency
    };
  } catch (error) {
    console.error('Error fetching data:', error);
      // Ensure that the returned object has the expected structure
    return {
      count: 0,
      result: null,
      error: 'Failed to fetch data. Please try again later.',
    };
  }
};

export const getDeliveries= async (page,userData,filter,action) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { state:filter}, // Send purchaseId as a query parameter
  };


  try {
    const response = await axios.get(`${API_BASE_URL}/${page}`, config);
    const data = response.data;
   // console.table('deliveries  API Response:', data); // Log the data
   
    return {
      count: data.length,
      result: data,
      error: null, // Include this line to ensure consistency
    };
  } catch (error) {
    console.error('Error fetching data:', error);
      // Ensure that the returned object has the expected structure
    return {
      count: 0,
      result: null,
      error: 'Failed to fetch data. Please try again later.',
    };
  }
};



  export const getPurchase = async (page, purchaseId, userData) => {
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { id:purchaseId}, // Send purchaseId as a query parameter
    };
    try {
      // console.log('in api sir ',page, purchaseId, userData);
      const response = await axios.get(`${API_BASE_URL}/${page}`, config);
      const data = response.data;
      console.table('purchase order sir  API Response:', data); // Log the data
  

      return {
        count: data ? 1 : 0, // Assuming data is the product details
        result: data ? [data] : null,
        error: null,
      };
    } catch (error) {
      console.error('Error fetching purchase order:', error);
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch purchase order details. Please try again later.',
      };
    }
  }; 
 
 
  
  export const addPurchaseOrder = async (page, order, userData) => {
    console.log('order in api ',order)  
    try {
      const transactionLines = order.products.map(product => ({
        name:product.product,
        product_id: product.productId,
        quantity: product.qty,
        uom_id:product.uomId,
        discount_percentage:product.discountRate,
        unit_price:product.price,
        tax_type_id:product.taxId
        // Add more properties as needed for each product
      }));
   
      const response = await fetch(`${API_BASE_URL}/${page}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.data.token}`
        },
         body: JSON.stringify({
              transactionLines: transactionLines,
              partner_id:order.supplierId,
              date:order.orderDate,
              name: order.supplier + '_' + order.orderDate , 
              session_id:1
        })
        
       
      });
        if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
        const data = await response.json();
     
        return {
          data
          // count: data.length,
          // result: data,
          // error: null, // Include this line to ensure consistency
        };

    } catch (err) {
        console.error('Error adding order:', err);
        throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
    }
};

export const updatePurchaseOrder = async (page, order, userData) => {
  console.log('order form form',order)
  try {
    const transactionLines = order[0].products.map(product => ({
      name:product.product,
      product_id: product.productId,
      quantity: product.qty,
      uom_id:product.uomId,
      discount_percentage:product.discount,
      unit_price:product.price,
      tax_type_id:product.taxId
      // Add more properties as needed for each product
    }));
 
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.data.token}`
      },
      body: JSON.stringify({
        transactionlines: transactionLines,
        partner_id:order[0].supplierId,
        date:order[0].orderDate,
        state: order[0].state,
        name: order[0].supplier + '_' + order[0].orderDate , 
        session_id:1
      })
      
     
    });
      if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
      const data = await response.json();
   
      return {
        data
        // count: data.length,
        // result: data,
        // error: null, // Include this line to ensure consistency
      };

  } catch (err) {
      console.error('Error adding order:', err);
      throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
  }
};



export const updatePurchaseOrderState = async (page, stateObj ,userData) => {
  const { state, purchaseId } = stateObj; // Destructure the status and purchaseId from stateObj
  try {
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.data.token}`
      },
      body: JSON.stringify({
        id: purchaseId,
        state:state,
      })
    });
      if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
   

      const data = await response.json();
      console.log('Response from API:', data); // Log the response from the API

   
      return {
        data
        // count: data.length,
        // result: data,
        // error: null, // Include this line to ensure consistency
      };

  } catch (err) {
      console.error('Error adding order:', err);
      throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
  }
};

export const recieveProducts = async (page, order, userData) => {
  console.log('order in api',order)

  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    // params: { id: productData.id }, // Send productId as a query parameter
  };
  const transactionLines = order.transactionLines.map(product => ({
    order_line_id:product.id,
    product_id: product.product_id,
    ordered_quantity: product.quantity,
    delivered_quantity: product.received_qty,
    uom_name:product.uom_name     
  }));
 

  // Determine the status based on the delivered and ordered quantities
  let state = "fully_delivered";
  for (const product of order.transactionLines) {
    if (product.received_qty < product.quantity) {
      state = "partially_delivered";
      break;
    }
  }
  console.log('state',state)


  //date Format as required by  API 

  var formattedDate = order.delivered_date + "T00:00:00.000Z";
  //const formattedDate = submittedDte.toISOString().slice(0, 10);
  console.log('here sir in delivery transaction lines ',formattedDate)
  try {
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.data.token}`
      },
      body: JSON.stringify({
        order_id:order.id,
        date:order.delivered_date,
        session_id: 1,
        //state:'partially_delivered',
        state:state,
        transactionLines:transactionLines
      })
    });
      if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
   
     // const data = await response.json();
      return {
         // data,
          message: 'Operation was completely successful.'
      };
  } catch (err) {
      console.error('Error adding order:', err);
      throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
  }
};

export const updateReceivedProducts = async (page, deliver, userData) => {
 
  const transactionLines = deliver.transactionLines.map(product => ({
    order_line_id: product.id,
    product_id: product.product_id,
    ordered_quantity: product.ordered_quantity,
    delivered_quantity: product.delivered_quantity
    ,
    uom_name: product.uom_name
  }));


  // Determine the status based on the delivered and ordered quantities
  let state = "full_delivery";
  for (const product of deliver.transactionLines) {
    if (product.delivered_quantity < product.ordered_quantity) {
      state = "partial_delivery";
      break;
    }
  }
  
  // Date format as required by the API
  const formattedDate = deliver.date + "T00:00:00.000Z";
  console.log('state in api',state,formattedDate);
  try {
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.data.token}`
      },
      body: JSON.stringify({
        id: deliver.id,
        date: formattedDate,
        state: state, // Include the status in the request body
        transactionLines: transactionLines,
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Response from API:', data); // Log the response from the API

    return {
      data
    };

  } catch (err) {
    console.error('Error adding order:', err);
    throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
  }
};

export const createInvoice = async (page, order, userData) => {

  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    // params: { id: productData.id }, // Send productId as a query parameter
  };
 // const submittedDte=order.dueDate; // Format the date as required 
  //const formattedDate = submittedDte.toISOString().slice(0, 10);
  //console.log('in api again sir ',order)
  try {
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.data.token}`
      },
      body: JSON.stringify({
        due_date:order.dueDate,
        invoice_no:order.invoiceId,
        state:order.invoiceState,
        order_id:order.id,
        session_id: 1,
      })
    });
 
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Response from API:', data); // Log the response from the API
    
  //  const responseData = await response.json(); // Parse response body as JSON
  //  // const message = responseData.message; // Extract the message
  //  console.log('message from api',responseData)
   
    // const data = await response.json();
    return {
      message: 'Operation was completely successful.'     // data,
    };
  } catch (err) {
      console.error('Error adding order:', err);
      throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
  }
};


export const deletePurchase = async (page, productId,  userData) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    // params: { id: productId }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:productId,
      active: 0,
    }, config);
  
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
  } catch (err) {
    console.error('Error deleting  product:', err);
    throw new Error('Failed to delete product'); // Rethrow a custom error message to be handled by the calling code
  }
};

export const updateProductQty = async (page, productData, userData) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
     params: { id: productData.id }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:productData.id,
      quantity_instock: productData.quantity_instock,
    }, config);

    if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
  } catch (err) {
    console.error('Error updating product:', err);
    throw new Error('Failed to update product Quantity'); // Rethrow a custom error message to be handled by the calling code
  }
};


