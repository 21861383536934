import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { AiOutlinePlus, AiOutlineMinus, AiOutlineCloseCircle } from 'react-icons/ai';
import { MdOutlineCancel } from 'react-icons/md';
import { TiCancel } from "react-icons/ti";
import { IoIosCart } from "react-icons/io";
import { IoSearchSharp } from "react-icons/io5";
import { TbListDetails } from "react-icons/tb";
import { IoMdAdd } from "react-icons/io";
import { MdGridView } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReactToPrint } from 'react-to-print';
import AppContext from '../../../../contexts/AppContext';
import { Layout, Header, Modal, PosReceipt, PosCheckout, PosBillView, CustomDropdown } from '../../../../components';
import { getEmployees } from '../../../authentication/users/utils/api';
import { cleanObject, createOrder, handleApi, formatLongDateTime, formatShortDateTime, formatCurrency } from '../../../../utils/utils';

const Pos = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isEmployeeSelected, setIsEmployeeSelected] = useState(false); // Adjust the initial state as needed
  const { currentColor, userData, products } = useContext(AppContext);
  const navigate = useNavigate(); // Get navigate function from React Router
  const [bills, setBills] = useState([]);
  const [originalBills, setOriginalBills] = useState([]); // will get bills and will not be changing 
  const [orders, setOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState({});
  const [currentBill, setCurrentBill] = useState({});
  const [billId, setBillId] = useState('');
  const [billLabel, setBillLabel] = useState('');
  const [orderId, setOrderId] = useState('');
  const [cart, setCart] = useState([]);
  const [receiptData, setReceiptData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [mode, setMode] = useState("bill"); // Show bills by default
  const [modalToOpen, setModalToOpen] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amountDue, setAmountDue] = useState(0);
  const [searchQuery, setSearchQuery] = useState(''); // Ensure it's a string
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const initialProductCount = 12;

  // Use useMemo to filter products based on searchQuery
  // Ensure filteredProducts handles searchQuery correctly
  const filteredProducts = useMemo(() => {
    const queryLower = typeof searchQuery === 'string' ? searchQuery.toLowerCase() : '';
    return products.filter(product => {
      const nameIncludesQuery = product.name?.toLowerCase().includes(queryLower) || false;
      const barcodeIncludesQuery = product.barcode?.includes(searchQuery) || false;
      return nameIncludesQuery || barcodeIncludesQuery;
    });
  }, [products, searchQuery]);

 

  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, [products]);

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    // Limit the products to the initial count after filtering
    setDisplayedProducts(filteredProducts.slice(0, initialProductCount));
  }, [filteredProducts]);

  const fetchEmployees = async () => {
    setIsLoading(true);

    try {
      const employeesData = await getEmployees('employees', userData);
      const updatedEmployees = employeesData.data.map(employee => ({
        ...employee,
        full_name: `${employee.first_name} ${employee.last_name}`
      }));
      setEmployees(updatedEmployees);
    } catch (error) {
      console.error('Error fetching employees:', error);
      toast.error('Failed to fetch employees');
    } finally {
      setIsLoading(false);
    }
  };

  // const handleSearch = (query) => {
  //   setSearchQuery(query || ''); // Ensure query is a string
  // };

  const handleSearch = (query) => {
    setSearchQuery(query);
  
    if (query) {
      const matchedProduct = products.find(product => product.barcode === query);
      if (matchedProduct) {
        handleProductClick(matchedProduct); // Use handleProductClick to add product to cart
        setSearchQuery(''); // Optionally clear the search query after adding to cart
      }
    }
  };

  const resetCart = () => { setCart([]); }; // Reset the cart state to an empty array

  const handleNewOrderBtnClick = (bill) => {
    const label = 'BILL #' + bill.id;
    setBills([]);
    setMode('order');
    setAmountDue(bill?.totalBillTaxedPrice);
    setBillLabel(label);
    setBillId(bill?.id);

    resetCart(); // Reset cart
  };

  const handleNewBillBtnClick = () => {
    console.log(' here sir in handle new bill');
    setBills([]);
    setBillId('');
    setAmountDue(0);
    setMode('order');
    setBillLabel("NEW BILL");
    resetCart(); // Reset cart
  };

  const handleBillDetailClick = (bill) => {
    setCurrentBill(bill);
    setMode('order');
    setModalToOpen('Detail');
    setIsModalOpen(true);
  };

  const handleCheckOutClick = (bill) => {
    console.log('current order in checkout  ', cart);
    setModalToOpen('Checkout');
    setIsModalOpen(true);
  };

  const handleShowBills = () => {
    console.log('here sir in show bills');
    setBills(originalBills);
    setMode('bill');
  };

  const getCartItemCount = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const handleEmployeeSelect = async (employee) => {
    setBills([]); // Clear any existing orders
    setIsEmployeeSelected(true); // Mark the employee as selected
    setSelectedEmployee(employee); // Set the selected employee
    const apiMethod = 'GET'; // Set the API method to GET
    const cleanedBill = { employee_id: employee.id, paid: 0 }; // Define the filter for fetching bills, currently empty
    try {
      // Fetch bills
      const data = await handleApi('pos/bills', cleanedBill, userData, apiMethod);
      console.log('bill data:', data.data);

      if (data.data && data.data.length > 0) {
        setBills(data.data);
        setOriginalBills(data.data);
        console.log('have data length sir', data.data.length);
        setMode('bill');
      } else {
        console.log('no data length sir', data.data.length);
        handleNewBillBtnClick();
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const handleCloseModal = () => {
    setMode('bill');
    setIsModalOpen(false);
    resetCart();
  };

  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const calculateSubtotal = () => {
    let totalPriceSum = 0;
    cart.forEach(item => {
      totalPriceSum += parseFloat(item.totalPrice) || 0; // Convert totalPrice to a number and add to sum, handling undefined or non-numeric values
    });
    return totalPriceSum;
  };

  const calculateTotal = () => { return calculateSubtotal(); };

  const handleProductClick = (product) => {
    const existingProductIndex = cart.findIndex((item) => item.id === product.id);
    if (existingProductIndex !== -1) {
      const updatedList = [...cart];
      updatedList[existingProductIndex].quantity++;
      updatedList[existingProductIndex].totalPrice = updatedList[existingProductIndex].quantity * updatedList[existingProductIndex].unitPrice;
      setCart(updatedList);
    } else {
      const newProduct = {
        ...product,
        quantity: 1,
        unitPrice: product.prices.sell_price_new,
        totalPrice: product.prices.sell_price_new * 1, // Initial total price based on quantity 1
        discount_percentage: "0.00",
        tax_type_id: 1,
        image: product.photo && product.photo.contentType && product.photo.data
          ? `data:${product.photo.contentType};base64,${product.photo.data}`
          : "https://via.placeholder.com/200.png/CCCCCC/FFFFFF?text=Placeholder"
      };
      setCart([...cart, newProduct]);
    }
  };

  const handleRemoveProduct = (productId) => {
    const updatedList = cart.filter((item) => item.id !== productId);
    setCart(updatedList);
  };

  const handleQuantityChange = (productId, price, type) => {
    const updatedList = cart.map((item) => {
      // Prevent quantity from becoming zero and ensure item.price is a number
      const newQuantity = type === 'minus' ? Math.max(item.quantity - 1, 1) : item.quantity + 1;
      return item.id === productId
        ? {
            ...item,
            quantity: newQuantity,
            totalPrice: newQuantity * item.unitPrice, // Calculate totalPrice using the new quantity and price
          }
        : item;
    });

    setCart(updatedList);
  };

  const handleSubmitOrder = async () => {
    const now = new Date();
    const immediatePayment = ""; // This is not immediate payment. It has to go to bill
    const cleanedOrder = createOrder(selectedEmployee, cart, immediatePayment, billId);
    const apiMethod = 'POST';
    setIsLoading(true);
    // API Call
    handleApi('pos/orders', cleanedOrder, userData, apiMethod)
      .then(async (data) => {
        console.log('from api sir', data.data);
        if (data.data && data.data.length > 0) {
          setMode('bill');
        } else {
          setMode('order');
          console.log('api did not return anything');
        }
      })
      .catch(error => {
        toast.error(error.message); // Display the error message to the user
      })
      .finally(() => {
        resetCart(); // Reset cart
        setMode('bill');
        handleEmployeeSelect(selectedEmployee);
        setIsLoading(false);
      });
  };

  const totalAmount = calculateTotal();
  console.log('bills', bills);
  


  return (
    <Layout>
    {/* <div className="w-full flex flex-col md:flex-row">  */}
      <div className="bg-gray-50 rounded-2xl"> 
        <div className=" ">
          <div className="w-full sm:w-auto sm:flex-1  sm: px-4 flex flex-row justify-end">
            <button
              className=" bg-transparent hover: hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose} // Call handleClose on click
            >
              <MdOutlineCancel size=  {24} color={currentColor}/>
            </button>
          </div>
            {/* <Header category="" title="Point of Sale"  /> */}
            <div className="text-2xl uppercase  text-center  font-bold p-4 max-w-full md:max-w-md lg:max-w-lg mx-auto" style={{color:currentColor}}>Point of Sale</div>
          </div>

        
        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="100%">
        {/* <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="100%" showCloseButton={false}> */}
          {isModalOpen  && modalToOpen==='Checkout' && <PosCheckout amountDue={totalAmount} cart={cart}  employee={selectedEmployee}  onClose={handleCloseModal} />}
          {isModalOpen  && modalToOpen==='Detail' && <PosBillView bill={currentBill}  onClose={handleCloseModal} />} 
          {/* {isModalOpen  && modalToOpen==='Detail' && <PosBillView bill={originalBills}  onClose={handleCloseModal} />} */}
        </Modal> 

          <div class="flex space-x-4 mx-4">
          
            <div className="">
              <ToastContainer position="top-center" autoClose={2000} />
            </div>
            {/* start bill panel  */}
            <div id="billpanel" className="mb-4 flex-auto duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] md:w-1/3 rounded-2xl">
          {/* sales person */}
              <div id="salepersonpanel" className="mb-4 py-2 px-6 bg-white rounded-lg border border-gray-100 max-w-full md:max-w-md lg:max-w-lg mx-auto">
                <div id="search" className="p-1 md:m-1  rounded-3xl">
                  <div className="">  
                    <CustomDropdown employees={employees} onSelect={handleEmployeeSelect} currentColor={currentColor}/>
                  </div>
                  {selectedEmployee && (
                    <div className="px-2 flex items-center justify-between bg-white">
                      <div className="flex items-center space-x-2"> {/* Container for image and name */}
                        <img
                          src={selectedEmployee.photo_url}
                          alt={selectedEmployee.full_name}
                          className="w-16 h-16 rounded-full"
                        />
                        <span className="font-semibold text-2xl text-gray-700 ">
                          {selectedEmployee.full_name}
                        </span>
                      </div>
                      <div className="flex items-center space-x-2"> {/* Align buttons to the right */}
                        {/* {Array.isArray(originalBills) && originalBills.length > 0 && mode === 'bill' && ( */}
                        {mode === 'bill' && Array.isArray(bills) && bills.length > 0 && (
                          <button
                          className="px-3 py-2 text-xs font-medium rounded-lg bg-white focus:outline-none flex items-center space-x-1"
                          style={{ border: `1px solid ${currentColor}`, color: currentColor }}
                          
                            onClick={() => handleNewBillBtnClick()}
                          >
                           
                            <IoMdAdd/>
                            <span className="ml-1 "> New Bill</span>

                          </button>

                        )}
                        {mode === 'order' && Array.isArray(originalBills) && originalBills.length > 0 && Array.isArray(cart) && cart.length === 0 && (
                          <button
                          className="px-3 py-2 text-xs font-medium rounded-lg bg-white focus:outline-none flex items-center space-x-1"
                          style={{ border: `1px solid ${currentColor}`, color: currentColor }}
                            onClick={() => handleShowBills()}
                          >
                            <MdGridView/>
                            <span className="ml-1 ">Show bills</span>
                          </button>


                        )}
                      </div>
                    </div>
                  )}

                </div>
              </div>
              {/* end salesperson */}
              {isEmployeeSelected ? (
                bills.length > 0 ? (
                  <div id="billlistpanel" className="mb-4 p-4 bg-white rounded-lg border border-gray-100 max-w-full md:max-w-md lg:max-w-lg mx-auto">
                  <h2 className="text-2xl font-bold mb-6 text-gray-900">Bills</h2>
                  <ul className="space-y-6">
                    {bills.map((bill, index) => (
                      <li key={index} className="flex flex-col md:flex-row items-start md:items-center py-2 px-4 bg-gray-50 rounded-lg border border-gray-100 transition-transform transform hover:scale-105">
                        <div className="flex-1">
                          <h3 className="text-lg font-semibold text-gray-800 mb-4">
                            <span>Bill #{bill?.id}</span>
                            <div className="flex space-x-2">
                              <button
                                className="flex items-center px-3 py-1.5 text-xs font-medium rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-100 focus:outline-none"
                                style={{ borderColor: currentColor, color: currentColor }}
                                title="Show Detail"
                                onClick={() => handleBillDetailClick(bill)}
                              >
                                <TbListDetails className="w-4 h-4" />
                                <span className="ml-1">Detail</span>
                              </button>
                              <button
                                className="flex items-center px-3 py-1.5 text-xs font-medium rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-100 focus:outline-none"
                                style={{ borderColor: currentColor, color: currentColor }}
                                title="Add Order"
                                onClick={() => handleNewOrderBtnClick(bill)}
                              >
                                <IoMdAdd className="w-4 h-4" />
                                <span className="ml-1">Add</span>
                              </button>
                            </div>
                          </h3>
                          <div className="space-y-1 text-gray-700">
                            <div className="flex items-center">
                              <span className="w-32 text-gray-800 font-medium"># of Orders:</span>
                              <span className="text-gray-900 font-medium bg-white text-gray-900 px-1 rounded">{bill?.billOrders.length}</span>
                            </div>
                            <div className="flex items-center">
                              <span className="w-32 text-gray-600 font-medium">Amount:</span>
                              <span className="text-gray-900 font-medium bg-white text-gray-900 px-1 rounded">{formatCurrency(parseFloat(bill?.totalBillTaxedPrice))}</span>
                            </div>
                            <div className="flex items-center">
                              <span className="w-32 text-gray-800 font-medium">Last Order:</span>
                              <span className="text-gray-900 font-medium bg-white text-gray-900 px-1 rounded">{bill?.date ? formatLongDateTime(bill.date) : 'N/A'}</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                
                
                ) : (
                  <div id="productlistpanel" className="mb-4">
        <div id="productpanel" className="rounded-2xl flex-shrink-0 mb-4 duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] bg-white border border-gray-100">
          <div id="search" className="p-1 md:m-1 rounded-3xl">
            <div className="relative">
              <input
                type="text"
                placeholder="Search product or barcode..."
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
              />
              <button className="absolute right-3 top-3 text-blue-500 hover:text-blue-700">
                <IoSearchSharp size={20} color={currentColor} />
              </button>
              <div id="productList" className={`flex flex-wrap max-h-96 overflow-y-auto scrollbar-thumb-${currentColor} scrollbar-track-gray-900`}>
                {displayedProducts.map((item, index) => (
                  <div key={index} className="flex flex-col items-center m-1 rounded-lg hover:shadow-lg" style={{ width: "90px", height: "70px" }} onClick={() => handleProductClick(item)}>
                    <div className="flex items-center gap-3 dark:border-gray-600 rounded-lg hover:bg-gray-100">
                      <img className="rounded-lg h-14 w-auto max-h-14" src={item?.photoUrl} alt={item?.name} />
                    </div>
                    <span className="font-thin text-xs mt-auto text-center" style={{ maxWidth: "120px", wordWrap: "break-word" }}>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
  
                )
              ) : null}
            </div>
            {/* end  bill panel  */}
            {/* <div className="md:w-1/2 md:pl-4"> */}
            {/* start pos panel  */}
            { mode === 'order' && (
            <div id="pospanel" className="rounded-2xl flex-shrink-0 w-1/3 mx-8 mb-4 duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] bg-white md:w-1/2 px-4 border border-gray-100">
              <div className="flex justify-between items-center py-4 px-2 bg-white border-b border-gray-100 rounded-lg">
                <div className="flex items-center">
                  {billLabel  && mode === 'order' &&  (
                    <div className="flex flex-col items-start">
                      {/* Current Bill */}
                      <span className="text-lg font-semibold"> {billLabel}</span>
                      <span className="text-xs text-gray-500 font-semibold"> {formatCurrency(amountDue)}</span>
                    </div>
                  )}
                </div>

                {/* Container for Cart Icon and Close Button */}
                <div className="flex items-center space-x-4">
                 
                    <>
                      {/* Cart Icon */}
                      <span className={`font-bold text-lg ${currentColor} flex items-center relative`}>
                        <IoIosCart size={40} color={currentColor} />
                        {cart.length > 0 && (
                          <span className="absolute top-[1px] right-[5px] transform translate-x-1/2 -translate-y-1/2 bg-gray-500 text-white rounded-full px-1 text-xs font-semibold">
                      
                            {getCartItemCount()}
                          </span>
                        )}
                      </span>
                      {/* Close Button */}
                      {Array.isArray(cart) && cart.length > 0 && ( 
                        <button
                          id="minus"
                          // className="bg-gray-600 hover:bg-gray-700 text-white py-1 px-1 rounded-lg transition-all duration-300 flex items-center"
                          onClick={() => resetCart()}
                        >
                          <TiCancel size={28} title="Cancel this cart" color="gray"/>
                          <span className="ml-2"></span>
                        </button>
                      )}
                    </>
                 
                </div>
              </div>


              <hr></hr>
             
              
              <div className="flex flex-col ">
                {/* Iterate through cart */}
                {cart.map((item, index) => (
  <div key={index} className="flex items-center border-b border-gray-300 p-2">
    <img className="rounded-lg h-14 w-14 object-cover" src={item.image} alt="" />
    <div className="flex-1 mx-2">
      <p className="text-sm font-medium">
        {item.name}
        <span className="text-xs text-gray-600 ml-1">
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MKW' }).format(item.unitPrice)}
        </span>
      </p>
      <div className="flex items-center justify-between mt-1">
        <div className="flex items-center border border-gray-300 rounded">
          <button id="minus" className="px-2 py-1 border-r border-gray-300 text-red-600" onClick={() => handleQuantityChange(item.id, item.totalPrice, 'minus')}>
            <AiOutlineMinus />
          </button>
          <p className="px-2 text-green-600 text-sm">{item.quantity}</p>
          <button id="plus" className="px-2 py-1 border-l border-gray-300 text-green-600" onClick={() => handleQuantityChange(item.id, item.totalPrice, 'plus')}>
            <AiOutlinePlus />
          </button>
        </div>
        <p className="font-semibold text-sm">
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MKW' }).format(item.totalPrice)}
        </p>
        <button onClick={() => handleRemoveProduct(item.id)} className="text-red-500">
          <AiOutlineCloseCircle color="gray"/>
        </button>
      </div>
    </div>
  </div>
))}

    
                {totalAmount > 0 && (
                    <div className="flex justify-between items-center border-t border-gray-300 mb-2">
                      <p className="text-gray-500 font-bold dark:text-gray-200"></p>
                      <p className="text-gray-500 dark:text-gray-200 font-bold text-lg">Total</p>
                      <p className="font-bold ml-8 text-lg">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MKW' }).format(parseFloat(totalAmount))}</p>
                      <div className="text-right mt-4">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded-lg" style={{ backgroundColor: currentColor }} onClick={() => handleSubmitOrder()}>
                          Order
                        </button>
                      </div>
                      
                    </div>
                  )}
                  {totalAmount > 0 && !billId &&(
                    <div className="text-right mt-1">
                      <button className="bg-green-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded-lg" onClick={() =>   handleCheckOutClick()}>
                        Pay Now 
                      </button>
                    </div>
                  )}

              </div>
            </div>
            )}
             {/* end pos panel  */}
            

            {/* </div> */}
            {/* <Modal open={checkOutModal} onClose={handleCloseModal}    amountDue={amountDue} resetPos={resetPos}>
             
              <PosCheckout/>
            </Modal> */}
            {/* <PosReceipt receiptData={receiptData} /> */}
            
          </div>

        </div>
      </Layout>
    );
  };

export default Pos;
