import React, { useState, useEffect, useRef, useContext } from "react";
import { ToastContainer, collapseToast, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillPrinter } from "react-icons/ai";
import { RiMailSendLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import AppContext from '../contexts/AppContext';
import { Header, Button} from '../components';
import { updatePurchaseDeliverState, recieveProducts, updateReceivedProducts} from '../data/PurchaseService';
import { StateContext } from "../contexts/ContextProvider";
import { popupClose } from "@syncfusion/ej2-react-schedule";
const EditRecievedProducts = ({ po, onClose })=> {
  const [isLoading, setIsLoading] = useState(true);
  const { currentColor,userData} = useContext(AppContext);
  const [deliver, setDeliver] = useState({});
  const [recieved, setReceived] = useState(0);

  const [error, setError] = useState(null);

  // useEffect(() => {
  //  setDeliver(deliver)
  // }, [po]);

  useEffect(() => {
    if (Object.keys(po).length > 0 && po.transactionLines && po.transactionLines.length > 0) {
      const updatedTransactionLines = po.transactionLines.map(line => ({ // add new field recoived in transactionLines
        ...line,received: 0 }));
      const updatedPO = {...po, transactionLines: updatedTransactionLines };setDeliver(updatedPO);
    }
    setDeliver(po)

  }, []);

  




  

const handleChange = (event, index) => {
    const { name, value } = event.target;
    setDeliver(prevDeliver => {
        const updatedDeliver = { ...prevDeliver }; // Make a shallow copy of the deliver object
        if (name === "delivered_quantity") {
          // Update the received field of the product at the specified index
          updatedDeliver.transactionLines[index].delivered_quantity = value;
      } else if (name === "date") {
          const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
          if (value > today) {
              toast.error("Delivered date cannot be in the future");
          } else if (value < prevDeliver.date) {
              toast.error("Delivered date cannot be earlier than order date");
          } else {
              updatedDeliver.date = value;
          }
      }
      // else{
      //     setDeliver((prevDeliver) => ({
      //       ...prevDeliver,
      //       [name]: value
      //     }));
      //   }
        return updatedDeliver; // Return the updatedDeliver object
    });
};


  // const handleDeliverStatus=() => {
  //   const stateObj = { state: 'delivered' , purchaseId:purchaseId};
  //   console.log('state received ',stateObj)
  //   updatePurchaseDeliverState('purchase/delivers', stateObj ,userData) // Fetch PO from api
  //   .then(data => {if (data.status===200) {toast.success('Products recieved successfully.' );}})
  //   .catch(error => {setError('Failed to fetch data. Please try again later.');})
  //   .finally(() => {setIsLoading(false);});
  // };

  const handleSubmit = async () => {
     //event.preventDefault(); // Prevent default form submission behavior
    window.scrollTo({ top: 0, behavior: 'smooth' });
   console.log('deliver in submoit',deliver);
    updateReceivedProducts('purchase/deliveries', deliver ,userData) // Fetch PO from api
    .then(result => {
      // handleDeliverStatus();
      toast.success('Goods received successfully.');
    })
    .catch(error => {setError('Failed to fetch data. Please try again later.');})
    .finally(() => {setIsLoading(false);});
    
  };
 console.log('deliver at this point',deliver)  
    
  return (
    <>
    <ToastContainer position="top-left" autoClose={2000} />       
          <div className="flex justify-between ">
            <Header
              category=""
              title="Received Products "
              // color={currentColor}
              className="mb-8 bdeliver bdeliver-2 " // Add a bottom margin of 4 (adjust as needed)
            />
            
            
          
          </div>
      {/* purchase deliver preamble info */}
      <div className="flex flex-wrap bdeliver bdeliver-1 items-center mb-8 mt-3 rounded-lg">
        <div className="w-full sm:w-auto sm:flex-1  sm:bdeliver-b-0 sm:bdeliver-r bdeliver-gray-200 px-4 py-2">
          <p className="whitespace-nowrap text-slate-400 text-xs text-right">Order Date</p>
          <p className="whitespace-nowrap uppercase font-bold text-xs text-main text-right" style={{ color: currentColor }}>
          {deliver?.p_orders?.date ? new Date(deliver?.p_orders?.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : ''} 
          </p>
        </div>
        <div className="w-full sm:w-auto sm:flex-1 sm:bdeliver-b-0 sm:bdeliver-r bdeliver-gray-200 px-4 py-2">
          <p className="whitespace-nowrap text-slate-400 text-xs">Delivered Date</p>
          <input
            type="date"
            name="date"
            className="block px-0 w-full text-sm font-bold text-gray-900 bg-transparent 
              appearance-none dark:text-white dark:bdeliver-gray-600 
              dark:focus:bdeliver-blue-500 focus:outline-none focus:ring-0 
              focus:bdeliver-blue-600 peer"
            placeholder=" "
            value={deliver?.date ? new Date(deliver.date).toISOString().split('T')[0] : ''}
            onChange={(event) => handleChange(event)}
            style={{ color: currentColor }}
          />
        </div>

        <div className="w-full sm:w-auto sm:flex-1 sm:bdeliver-b-0 sm:bdeliver-r bdeliver-gray-200 px-4 py-2">
            <p className="whitespace-nowrap text-slate-400 text-xs text-right">Order #</p>
            <p className="whitespace-nowrap uppercase font-bold text-main text-xs text-right" style={{ color: currentColor }}>
            {deliver?.reference ? deliver?.reference : ''}
            </p>
        </div>
        <div className="w-full sm:w-auto sm:flex-1 sm:bdeliver-b-0 sm:bdeliver-r bdeliver-gray-200 px-4 py-2">
            <p className="whitespace-nowrap text-slate-400 text-xs text-right">Supplier</p>
            <p className="whitespace-nowrap uppercase font-bold text-main text-xs text-right" style={{ color: currentColor }}>
            {deliver?.supplierDetails?.name}
            </p>
        </div>           
        {/* Conditionally render Approve and Cancel buttons */}
        <div className="w-full sm:w-auto sm:flex-1 sm:bdeliver-b-0 sm: px-4 py-2 flex flex-row">
          <button 
              className="bg-transparent bdeliver ${currentColor} mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Cancel Deliver" // Tooltip content
              // onClick={() => handleDeliverStatus('cancelled')} // Pass "cancel" value to handleCancelDeliver
          >
              <RiMailSendLine  size={24} /> {/* This will render a larger check icon */}
          </button>

          <button 
              className="bg-transparent bdeliver ${currentColor} mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Cancel Deliver" // Tooltip content
              // onClick={() => handleDeliverStatus('cancelled')} // Pass "cancel" value to handleCancelDeliver
          >
              <AiFillPrinter  size={24} /> {/* This will render a larger check icon */}
          </button>
        </div>
        {/* End of Approve and Cancel buttons */}   
      </div>
      {/* end purchase deliver preamble info */}

      {/* Product list */}
      <div className="mx-auto bg-white rounded-lg shadow-lg bdeliver bdeliver-1"style={{ overflowX: 'hidden', overflowY: 'auto', maxWidth: '100%' }}>
        {/* Column Header */}
        <div className="flex justify-between p-3 bdeliver-b">
          <div style={{ width: '10px', textAlign: 'left' }}>
            <span className="text-sm text-blue-400">#</span>
          </div>
          <div style={{ width: '160px', textAlign: 'left' }}>
              <span className="text-sm text-blue-400">Item Name </span>
          </div>
      
          <div style={{ width: '80px', textAlign: 'left' }}>
              <span className="text-sm text-blue-400">Measure</span>
          </div>
            
          <div style={{ width: '80px', textAlign: 'left' }}>
              <span className="text-sm text-blue-400">Qty</span>
          </div>
          <div style={{ width: '80px', textAlign: 'center' }}>
              <span className="text-sm text-blue-400">Received</span>
          </div>
        </div>
        {/* End Column Header */}

        {/* Product list*/}
        {deliver && deliver.transactionLines && deliver.transactionLines.map((product, index) => (
          <div key={product.id} className="p-3 flex items-center justify-between bdeliver-t cursor-pointer hover:bg-gray-200">
            <div className="flex items-center" style={{ width: '10px' }}>
              <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                <div className="leading-snug text-sm text-gray-900 ">{index + 1}</div>
              </div>
            </div>
            
            <div className="flex items-center" style={{ width: '160px' }}>
              <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                <div className="leading-snug text-sm text-gray-900 ">{product?.products?.name}</div>
              </div>
            </div>

            <div className="flex items-center" style={{ width: '80px' }}>
              <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                <div className="leading-snug text-sm text-gray-900 ">{product?.uom_name}</div>
              </div>
            </div>

            <div className="flex items-center" style={{ width: '80px' }}>
              <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                <div className="leading-snug text-sm text-gray-900 font-bold">{product?.ordered_quantity}</div>
              </div>
            </div>
            <div className="flex items-center" style={{ width: '80px' }}>
              <div className="ml-4 flex flex-col" style={{ width: '80px' }}>
                <div className="text-sm" style={{ width: '100px', textAlign: 'left' }}>
                  <input
                    type="text"
                    name="delivered_quantity"
                    className="text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:bdeliver-gray-600 dark:focus:bdeliver-blue-500 focus:outline-none focus:ring-0 focus:bdeliver-blue-600 peer"
                    placeholder=" "
                    value={product?.delivered_quantity}
                    onChange={(event) => handleChange(event, index)}
                  />
                
                </div>
              </div>
            </div>

          </div>
        ))}
      </div>
      {/* Submit Button */}
      <div className="flex justify-end mt-4 mr-4">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-lg"
          style={{ backgroundColor: currentColor }}
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
    </>
  )
}

export default EditRecievedProducts
