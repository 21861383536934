import { useState } from 'react';
import { IoSearchSharp } from "react-icons/io5";

const CustomDropdown = ({ employees, onSelect,currentColor }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const filteredEmployees = employees.filter(employee =>
    employee.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (employee) => {
    if (onSelect) {
      onSelect(employee); // Notify parent component of the selected employee
    }
    setSearchTerm(''); // Clear the search term to allow for new searches
    setShowDropdown(false); // Close dropdown after selection
  };

  <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search product..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        onFocus={() => setShowDropdown(true)}
                        onBlur={() => setTimeout(() => setShowDropdown(false), 200)} // Delay to allow click events on dropdown
                        className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                        list="allProductsList"
                    />
                    
                    <button
                        className="absolute right-3 top-3 text-blue-500 hover:text-blue-700"
                        //onClick={handleSearchChange}
                    >
                        <IoSearchSharp size={20} color={currentColor} />
                    </button>
                </div>
              </div>
  return (
    <div className="relative">
      <input
        type="text"
        placeholder="Select Sales Person..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setTimeout(() => setShowDropdown(false), 200)} // Delay to allow click events on dropdown
        className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                       />
                        <button
                        className="absolute right-3 top-3 text-blue-500 hover:text-blue-700"
                        //onClick={handleSearchChange}
                    >
                        <IoSearchSharp size={20} color={currentColor} />
                    </button>
      {showDropdown && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          <ul>
            {filteredEmployees.length > 0 ? (
              filteredEmployees.map((employee, index) => (
                <li
                  key={employee.id} // Use employee.id as the key for better identification
                  className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSelect(employee)}
                >

                  <span>{employee.full_name}</span>
                 
                </li>
              ))
            ) : (
              <li className="p-2">No results found</li>
            )}
            
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
