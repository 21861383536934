import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { Audio } from 'react-loader-spinner';
import { MdOutlineCancel } from 'react-icons/md';
import { IoMdTrash } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { IoSearchSharp } from "react-icons/io5";
import { Layout, Header, Button, Modal, NewQtyAdjustment, EditQtyAdjustment , DeleteProductCategory} from '../../../../components';
import fetchData from '../../../../contexts/Api'; 
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns';
  

const Quantities = () => {
  const [quantityAdjustments, setQuantityAdjustments] = useState([]);
  const [quantityAdjustment, setQuantityAdjustment] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const { userData, currentColor, actionCount, setActionCount } = useContext(AppContext);
  const navigate = useNavigate(); // Get navigate function from React Router
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Filter categories based on search term
  const filteredQuantityAdjustments = quantityAdjustments.filter(qty =>
    (qty?.users?.username.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredQuantityAdjustments.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
   // setIsLoading(true);
    fetchQuantityAdjustments();
  }, [actionCount]);

  const fetchQuantityAdjustments = async () => {
    await fetchData('quantity_adjustments', userData, setQuantityAdjustments);
    setIsLoading(false);
  };

  const handleAction = (action,qty) => {
    setActionType(action);
    setQuantityAdjustment(qty);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
};


  // Convert server date to local date
  const convertToLocalTime = (dateStr) => {const date = new Date(dateStr);return date; };
  
  // Custom format function to remove "about"
  const formatFriendlyDate = (dateStr) => {
    const formattedDate = formatDistanceToNow(convertToLocalTime(dateStr), { addSuffix: true });
    // Remove "about" if present
    return formattedDate.replace(/^about\s/, '');
  };
  
console.log('product setQuantityAdjustment',quantityAdjustments)
  return (
    <Layout>
      <div className="m-1 md:m-8 mt-24 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-8  justify-center text-center ">
          <div className="w-full sm:w-auto sm:flex-1  sm: px-4 py-2 flex flex-row justify-end">
            <button
              className="bg-transparent hover: hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose} // Call handleClose on click
            >
              <MdOutlineCancel size={24} color={currentColor}/>
            </button>
          </div>
          <Header category="" title="Quantity Adjustment"  />
        </div>
     
        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && <NewQtyAdjustment data={filteredQuantityAdjustments} onClose={handleCloseModal} showCloseButton={true}/>}
          {isModalOpen && actionType === 'edit' && <EditQtyAdjustment rowData={quantityAdjustment}  onClose={handleCloseModal} showCloseButton={true}/>}
           </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col ">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (

          <>
          <div className='bg-gray-50 rounded-lg  max-w-lg p-8 mx-auto'>
            <div className="max-w-lg mx-auto">
              <button
                className="rounded-lg mb-3 p-1 transition-colors duration-300 ease-in-out hover:shadow-xl text-white"
                style={{ backgroundColor: currentColor }}
                onClick={() => handleAction('new', {})}
                type="button"
              >
                New Quantity Adjustment
              </button>
            </div>
            {/* Search Input */}
            
            <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                    />
                    
                    <button
                        className="absolute right-3 top-3 text-blue-500 hover:text-blue-700"
                        //onClick={handleSearchChange}
                    >
                        <IoSearchSharp size={20} color={currentColor} />
                    </button>
                </div>
              </div>

          
            {/* Pagination */}
            <div className="mt-3 flex mb-4 max-w-lg mx-auto">
              {Array.from({ length: Math.ceil(filteredQuantityAdjustments.length / itemsPerPage) }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                  style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          
            <div className="max-w-lg mx-auto bg-white rounded-lg shadow-lg">
  <div className="flex justify-between p-3 border-b">
    <div className="flex-1 flex items-center">
      <span className="text-sm text-slate-400 whitespace-nowrap">Id</span>
    </div>
    <div className="flex-1 flex items-center justify-center">
      <span className="text-sm text-slate-400 whitespace-nowrap">Adjusted by</span>
    </div>
    <div className="flex-1 flex items-center justify-center">
      <span className="text-sm text-slate-400 whitespace-nowrap">Approved ?</span>
    </div>
    <div className="flex-1 flex items-center justify-end">
      <span className="text-sm text-slate-400 whitespace-nowrap">Actions</span>
    </div>
  </div>
  {currentItems.map(qty => (
    <div key={qty.id} className="flex items-center p-3 border-t cursor-pointer hover:bg-gray-200">
      <div className="flex-1 flex items-center">
        <div className="ml-2 flex flex-col whitespace-nowrap">
          <div className="leading-snug text-sm text-gray-900">{qty?.id}</div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <div className="text-sm text-gray-600 whitespace-nowrap">
          {qty?.users?.username ? qty?.users?.username : ''}
          {qty?.adjustment_date
            ? ` (${formatFriendlyDate(qty?.adjustment_date)})`
            : ' (No Date Available)'}
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <div
          className={`text-sm text-white px-1 rounded-full whitespace-nowrap ${
            qty?.approved ? 'bg-green-500' : 'bg-red-500'
          }`}
        >
          {qty?.approved ? 'Yes' : 'No'}
        </div>
      </div>
      <div className="flex-1 flex items-center justify-end">
        <button
          className="h-6 px-2 text-sm rounded-full hover:bg-blue-100 whitespace-nowrap"
          onClick={() => handleAction('edit', qty)}
          title="Edit"
        >
          <CiEdit size={20} color={currentColor} />
        </button>
        
      </div>
    </div>
  ))}
</div>

          
            {/* Pagination */}
            <div className="mt-3 flex mb-4 max-w-lg mx-auto">
              {Array.from({ length: Math.ceil(filteredQuantityAdjustments.length / itemsPerPage) }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                  style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
          </>
          
        )}

        
      </div>
    </Layout>
  );
  };

export default Quantities;
