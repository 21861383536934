import React, { useState, useEffect, useRef, useContext } from "react";
import { FiPlusCircle , FiTrash2 } from 'react-icons/fi';
import { FaTrashAlt  } from 'react-icons/fa';
import { FcApproval } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { RiMailSendLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

import { ToastContainer, collapseToast, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { v4 as uuidv4 } from 'uuid';
import AppContext from '../../../../contexts/AppContext';
import { Header, Button} from '../../../../components';
import { getSuppliers} from '../../suppliers/utils/api';
import fetchData from '../../../../contexts/Api'; 
import { getUoms} from '../../../../data/UomService';
import { getTaxTypes} from '../../../../data/TaxService';
import { getPurchase, addPurchaseOrder } from '../utils/api';
import { convertStringToValue } from "@syncfusion/ej2/maps";
 
const NewPurchaseOrder = ({ purchaseId, mode, onPurchaseChange }) => {
  const { currentColor,userData} = useContext(AppContext);
  const [showProductForm, setShowProductForm] = useState(false); // Initially hide form
  const [suppliers, setSuppliers] = useState([]); // State for product data
  const [products, setProducts] = useState([]); // State for product data
  const [uoms, setUoms] = useState([]); // State for unit of measures
  const [taxTypes, setTaxTypes] = useState([]); // State for tax types
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [order, setOrder] = useState({ orderDate: '', products: [], supplier: '', supplierId:0, phone: '',contactPerson: '',reference:'',status: 'draft',purchaseValue:0,subTotal:0,totalDiscount:0,totalTax:0});
  const [order, setOrder] = useState({});
  
  const [receiptData, setReceiptData] = useState([]); // State for grand total
  
  useEffect(() => {
    fetchSuppliers();
    fetchProducts();
    fetchUoms();
    fetchTaxTypes();
  }, []);

  const ClearOrder = () => {
    setOrder({
      orderDate: '',
      products: [],
      supplier: '',
      supplierId: '',
      reference: 'new',
      state: 'draft',
      purchaseValue: '',
      phone: '',
      contactPerson: '',
      subTotal: 0,
      lineTotal: 0,
      totalTax: 0,
      totalDiscount: 0,
    });
  };
  
  const fetchSuppliers = async () => {
    await fetchData('suppliers', userData, setSuppliers);
    setIsLoading(false);
  };

  const fetchProducts = async () => {
    await fetchData('products', userData, setProducts);
    setIsLoading(false);
  };

  const fetchUoms = async () => {
    await fetchData('uoms', userData, setUoms);
    setIsLoading(false);
  };

  const fetchTaxTypes = async () => {
    await fetchData('tax_types', userData, setTaxTypes);
    setIsLoading(false);
  };

  
  

  
 

  const handleAddProductClicks = () => {
//console.log('order here',showProductForm)
    
  };

  const handleAddProductClick = () => {
//console.log('order here',showProductForm)
    if (showProductForm && (!order.supplier || !order.orderDate)) {
      toast.error('Please enter supplier and order Date ');
      return;
    }
    fetchProducts() // get products from api
    fetchUoms() // get measures from api
    fetchTaxTypes() // get tax types from api
    setShowProductForm(!showProductForm); // Toggle form visibility
   
  };

  const handleAddProducts=()=>{
    //console.log('button clicked sir ')
    setShowProductForm(!showProductForm); // Toggle form visibility
}



  const handleAddRow = () => {
    setOrder(prevOrder => {
      // If prevOrder is undefined or null, initialize it with default values
      if (!prevOrder) {
        return {
          products: [{ product: '', productId: 0, uom: '', uomId: '', price: 0, tax: 0, taxId: 0, discountRate: 0, qty: 1, totalInclTax: 0, lineTotal: 0 }]
        };
      }
  
      // Ensure prevOrder.products is an array
      const productsArray = Array.isArray(prevOrder.products) ? prevOrder.products : [];
  
      // Append a new product to the existing products array
      return {
        ...prevOrder,
        products: [...productsArray, { product: '', productId: 0, uom: '', uomId: '', price: '',taxAmt: 0, tax: '', taxId: '', taxRate:0,discountRate: 0, qty: 1, subTotal: 0, total: 0 }]
      };
    });
  };
  
 

  /*const handleAddRow = () => {
   /* if (!order || !order.products) {
      // Handle the case where order or order.products is undefined
      alert('Order or order products is not properly initialized.');
      return;
    }
  
    if (
      order.products.length === 0 ||
      (order.products.length > 0 &&
        order.products[order.products.length - 1].product &&
        order.products[order.products.length - 1].measure &&
        order.products[order.products.length - 1].price &&
        order.products[order.products.length - 1].tax &&
        order.products[order.products.length - 1].qty)
    ) {
      // Clone the order object to avoid mutating state directly
      const updatedOrder = { ...order };
      // Push a new product object to the products array
      updatedOrder.products.push({
        product: '',
        productId: '',
        measure: '',
        price: '',
        tax: '',
        qty: 1,
        total: ''
      });
      // Update the state with the new order
      setOrder(updatedOrder);
    } else {
      alert('Please fill all fields in the previous row before adding a new row.');
    }
  }; */
  
  const handleDeleteAllRows = (orderIndex, productIndex) => {
    setOrder(prevOrder => {
      const updatedOrder = [...prevOrder];
      updatedOrder[orderIndex].products.splice(productIndex, 1);
      return updatedOrder;
    });
  };


  
  const removeRow = (rowIndex) => {
   // console.log('deleting item',rowIndex)
    setOrder(prevOrder => {

      const updatedOrder = { ...prevOrder };
      const productsCopy = [...updatedOrder.products]; // Make a copy of products array
      productsCopy.splice(rowIndex, 1);
      updatedOrder.products = productsCopy;
      return updatedOrder;
    });
  };

  
  const CalculateTotals= (updatedOrder,productIndex)=>{
    // Ensure updatedOrder and products are defined
    if (!updatedOrder || !updatedOrder.products) {
      console.error("Invalid or missing order or products array");
      return updatedOrder; // Return the original order object
    }

    // Ensure productIndex is within bounds
    if (productIndex < 0 || productIndex >= updatedOrder.products.length) {
      console.error("Product index is out of bounds");
      return updatedOrder; // Return the original order object
    }
    const price = parseFloat(updatedOrder.products[productIndex]?.price) || 0;
    const qty = parseFloat(updatedOrder.products[productIndex].qty) || 0;
    const discountRate = parseFloat(updatedOrder.products[productIndex].discountRate) || 0;
    const discountRatio = parseFloat(discountRate) / 100; // Convert discountRate percentage to decimal
    const taxRate = parseFloat(updatedOrder.products[productIndex].taxRate) || 0;
    const taxRatio = parseFloat(taxRate) / 100; // Convert discountRate percentage to decimal
   
   //calculate linetotals
    const lineTotal = parseFloat(price * qty); //Apply discountRate to price before tax calculation
    updatedOrder.products[productIndex].lineTotal = lineTotal;
    
    //calculate discount
    const discountAmount = parseFloat(price) * qty * discountRatio;
    const discountedPrice = parseFloat(price) * (1- discountRatio);
    updatedOrder.products[productIndex].discount = discountAmount;
    
    const totalExclTax = discountedPrice * parseFloat(qty); //subTotal
    
    //calculate tax 
    const tax = parseFloat(taxRate);
    const taxAmt = parseFloat((totalExclTax * (tax / 100)));
    updatedOrder.products[productIndex].taxAmt = taxAmt;
    const totalInclTax = totalExclTax + taxAmt;
    updatedOrder.products[productIndex].totalInclTax = totalInclTax;

    // Calculate product totals
    const subTotal = updatedOrder.products.reduce((lineTotal, product) => lineTotal + parseFloat(product.lineTotal), 0);
    updatedOrder.subTotal = subTotal;
    const discountRateTotal = updatedOrder.products.reduce((discount, product) => discount + parseFloat(product.discount), 0);
    updatedOrder.totalDiscount = discountRateTotal;
    const taxTotal = updatedOrder.products.reduce((taxAmt, product) => taxAmt + parseFloat(product.taxAmt), 0);
    updatedOrder.totalTax = taxTotal;
    const grandTotal = updatedOrder.products.reduce((totalInclTax, product) => totalInclTax + parseFloat(product.totalInclTax), 0);
    updatedOrder.purchaseValue = grandTotal;

  }

  const handleChange = (event, productIndex) => {
    const { name, value } = event.target;
    setOrder(prevOrder => {const updatedOrder = { ...prevOrder };
      // Check if updatedOrder is defined and not null
      if (updatedOrder && updatedOrder.products) {
        // Check if productIndex is within bounds
        if (productIndex >= 0 && productIndex < updatedOrder.products.length) {
          const product = updatedOrder.products[productIndex];
          // Now you can safely use the product object
        } else {
          return
          // Handle case where productIndex is out of bounds
          console.error("Product index is out of bounds");
        }
      } else {
        console.error("Invalid or missing order or products array");
        // Handle case where updatedOrder or updatedOrder.products is missing or not an array
      }
  
      // Update order fields based on the changed input
      if (name === "supplier") {
        updatedOrder.supplier = value;
        const selectedSupplier = suppliers.find((supplier) => supplier.name === value);
        if (selectedSupplier) {
          updatedOrder.contactPerson = selectedSupplier.contact_person;
          updatedOrder.phone = selectedSupplier.phone_number;
          updatedOrder.supplierId = selectedSupplier.id;
        } 
      } else if (name === "orderDate") {updatedOrder.orderDate = value;
      } else if (name === "product") {
        const selectedProduct = products.find(product => product.name === value);
        updatedOrder.products[productIndex].qty = 1;
        updatedOrder.products[productIndex].discountRate = ''
        if (selectedProduct) {
          console.log('selectd product',selectedProduct);
          updatedOrder.products[productIndex].product = value;
          // updatedOrder.products[productIndex].productId= uuidv4();
          updatedOrder.products[productIndex].productId = selectedProduct.id;
          updatedOrder.products[productIndex].uom = selectedProduct.prices.buy_uom_name;
          updatedOrder.products[productIndex].uomId = selectedProduct.prices.buy_uom_id; 
          updatedOrder.products[productIndex].tax = selectedProduct.buy_tax_info.name;
          updatedOrder.products[productIndex].taxId = selectedProduct.buy_tax_type_id;
          updatedOrder.products[productIndex].taxRate = selectedProduct.buy_tax_info.percentage;
          updatedOrder.products[productIndex].price = selectedProduct.prices.buy_price_new;
          // const totalExclTax = parseFloat(updatedOrder.products[productIndex].price) * parseFloat(updatedOrder.products[productIndex].qty);
          // const tax = parseFloat(updatedOrder.products[productIndex].taxRate);
          // const totalInclTax = totalExclTax + (totalExclTax * (tax / 100));
          // updatedOrder.products[productIndex].lineTotal = totalExclTax;
        }
      } else if (name === "uom") {
        updatedOrder.products[productIndex].uom= value;
        const selectedUom = uoms.find(item=> item.name === value);
        if (selectedUom) { updatedOrder.products[productIndex].uomId= selectedUom.id;}
      } else if (name === "tax") {
          updatedOrder.products[productIndex].tax= value;
          const selectedTax = taxTypes.find(item=> item.name === value);
          if (selectedTax) { 
            updatedOrder.products[productIndex].taxId= selectedTax.id;
            updatedOrder.products[productIndex].taxRate= selectedTax.percentage;
          }
      } else {updatedOrder.products[productIndex][name] = value;}
      CalculateTotals(updatedOrder, productIndex);
      
      return updatedOrder;
    });
  };
  

  const handleSubmitOrder = async (event) => {
   event.preventDefault(); // Prevent default form submission behavior
   console.log('here sir in submit ')
    try {
      const response = await ( addPurchaseOrder('purchase/orders',  order, userData))
      toast.success('Purchase Order Updated  Successfully.');
      setReceiptData(response);
      //console.log('receiptData in API',setReceiptData)
    } catch (err) {
      toast.error('Error submitting form');
    }
  };
  
 

 
  //console.log('order at this point sir ',order);

    return (
      <div className="">
        <ToastContainer position="top-center" autoClose={2000} />       
      <Header
        category=""
        title={mode === "edit" ? "Edit Purchase Order" : "New Purchase Order"}
        color={currentColor}
        className="mb-8 pb-8" // Add a bottom margin of 4 (adjust as needed)
      />
      <div className=''>
        {/* start Render content for each order here */}
        


          <div className="card rounded-lg bg-blue-25 p-4 mb-1  mt-4 border border-gray-200">
            <div className="card grid  grid-cols-4 gap-4 mt-4">
              <div className="relative z-0 mb-3 w-full group">
                <input
                  type="date"
                  id="orderDate"
                  name="orderDate"
                  className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              
                  onChange={(event) => handleChange(event)}
                  value={order?.orderDate}
                />
                <label
                  htmlFor="orderdate"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Order Date.
                </label>
              </div>
              <div className="relative z-0 mb-3 w-full group">
                <input
                  type="text"
                  id="supplier"
                  name="supplier"
                  className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  list="suppliersList" // Link the input to the datalist
                  onChange={(event) => handleChange(event)}
                  value={order?.supplier}
                />
              
                <datalist id="suppliersList">
                  {suppliers.map((supplier, index) => (  // Add index as a unique key
                    <option value={supplier.name} key={index} />
                  ))}
                </datalist>

                <label
                  htmlFor="supplier"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Supplier
                </label>
              </div>
              {/* Conditionally render "Contact Person" and "Telephone" fields */}
              {order.supplier && (
                <>
                  <div className="relative z-0 mb-3 w-full group">
                  <input
                  type="text"
                  id="contactPerson"
                  name="contactPerson"
                  className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  onChange={(event) => handleChange(event)}
                  value={order.contactPerson}
                />
              
                <label
                  htmlFor="contactPerson"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Contact Person
                </label>
                  </div>
                  <div className="relative z-0 mb-3 w-full group">
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      onChange={(event) => handleChange(event)}
                      value={order.phone}
                    />
                    <label
                      htmlFor="phone"
                      className="absolute text-sm text-gray-500 dark:text-blue-600 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Telephone.
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>





           {/* Conditionally render "Add Product" button outside supplierDiv */}
           { order.supplier && order.orderDate && !showProductForm  &&(
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-full"
            style={{ backgroundColor: currentColor }}
            onClick={handleAddProducts}
          >
            Add products
         
          </button>
          )}

          

           {/* start Render product form here */}
           { showProductForm  &&(

          <form onSubmit={handleSubmitOrder}>
            <div className="card mx-auto rounded-lg bg-white p-4 border border-gray-200">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setShowProductForm(false)}
                  className="text-red-500 hover:text-red-700 transition duration-300 pb-2"
                  // style={{ color:  }}
                >
                  <IoClose/>
                </button>
    </div>
    <table className="min-w-full table-auto text-left text-gray-300 mx-auto">
  < thead className="font-bold">
<tr>
<th className="border-b text-sm font-normal px-2" style={{ color: currentColor, width: 'auto' }}>Product</th>
<th className="border-b text-sm font-normal px-2" style={{ color: currentColor, width: 'auto' }}>Measure</th>
<th className="border-b text-sm font-normal px-2" style={{ color: currentColor, width: 'auto' }}>Price</th>
<th className="border-b text-sm font-normal px-2" style={{ color: currentColor, width: 'auto' }}>Tax</th>
<th className="border-b text-sm font-normal px-2" style={{ color: currentColor, width: 'auto' }}>Discount (%)</th>
<th className="border-b text-sm font-normal px-2" style={{ color: currentColor, width: 'auto' }}>Qty</th>
<th className="border-b text-sm font-normal px-2" style={{ color: currentColor, width: 'auto' }}>Total</th>
<th className="border-b text-sm font-normal px-2" style={{ color: currentColor, width: 'auto' }}></th>
</tr>
</thead>

<tbody>
{order && order.products && order.products.map((product, index) => (
<tr key={index}>
<td className="px-2">
<div className="relative z-0 mb-3 w-full group">
  <input
    type="text"
    id="product"
    name="product"
    className="block py-2 px-0 w-full text-sm text-gray-600 bg-transparent border-0 border-b border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
    placeholder=" "
    list="productsList"
    onChange={(event) => handleChange(event, index)}
  />
  <datalist id="productsList">
    {products.map((product, index) => (
      <option key={index} value={product.name} />
    ))}
  </datalist>
</div>
</td>
<td className="px-2">
<div className="relative z-0 mb-3 w-full group">
  <input
    type="text"
    name="uom"
    className="block py-2 px-0 text-sm text-gray-900 bg-transparent border-0 border-b-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
    placeholder=" "
    list="uomsList"
    value={product.uom}
    onChange={(event) => handleChange(event, index)}
  />
  <datalist id="uomsList">
    {uoms.map((item, index) => (
      <option key={index} value={item.name} />
    ))}
  </datalist>
</div>
</td>
<td className="px-2">
<div className="relative z-0 mb-3 w-full group">
  <input
    type="text"
    name="price"
    className="block py-2 px-0 text-sm text-gray-900 bg-transparent border-0 border-b-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
    placeholder=" "
    value={product.price}
    // value={product.price ? parseFloat(product.price).toFixed(2) : '0.00'}
    onChange={(event) => handleChange(event, index)}
  />
</div>
</td>
<td className="px-2">
<div className="relative z-0 mb-3 group">
  <input
    type="text"
    id="tax"
    name="tax"
    className="block py-2 px-0 text-sm text-gray-900 bg-transparent border-0 border-b-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
    placeholder=" "
    list="taxList"
    value={product.tax}
    onChange={(event) => handleChange(event, index)}
  />
  <datalist id="taxList">
    {taxTypes.map((tax, index) => (
      <option key={index} value={tax.name} />
    ))}
  </datalist>
</div>
</td>
<td className="px-2">
<div className="relative z-0 mb-3 w-full group">
  <input
    type="text"
    id="discountRate"
    name="discountRate"
    className="block py-2 px-0 text-sm text-gray-900 bg-transparent border-0 border-b-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
    placeholder=" "
    value={product.discountRate}
    onChange={(event) => handleChange(event, index)}
  />
</div>
</td>
<td className="px-2">
<div className="relative z-0 mb-3 group">
  <input
    type="text"
    id="qty"
    name="qty"
    className="block py-2 px-0 text-sm text-gray-900 bg-transparent border-0 border-b-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
    placeholder=" "
    value={product.qty}
    onChange={(event) => handleChange(event, index)}
  />
</div>
</td>
<td className="px-2">
<div className="relative z-0 mb-3 w-full group">
  <input
    type="text"
    id="total"
    name="total"
    className="block py-2 px-0 w-full  text-sm text-gray-900 bg-transparent border-0 border-b-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
    placeholder=" "
    //value={product?.lineTotal}
    value={product?.lineTotal ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(product.lineTotal) : ""}
                       
    // value={parseFloat(product.lineTotal)?.toFixed(2)}
    onChange={(event) => handleChange(event, index)}
    readOnly
  />
</div>
</td>
<td className="py-2 px-2">
<div className="flex justify-end">
  <button
    type="button"
    onClick={() => removeRow(index)}
    className="text-red-500 hover:text-red-700 transition duration-300 inline-flex items-center space-x-1"
  >
    <FiTrash2 className="h-4 w-4" />
  </button>
</div>
</td>
</tr>
))}
<tr>
<td colSpan="5" className="py-2">
<button
type="button"
onClick={handleAddRow}
className="text-blue-500 hover:text-blue-700 transition duration-300 inline-flex items-center space-x-1 text-xs mb-2"
>
<FiPlusCircle className="h-3 w-3 animate-bounce" />
<span className="text-xs">Add Product</span>
</button>
{/* {order.products && order.products.length > 2 && (
<button
  type="button"
  onClick={() => handleDeleteAllRows()}
  className="text-red-500 hover:text-red-700 transition duration-300 inline-flex items-center space-x-1 text-xs ml-2"
>
  <FaTrashAlt className="h-3 w-3" />
  <span>Remove All</span>
</button>
)} */}
</td>
</tr>
</tbody>
</table>

  </div>

      {/* Sub total  */}
      {order.subTotal && (
        <div className="bg-gray-25 p-2 flex justify-end">
          <div className="px-4 font-bold">Subtotal:</div>
          {/* <div className="px-4">{order.subTotal.toLocaleString('en-MW', { style: 'currency', currency: 'MWK' })}</div> */}
          {/* <div className="px-4">{order.subTotal}</div> */}
          <div className="px-4 font-bold">{order?.subTotal ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(order?.subTotal) : 0}</div>
        </div>
      )}

      {/* Total discountRate  */}
      <div className="bg-gray-25 p-2 flex justify-end">
        <div className="flex items-end">
            <div className="px-4">Discount :</div>
        </div>
        
        <div className="px-4">{order?.totalDiscount ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(order?.totalDiscount) : 0}</div>
        
      </div>

      {/* Total tax  */}
      <div className="bg-gray-25 p-2 flex justify-end">
        <div className="flex items-right ">
            <div className="px-4">Tax :</div>
        </div>
        <div className="px-4">{order?.totalTax ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(order?.totalTax) : 0}</div>
       
      </div>

      {/* Grand total  */}
      <div className="bg-gray-25 p-2 flex justify-end">
        <div className="flex items-right">
            <div className="px- font-bold">Grand Total :</div>
        </div>
       
        <div className="px-4 font-bold">{order?.purchaseValue ? new Intl.NumberFormat('en-MW', { style: 'currency', currency: 'MWK' }).format(order?.purchaseValue) : 0}</div>
       
      </div>
      <div className="flex justify-end">
        
         <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-full"
            style={{ backgroundColor: currentColor }}
            onClick={handleSubmitOrder}
          >
            Press Order
         
          </button>
        
      </div>
  </form>
)}
{/* stop Render product form here */}
          </div>
  



      </div>
    );
  };
export default NewPurchaseOrder;
