//links.js
import React from 'react';
import { AiOutlineCalendar, AiOutlineShoppingCart, AiOutlineAreaChart, AiOutlineBarChart, AiOutlineStock } from 'react-icons/ai';
import { FiShoppingBag, FiEdit, FiPieChart, FiBarChart, FiCreditCard, FiStar, FiShoppingCart } from 'react-icons/fi';
import { BsKanban, BsBarChart, BsBoxSeam, BsCurrencyDollar, BsShield, BsChatLeft } from 'react-icons/bs';
import { BiColorFill } from 'react-icons/bi';
import { IoMdContacts } from 'react-icons/io';
import { RiContactsLine, RiStockLine } from 'react-icons/ri';
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { HiOutlineRefresh } from 'react-icons/hi';
import { TiTick } from 'react-icons/ti';
import { GiLouvrePyramid } from 'react-icons/gi';
import { GrLocation } from 'react-icons/gr';

export const links = [
  {
    title: 'Dashboard',
    //role: ['admin', 'manager','Owner'], // Both admin and manager can access Dashboard
    //department: ['HR'], // Only users in HR department can access Company settings
  
    links: [
      {
        name: 'dashboard',
        icon: <FiShoppingBag />,
      },
    ],
  },

  {
    title: 'Company',
    role: ['manager','executive','admin'], // Both admin and manager can access Dashboard
    department: ['Human Resources','ICT'], // Only users in HR department can access Company settings
  
    links: [
      {
        name: 'Settings',
        icon: <AiOutlineShoppingCart />,
      },
      {
        name: 'Departments',
        icon: <FiShoppingBag />,
      },
    ],
  },



  {
    title: 'Sales',
   
   
  
    links: [
      {
        role: ['executive','admin'], // Both admin and manager can access Dashboard
        name: 'POS',
        icon: <AiOutlineShoppingCart />,
      },

      {
        role: ['admin'], // Only admin  can access Dashboard
        name: 'Customers',
        icon: <AiOutlineShoppingCart />,
      },

      {
        role: ['admin'], // Only admin  can access Dashboard
        name: 'Quotations',
        icon: <AiOutlineShoppingCart />,
      },
      {
        role: ['admin'], // Only admin  can access Dashboard
        name: 'Orders',
        icon: <FiShoppingBag />,
      },

      {
        role: ['admin'], // Only admin  can access Dashboard
        name: 'Invoice',
        icon: <FiShoppingBag />,
      },
      {
        role: ['admin'], // Only admin  can access Dashboard
        name: 'Payment',
        icon: <FiShoppingBag />,
      },
    ],
  },


  {
    title: 'Inventory',
    role: ['admin', 'manager','executive'], // Both admin and manager can access Dashboard
    department: ['purchasing'], // Only users in HR department can access Company settings
  
    links: [
      {
        name: 'categories',
        icon: <AiOutlineShoppingCart />,
      },
      {
        name: 'Products',
        icon: <FiShoppingBag />,
      },
      {
        name: 'Quantities',
        icon: <AiOutlineShoppingCart />,
      },
      {
        name: 'Prices',
        icon: <AiOutlineShoppingCart />,
      },

 
    ],
  },




  {
    title: 'Purchases',
    role: ['admin', 'manager','executive'], // Both admin and manager can access Dashboard
    department: ['Purchasing'], // Only users in HR department can access Company settings
  
    links: [
    
      {
        name: 'Orders',
        icon: <FiShoppingBag />,
      },

      {
        name: 'Deliveries',
        icon: <FiShoppingBag />,
      },

      {
        name: 'Invoices',
        icon: <FiShoppingBag />,
      },
      {
        name: 'Payments',
        icon: <FiShoppingBag />,
      },
      {
        name: 'Suppliers',
        icon: <FiShoppingBag />,
      },
    ],
  },

 
  {
    title: 'Employees',
    role: ['admin', 'manager','executive'], // Both admin and manager can access Dashboard
    department: ['Human Resources'], // Only users in HR department can access Company settings
  
    links: [
      {
        name: 'Employees',
        icon: <AiOutlineShoppingCart />,
      },
    ],
  },
 
  {
    title: 'Users',
    role: ['admin'], // Both admin and manager can access Dashboard
    department: ['ICT'], // Only users in HR department can access Company settings
  
    links: [
      {
        name: 'Users',
        icon: <AiOutlineShoppingCart />,
      },
      {
        name: 'Roles',
        icon: <AiOutlineShoppingCart />,
      },

    
 
    ],
  },
  
  {
    title: 'Reports',
    role: ['admin'], // Both admin and manager can access Dashboard
    department: ['purchasing'], // Only users in HR department can access Company settings
  
    links: [
    
      {
        name: 'color-mapping',
        icon: <BsBarChart />,
      },
      {
        name: 'calendar',
        icon: <AiOutlineCalendar />,
      },
   
    ],
  },
];
