// ProductService.js
import axios from 'axios';
import API_BASE_URL from '../config';

// get all products
export const getProducts = async (page,userData) => {
  console.log('page',page,'userdat',userData)
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { active:1}, // query parameters ,  with_photos:1
  };
  try {
    const response = await axios.get(`${API_BASE_URL}/${page}`, config);
    const data = response.data;
    console.log(data)
    return {
      count: data.length,
      result: data,
      error: null, // Include this line to ensure consistency
   
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    // Ensure that the returned object has the expected structure
    return {
      count: 0,
      result: null,
      error: 'Failed to fetch data. Please try again later.',
    };
  }
};


export const getProductsByCategory = async (page, categoryId, userData) => {

  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { category_id:categoryId, with_photos:1}, // query parameters ,  with_photos:1
  };
  try {
    //console.log(page, productId, userData);
    const response = await axios.get(`${API_BASE_URL}/${page}`, config);
    const data = response.data;
    //console.log('API Response sir :', data); // Log the data
    console.table('API Response:', data); // Log the data
    return {
      count: data ? 1 : 0, // Assuming data is the product details
      result: data ? [data] : null,
      error: null,
    };
  } catch (error) {
    console.error('Error fetching products :', error);
    return {
      count: 0,
      result: null,
      error: 'Failed to fetch product details. Please try again later.',
    };
  }
};
  export const getProduct = async (page, productId, userData) => {
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { id:productId, with_photos:1}, // Send productId as a query parameter
    };
    try {
      //console.log(page, productId, userData);
      const response = await axios.get(`${API_BASE_URL}/${page}`, config);
      const data = response.data;
  
      //console.log('API Response:', data); // Log the data
      //console.table('API Response:', config); // Log the data
      return {
        count: data ? 1 : 0, // Assuming data is the product details
        result: data ? [data] : null,
        error: null,
      };
    } catch (error) {
      console.error('Error fetching product details:', error);
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch product details. Please try again later.',
      };
    }
  }; 
  
  export const addProduct = async (page, userData, product) => {
    console.log('productin api',page);
    try {
      const response = await fetch(`${API_BASE_URL}/${page}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.data.token}`
        },
          name:product.name,
          barcode:product.barcode,
          category_id:product.category_id,
          reorder_quantity:product.reorder_qty,
          prices:{buy_price_new:product.cost_price,buy_uom_id:product.buy_uom_id,
            sell_uom_id:product.sell_uom_id,sell_price_new:product.sell_Price,
          },
          photo: {data:product.filebase64,contentType:product.photoType},
        });

        if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
       //const data = await response.json();
    } catch (err) {
        console.error('Error adding order:', err);
        throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
    }
};



export const updateProduct = async (page, productId, productData, userData) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { id: productId }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:productId,
      name: productData.productName,
      barcode: productData.barCode,
      category_id: productData.category,
      reorder_quantity: productData.reorderQty,
      prices:{id:productData.priceId,buy_price_new:productData.costPrice,buy_uom_id:productData.buyMeasure,
        sell_uom_id:productData.sellMeasure,sell_price_new:productData.sellPrice,
      },
      photo: { data: productData.filebase64, contentType: productData.photoType },
    }, config);
    console.log('msg from server',response)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
  } catch (err) {
    console.error('Error updating product:', err);
    throw new Error('Failed to update product'); // Rethrow a custom error message to be handled by the calling code
  }
};



export const deleteProduct = async (page, productId,  userData) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    // params: { id: productId }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:productId,
      active: 0,
    }, config);
    console.log('msg from server',response)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
  } catch (err) {
    console.error('Error deleting  product:', err);
    throw new Error('Failed to delete product'); // Rethrow a custom error message to be handled by the calling code
  }
};

export const updateProductQty = async (page, productData, userData) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
     params: { id: productData.id }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:productData.id,
      quantity_instock: productData.quantity_instock,
    }, config);
    console.log('msg from server',response)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
  } catch (err) {
    console.error('Error updating product:', err);
    throw new Error('Failed to update product Quantity'); // Rethrow a custom error message to be handled by the calling code
  }
};




