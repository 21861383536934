import React, { useEffect, useState, useContext } from 'react';
import AppContext from '../contexts/AppContext';

// import { useStateContext } from '../contexts/ContextProvider';


const Button = ({ icon, bgColor, color, bgHoverColor, size, text, borderRadius, width }) => {
  // const { setIsClicked, initialState } = useStateContext();
  const { setIsClicked, initialState ,currentColor,userData} = useContext(AppContext);

  return (
    <button
      type="button"
      onClick={() => setIsClicked(initialState)}
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={`rounded-lg  mb-3 text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    >
      {icon} {text}
    </button>
  );
};

export default Button;