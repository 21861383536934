
    // EmployeeService.js
    import axios from 'axios';
    import API_BASE_URL from '../../../../config';


    


  

    export const addEmployee = async (page, employee, userData) => {
      console.log('Employee in api ',employee)  
      try {
      
   
      const response = await fetch(`${API_BASE_URL}/${page}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.data.token}`
        },
        body: JSON.stringify({
            first_name:employee.first_name,
            last_name:employee.last_name,
            hire_date:employee.hire_date,
            department_id:employee.department_id,
            email:employee.email,
            job_title:employee.job_title,
            phone_number:employee.phone_number,
             photo: {data:employee.displayImg,contentType:employee.imgType},
            session_id:1
        })
      });
      console.log('from api response sir ',response)
      return {
        data: response
      };

    } catch (err) {
        console.error('Error adding order:', err);
        throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
    }
};
export const updateEmployee = async (page, employee, userData) => {
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { id: employee.id }, // Send productId as a query parameter
    };
  
    try {
    
      const response = await axios.put(`${API_BASE_URL}/${page}`, {
        id:employee.id,
        first_name:employee.first_name,
        last_name:employee.last_name,
        hire_date:employee.hire_date,
        department_id:employee.department_id,
        email:employee.email,
        job_title:employee.job_title,
        phone_number:employee.phone_number,
        active:employee.active,
        photo: {data:employee.displayImg,contentType:employee.imgType},
        session_id:1
      }, config);
      console.log('from api response sir ',response)
      return {
        data: response
      };
    } catch (err) {
      console.error('Error updating product:', err);
      throw new Error('Failed to update product'); // Rethrow a custom error message to be handled by the calling code
    }
  };
  

  export const deleteEmployee= async (page, userData, employee) => {
    console.log('deleteing ',employee)
    const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { id: employee.id }, // Send productId as a query parameter
  };
  
  try {
    const response = await axios.delete(`${API_BASE_URL}/${page}`,config);
    // Check if the response indicates success (you may adjust this based on your API's response structure)
    console.log('from api response sir ',response.data)
      return {
        data: response.data 
      };
  
  } catch (err) {
    console.error('Error deleting product:', err);
    throw new Error('Failed to delete product');
  }
  };
  


